/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  RegisterFormConfigRequest,
  RegisterFormConfigResponse,
  AccountFormConfigRequest,
  AccountFormConfigResponse,
} from "state/account-forms";

import {
  REGISTER_FORM_CONFIG_REQUEST,
  REGISTER_FORM_CONFIG_RESPONSE,
  ACCOUNT_FORM_CONFIG_REQUEST,
  ACCOUNT_FORM_CONFIG_RESPONSE,
} from "state/account-forms";
import { getRegisterFormConfig, getAccountFormConfig } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: RegisterFormConfigRequest) => {
      try {
        const data = await client(
          getRegisterFormConfig,
          { partner: msg.partner },
          { cache: true }
        );

        return ({
          tag: REGISTER_FORM_CONFIG_RESPONSE,
          data: data.getRegisterFormConfig,
        }: RegisterFormConfigResponse);
      }
      catch (e) {
        console.error(e);
      }
    },
    subscribe: { [REGISTER_FORM_CONFIG_REQUEST]: true },
  });
  storage.addEffect({
    effect: async (msg: AccountFormConfigRequest) => {
      try {
        const data = await client(
          getAccountFormConfig,
          { partner: msg.partner }
        );

        return ({
          tag: ACCOUNT_FORM_CONFIG_RESPONSE,
          data: data.getAccountFormConfig,
        }: AccountFormConfigResponse);
      }
      catch (e) {
        console.error(e);
      }
    },
    subscribe: { [ACCOUNT_FORM_CONFIG_REQUEST]: true },
  });
};

export default registerClient;
