/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { QuizListEffectResponse } from "state/quiz-list";
import type { QuizEffectRequest, QuizEffectResponse } from "state/quiz";
import type { QuizHistoryEffectRequest, QuizHistoryEffectResponse,
  QuizHistoryRowsEffectRequest, QuizHistoryRowsEffectResponse,
  QuizHistoryListEffectResponse } from "state/quiz-history";

import {
  QUIZ_LIST_REQUEST,
  QUIZ_LIST_RESPONSE,
} from "state/quiz-list";

import {
  QUIZ_EFFECT_REQUEST,
  QUIZ_EFFECT_RESPONSE,
} from "state/quiz";

import {
  QUIZ_HISTORY_ROWS_EFFECT_REQUEST,
  QUIZ_HISTORY_ROWS_EFFECT_RESPONSE,
  QUIZ_HISTORY_LIST_EFFECT_REQUEST,
  QUIZ_HISTORY_LIST_EFFECT_RESPONSE,
  QUIZ_HISTORY_EFFECT_REQUEST,
  QUIZ_HISTORY_EFFECT_RESPONSE,
} from "state/quiz-history";

import { quizList, quiz, quizHistoryRows, quizHistoryList, quizHistory } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          quizList
        );

        return ({
          tag: QUIZ_LIST_RESPONSE,
          data: data.quizList,
        }: QuizListEffectResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: QUIZ_LIST_RESPONSE,
          error: "Error in quiz_list effect",
        }: QuizListEffectResponse);
      }
    },
    subscribe: { [QUIZ_LIST_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuizEffectRequest) => {
      try {
        const data = await client(
          quiz,
          { id: msg.id }
        );

        return ({
          tag: QUIZ_EFFECT_RESPONSE,
          data: data.quiz,
        }: QuizEffectResponse);
      }
      catch (e) {
        return ({
          tag: QUIZ_EFFECT_RESPONSE,
          error: "Error in quiz effect",
        }: QuizEffectResponse);
      }
    },
    subscribe: { [QUIZ_EFFECT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuizHistoryEffectRequest) => {
      try {
        const data = await client(
          quizHistory,
          { id: msg.id }
        );

        return ({
          tag: QUIZ_HISTORY_EFFECT_RESPONSE,
          data: data.quizHistory,
        }: QuizHistoryEffectResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: QUIZ_HISTORY_EFFECT_RESPONSE,
          error: "Error in quiz_history effect",
        }: QuizHistoryEffectResponse);
      }
    },
    subscribe: { [QUIZ_HISTORY_EFFECT_REQUEST]: true },
  });

  // history rows

  storage.addEffect({
    effect: async (msg: QuizHistoryRowsEffectRequest) => {
      try {
        const data = await client(
          quizHistoryRows,
          { id: msg.id }
        );

        return ({
          tag: QUIZ_HISTORY_ROWS_EFFECT_RESPONSE,
          data: data.quizHistoryRows,
        }: QuizHistoryRowsEffectResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: QUIZ_HISTORY_ROWS_EFFECT_RESPONSE,
          error: "Error in quiz_history effect",
        }: QuizHistoryRowsEffectResponse);
      }
    },
    subscribe: { [QUIZ_HISTORY_ROWS_EFFECT_REQUEST]: true },
  });

  // history list

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          quizHistoryList
        );

        return ({
          tag: QUIZ_HISTORY_LIST_EFFECT_RESPONSE,
          data: data.quizHistoryList,
        }: QuizHistoryListEffectResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: QUIZ_HISTORY_LIST_EFFECT_RESPONSE,
          error: "Error in quiz_history_list effect",
        }: QuizHistoryListEffectResponse);
      }
    },
    subscribe: { [QUIZ_HISTORY_LIST_EFFECT_REQUEST]: true },
  });
};

export default registerClient;
