/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { QuestionnaireListResponse } from "state/questionnaire-list";
import type {
  QuestionnaireRequest,
  QuestionnaireResponse,
  QuestionnaireSubmitRequest,
  QuestionnaireSubmitResponse,
} from "state/questionnaire";

import {
  QUESTIONNAIRE_LIST_REQUEST,
  QUESTIONNAIRE_LIST_RESPONSE,
} from "state/questionnaire-list";

import {
  QUESTIONNAIRE_REQUEST,
  QUESTIONNAIRE_RESPONSE,
  QUESTIONNAIRE_SUBMIT_REQUEST,
  QUESTIONNAIRE_SUBMIT_RESPONSE,
} from "state/questionnaire";

import { syncCustomer } from "@crossroads/shop-state/customer";

import {
  customer,
  questionnaires,
  questionnaire,
  postQuestionnaireResponse } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(
        questionnaires
      );

      return ({
        tag: QUESTIONNAIRE_LIST_RESPONSE,
        data: data.questionnaires,
      }: QuestionnaireListResponse);
    },
    subscribe: { [QUESTIONNAIRE_LIST_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuestionnaireRequest) => {
      const data = await client(
        questionnaire,
        { id: msg.id },
        { cache: true }
      );

      return ({
        tag: QUESTIONNAIRE_RESPONSE,
        data: data.questionnaire,
      }: QuestionnaireResponse);
    },
    subscribe: { [QUESTIONNAIRE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuestionnaireSubmitRequest) => {
      const data = await client(
        postQuestionnaireResponse,
        { id: msg.id, time: msg.time, responses: msg.responses },
        { cache: false }
      );

      if (data.postQuestionnaireResponse.response === "ERROR") {
        return ({
          tag: QUESTIONNAIRE_SUBMIT_RESPONSE,
          error: "ERROR",
        }: QuestionnaireSubmitResponse);
      }

      if (data.postQuestionnaireResponse.response === "SUCCESS") {
        const { customer: data } = await client(customer);

        if (data) {
          storage.broadcastMessage(syncCustomer(data));
        }
      }

      return ({
        tag: QUESTIONNAIRE_SUBMIT_RESPONSE,
        data: data.postQuestionnaireResponse.response,
      }: QuestionnaireSubmitResponse);
    },
    subscribe: { [QUESTIONNAIRE_SUBMIT_REQUEST]: true },
  });
};

export default registerClient;
