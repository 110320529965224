/* @flow */

import type { Questionnaire, PostQuestionnaireResponseResult, QuestionnaireQuestionInput } from "shop-state/types";
import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";

import { updateData, updateNone, EFFECT_ERROR } from "crustate";

type Data =
  | {| state: "LOADING" |}
  | {| state: "LOADED", data: Questionnaire |}
  | {| state: "ERROR", error: mixed |}
  | {| state: "SUBMITTING", data: Questionnaire |}
  | {| state: "SUBMITTED", data: Questionnaire, result: PostQuestionnaireResponseResult |};

export type QuestionnaireResponse = Response<typeof QUESTIONNAIRE_RESPONSE, ?Questionnaire>;

export type QuestionnaireRequest = {
  tag: typeof QUESTIONNAIRE_REQUEST,
  id: number,
};

export type QuestionnaireSubmitResponse =
  Response<typeof QUESTIONNAIRE_SUBMIT_RESPONSE, PostQuestionnaireResponseResult>;
export type QuestionnaireSubmitRequest = {
  tag: typeof QUESTIONNAIRE_SUBMIT_REQUEST,
  id: number,
  time: number,
  responses: Array<QuestionnaireQuestionInput>,
};

export const QUESTIONNAIRE_RESPONSE: "response/questionnaire" = "response/questionnaire";
export const QUESTIONNAIRE_REQUEST: "request/questionnaire" = "request/questionnaire";

export const QUESTIONNAIRE_SUBMIT_RESPONSE: "response/questionnaire_submit" = "response/questionnaire_submit";
export const QUESTIONNAIRE_SUBMIT_REQUEST: "request/questionnaire_submit" = "request/questionnaire_submit";

export const getQuestionnaire = (id: number) => ({
  tag: QUESTIONNAIRE_REQUEST,
  id,
});

export const submitQuestionnaire = (
  id: number,
  time: number,
  responses: Array<QuestionnaireQuestionInput>) => ({
  tag: QUESTIONNAIRE_SUBMIT_REQUEST,
  id,
  time,
  responses,
});

export const QuestionnaireModel: Model<
  Data,
  { id: number },
  QuestionnaireRequest |
  QuestionnaireResponse |
  QuestionnaireSubmitResponse |
  QuestionnaireSubmitRequest |
  EffectErrorMessage
> = {
  id: "questionnaire",
  init: ({ id }) =>
    updateData({ state: "LOADING" }, getQuestionnaire(id)),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });

      case QUESTIONNAIRE_SUBMIT_REQUEST:
        if (state.state === "LOADED") {
          return updateData({ state: "SUBMITTING", data: state.data }, msg);
        }

        return updateNone();
      case QUESTIONNAIRE_SUBMIT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data && state.state === "SUBMITTING") {
          return updateData({ state: "SUBMITTED", data: state.data, result: msg.data });
        }

        return updateData({ state: "ERROR", error: "Could not submit questionnaire" });
      case QUESTIONNAIRE_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        return updateData({ state: "ERROR", error: "Questionnaire not found" });
      default:
    }
  },
};
