/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import type {
  // LeaderboardDistrictsStateRequest,
  LeaderboardDistrictsResponse,
  LeaderboardStateRequest,
  LeaderboardResponse,
} from "state/leaderboard";

import {
  LEADERBOARD_DISTRICTS_REQUEST,
  LEADERBOARD_DISTRICTS_RESPONSE,
  LEADERBOARD_REQUEST,
  LEADERBOARD_RESPONSE } from "state/leaderboard";
import { leaderboard, leaderboardDistricts } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: LeaderboardStateRequest) => {
      try {
        const data = await client(leaderboard,
          { fromdate: msg.fromdate, todate: msg.todate, district: msg.district, pagesize: 999 },
          { cache: true });
        return ({
          tag: LEADERBOARD_RESPONSE,
          data: data && data.leaderboard,
        }: LeaderboardResponse);
      }
      catch (e) {
        return ({
          tag: LEADERBOARD_RESPONSE,
          data: null,
          error: "ERROR",
        });
      }
    },
    subscribe: { [LEADERBOARD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(leaderboardDistricts, {});
        return ({
          tag: LEADERBOARD_DISTRICTS_RESPONSE,
          data: data && data.leaderboardDistricts,
        }: LeaderboardDistrictsResponse);
      }
      catch (e) {
        return ({
          tag: LEADERBOARD_RESPONSE,
          data: null,
          error: "ERROR",
        });
      }
    },
    subscribe: { [LEADERBOARD_DISTRICTS_REQUEST]: true },
  });
};

export default registerClient;
