/* @flow */

import type { Routes, StoreContent } from "shop-state/types";

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import MembersSettings from "components/MemberGroupView/MembersSettings";
import TransferPoints from "components/MemberGroupView/TransferPoints";
import { MemberGroupIsOwnerData } from "data";
import cn from "classnames";

import styles from "./styles.scss";

export type BaseProps = {
  page?: string,
  basePath: string,
};

type Props = {
  page?: string,
  basePath: string,
  routes: Routes,
  content: StoreContent,
};

export type ExtendedProps = Props;

export const MemberGroupView = ({ page, basePath }: BaseProps) => {
  const userMemberGroupData = useData(MemberGroupIsOwnerData);
  const { routes, content } = useContext(StoreInfoContext);
  const history = useHistory();

  if (userMemberGroupData.state !== "LOADED" || !userMemberGroupData.data) {
    return null;
  }

  const { owner } = userMemberGroupData.data;

  if (!owner && routes.accountView) {
    history.push(routes.accountView.url);
  }

  const renderSubPage = () => {
    switch (page) {
      case "share-points":
        return <TransferPoints basePath={basePath} page={page} routes={routes} content={content} />;
      case "members":
      default:
        return (
          <MembersSettings basePath={basePath} page={page} routes={routes} content={content} />
        );
    }
  };

  return (
    <Wrapper variant="pageWrapper">{renderSubPage()}</Wrapper>
  );
};

export default MemberGroupView;

export const BtnComponent = ({ basePath, page, routes, content }: Props): React$Node => {
  const { accountView } = routes;
  const { memberGroupView: { membersPageTitle, sharePageTitle } } = content;

  return (
    <div className={styles.btnField}>
      {accountView && accountView.url && accountView.title &&
        <Button
          variant="secondary" size="small"
          to={accountView.url}
          className={cn(styles.customBtn, "awardit-memberGroupTabBtn", { [styles.active]: page === accountView.url })}
        >
          {accountView.title ?? ""}
        </Button>
      }
      <Button
        variant="secondary" size="small"
        to={`${basePath}/members`}
        className={cn(styles.customBtn, "awardit-memberGroupTabBtn", { [styles.active]: page === "members" })}
      >
        {membersPageTitle ?? ""}
      </Button>
      <Button
        variant="secondary" size="small"
        to={`${basePath}/share-points`}
        className={cn(styles.customBtn, "awardit-memberGroupTabBtn", { [styles.active]: page === "share-points" })}
      >
        {sharePageTitle ?? ""}
      </Button>
    </div>
  );
};
