/* @flow */

import type { AdditionalMemberNumber } from "shop-state/types";

import React, { useContext, useEffect, useState } from "react";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import Button from "components/Button";
import ChevronIcon from "icons/chevron-mini.svg";
import { additionalMemberNumbers } from "queries";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "components/AccountView/styles.scss";

const AdditionalMemberNumbers = () => {
  const { content: { accountview }, routes } = useContext(StoreInfoContext);
  const client = useClient();
  const [memberNumberData, setMemberNumberData] = useState<Array<AdditionalMemberNumber>>([]);
  const t = useTranslate();

  useEffect(() => {
    client(additionalMemberNumbers).then(({ additionalMemberNumbers }) => {
      if (additionalMemberNumbers.length > 0) {
        setMemberNumberData(additionalMemberNumbers.slice(0, 3));
      }
    });
  }, []);

  if (memberNumberData.length <= 0) {
    return null;
  }

  return (
    <div>
      <header className={styles.boxHeader}>
        {accountview.additionalMemberNumbersHeading &&
          <h2 className={styles.boxHeading}>{accountview.additionalMemberNumbersHeading}</h2>
        }
        {routes.accountAdditionalMemberNumbers && routes.accountAdditionalMemberNumbers.url &&
          <Button to={routes.accountAdditionalMemberNumbers.url} type="button">
            {accountview.open &&
              <span>{accountview.open}</span>
            }
            <ChevronIcon />
          </Button>
        }
      </header>
      <section className={styles.boxBody}>
        <table className={styles.additionalMemberNumbersTable}>
          <thead>
            <tr>
              <th>{t("ADDITIONAL_MEMBER_NUMBERS.TABLE.NAME")}</th>
              <th>{t("ADDITIONAL_MEMBER_NUMBERS.TABLE.NUMBER")}</th>
            </tr>
          </thead>
          <tbody>
            {memberNumberData.map(x => (
              <tr key={x.id}>
                <td>{x.name}</td>
                <td>{x.id}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default AdditionalMemberNumbers;
