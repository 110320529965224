/* @flow */

import { useEffect, useState } from "react";
import { throttle } from "diskho";

type Direction = "lt" | "gt";

const useDevice = (direction: Direction, breakpoint: number = 550) => {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = throttle(() => setWidth(window.innerWidth), 1000);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return direction === "gt" ?
    width > breakpoint :
    width < breakpoint;
};

export default useDevice;
