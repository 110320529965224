/* @flow */

import React, { useContext, useState, useEffect } from "react";
import cn from "classnames";

type Value = string | number;
type LeaderboardItems = $ReadOnlyArray<LeaderboardItems>;

import { StoreInfoContext } from "entrypoint/shared";
import { useData, useSendMessage } from "crustate/react";
import { getLoadLeaderboardByDate } from "state/leaderboard";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import HeroLeaderboardTable from "components/HomeHeroLeaderboard/HeroLeaderboardTable";
import UserIcon from "icons/user-leaderboard.svg";
import ArrowUpIcon from "icons/arrow-up-leaderboard.svg";
import StarIcon from "icons/star-leaderboard.svg";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";
import LoadingView from "components/LoadingView";

import SortOptions from "components/LeaderboardView/SortOptions";
import { LeaderboardData, CustomerData, LeaderboardDistrictsData } from "data";

import styles from "./styles.scss";

const HomeHero = () => {
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { info, content: { leaderboardview, homeview } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const leaderboard = useData(LeaderboardData);
  const leaderboardDistrics = useData(LeaderboardDistrictsData);
  const leaderboardDistricsList = leaderboardDistrics.state === "LOADED" ? leaderboardDistrics.data : [];
  const leaderboardRows = leaderboard.state === "LOADED" ? leaderboard.data.leaderboard.slice(0, 3) : [];
  const leaderboardCount = leaderboard.state === "LOADED" && leaderboard.data.amountofwinners ? leaderboard.data.amountofwinners.count : 0;
  const currentLeaderboardUser = leaderboard.state === "LOADED" ?
    leaderboard.data.leaderboard.find(item => item.name === leaderboard.data.user.name) :
    null;
  const leaderboardCurrentDistric = leaderboard.state === "LOADED" && leaderboard.data.currentdistrict ?
    leaderboard.data.currentdistrict : null;

  const initDate = new Date();

  const [currentDistrict, setCurrentDistrict] = useState<string | null>(leaderboardCurrentDistric);
  const [activeLeaderboard, setActiveLeaderboard] = useState<string>("year");
  const [selectedFromDate, setSelectedFromDate] = useState<string>("");
  const [selectedToDate, setSelectedToDate] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<Value | null>(initDate.getFullYear().toString());
  const [pointsDeff, setPointsDeff] = useState<number | null>(null);

  const lastDateOfMonth = (date: Date) => (
    new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate()
  );

  const previousMonth = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    let dateArr = [];

    if (month === 0) {
      dateArr = [year - 1, 11];
    }
    else {
      dateArr = [year, month - 1];
    }

    if (dateArr[1] < 9) {
      return `${dateArr[0]}-0${dateArr[1] + 1}`;
    }

    return `${dateArr[0]}-${dateArr[1] + 1}`;
  };

  const getMonthLeaderboard = () => {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${year}-${month}-01`,
      todate: `${year}-${month}-${lastDateOfMonth(date)}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("month");
  };

  const getPrevMonthLeaderboard = () => {
    const currentDate = new Date();
    const prevYearMonth = previousMonth(currentDate);
    const prevDate = new Date(prevYearMonth);

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${prevYearMonth}-01`,
      todate: `${prevYearMonth}-${lastDateOfMonth(prevDate)}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("prevMonth");
  };

  const getYearLeaderboard = () => {
    const date = new Date();
    const fromDate = `${date.getFullYear()}-01-01`;
    const toDate = `${date.getFullYear()}-12-31`;

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
    setActiveLeaderboard("year");
  };

  const getSelectedYearLeaderboard = (selectedYear: Value) => {
    const date = new Date(selectedYear);
    const fromDate = `${date.getFullYear()}-01-01`;
    const toDate = `${date.getFullYear()}-12-31`;

    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
  };

  const getCustomDateLeaderboard = (fromDate: string, toDate: string) => {
    sendMessage(getLoadLeaderboardByDate({
      fromdate: `${fromDate}`,
      todate: `${toDate}`,
      district: currentDistrict ? currentDistrict : "",
    }));
  };

  useEffect(() => {
    if (leaderboard.state === "LOADED" && currentLeaderboardUser) {
      if (leaderboardCount > 0 && leaderboardRows.length > 0) {
        setPointsDeff(leaderboardRows[leaderboardCount - 1].points -
          (currentLeaderboardUser.points !== null ? currentLeaderboardUser.points : 0));
      }
      else if (leaderboardRows.length > 0) {
        setPointsDeff(leaderboardRows[0].points -
          (currentLeaderboardUser.points !== null ? currentLeaderboardUser.points : 0));
      }
      else {
        setPointsDeff(0);
      }
    }
  }, [leaderboard]);

  useEffect(() => {
    if (leaderboard.state !== "LOADED") {
      return;
    }

    if (currentDistrict) {
      const { flags } = leaderboard.data;
      if (flags && (flags.showdatesel > 0 && flags.showdatesel < 3)) {
        getYearLeaderboard();

        return;
      }

      sendMessage(getLoadLeaderboardByDate({ district: currentDistrict }));
    }
  }, [currentDistrict]);

  useEffect(() => {
    if (currentDistrict === null && leaderboardDistricsList.length > 0) {
      const arr = leaderboardDistricsList.filter(district => district.primary === true);
      if (arr.length !== 0) {
        const primaryDist = arr[0].id;
        setCurrentDistrict(primaryDist);
      }
    }
  }, [leaderboardDistricsList]);

  const renderMissingInfoMessage = () => {
    const lbData = leaderboard.state === "LOADED" ? leaderboard.data : null;
    const excludedPartners = ["alcro_sek", "beckers_sek"];

    if (lbData === null || excludedPartners.includes(info.code)) {
      return null;
    }

    if (homeview.motivationLeaderboardWidgetIsActiveNameTable !== undefined &&
    homeview.motivationLeaderboardWidgetIsActiveNameTable !== null &&
    homeview.motivationLeaderboardWidgetIsActiveNameTable === false &&
      lbData.user.points === 0 && lbData.user.position === 0) {
      return (
        <div className={cn(styles.widgetRow, styles.missingData)}>
          <div className={styles.missingDataInner}>
            <span>{t("LEADERBOARD.MISSING_USER_DATA")}</span>
          </div>
        </div>
      );
    }

    if ((leaderboard.state === "LOADED" && lbData.leaderboard.length === 0)) {
      return (
        <div className={cn(styles.widgetRow, styles.missingData)}>
          <div className={styles.missingDataInner}>
            <span>{t("LEADERBOARD.MISSING_DATA")}</span>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className={cn("awardit-homeHeroLeaderboardFullWidth", {
      [styles.fullWidth]: homeview.motivationHeroImageFullWidth })}
    >
      <div className={cn("awardit-homeHeroLeaderboardHero", styles.hero)}>
        <picture className={styles.image}>
          <source
            srcSet={homeview.motivationHeroImage ?? ""}
            media={`(max-width: ${parseInt(styles.tiny, 10) - 1}px)`}
          />
          <source
            srcSet={homeview.motivationHeroImage ?? ""}
            media={`(min-width: ${parseInt(styles.tiny, 10)}px)`}
          />
          <img alt="" src={homeview.motivationHeroImage ?? ""} />
        </picture>
        {(leaderboard.state !== "LOADED") && (
          <div className={styles.loadingWrapper}>
            <LoadingView />
          </div>
        )}
        <div className={cn(styles.widgetWrapper, "awardit-leaderboardWidgetWrapper")}>
          {(leaderboard.state === "LOADED") && (
            <section className={cn(styles.widget, "awardit-leaderboardWidget")}>
              {homeview.motivationHeroTitle &&
                <div className={styles.widgetHead}>
                  <h2>{homeview.motivationHeroTitle}</h2>
                </div>
              }
              <div className={styles.widgetControls}>
                {(leaderboard.state === "LOADED" && leaderboard.data.currentdistrict &&
                  leaderboardDistricsList.length > 0) && (
                  <div className={styles.districtDropdownContainer}>
                    <Dropdown
                      name="leaderboardDistricts"
                      className={cn(styles.dropdown, "awardit-leaderboardPageDistrictSelect")}
                      placeholder={leaderboardview.showAmoutOfColumns ?? ""}
                      value={currentDistrict ? currentDistrict : leaderboard.data.currentdistrict}
                      onChange={(val: any) => setCurrentDistrict(val)}
                    >
                      {leaderboardDistricsList.map(district => (
                        <DropdownItem key={district.id} value={district.id}>
                          {district.label}
                        </DropdownItem>
                      ))}
                    </Dropdown>
                  </div>
                )}
                {(leaderboard.state === "LOADED" &&
                  leaderboard.data.flags && leaderboard.data.flags.showdatesel > 0) && (
                  <div className={styles.widgetCtas}>
                    <SortOptions
                      datesel={leaderboard.data.flags.showdatesel}
                      activeLeaderboard={activeLeaderboard}
                      getMonthLeaderboard={() => getMonthLeaderboard()}
                      getPrevMonthLeaderboard={() => getPrevMonthLeaderboard()}
                      getYearLeaderboard={() => getYearLeaderboard()}
                      getCustomDateLeaderboard={(from, to) => getCustomDateLeaderboard(from, to)}
                      getSelectedYearLeaderboard={
                        selectedYear => getSelectedYearLeaderboard(selectedYear)
                      }
                      selectedFromDate={selectedFromDate}
                      selectedToDate={selectedToDate}
                      selectedYear={selectedYear}
                      setSelectedFromDate={x => setSelectedFromDate(x)}
                      setSelectedToDate={x => setSelectedToDate(x)}
                      setSelectedYear={x => setSelectedYear(x)}
                    />
                  </div>
                )}
              </div>
              {(homeview.motivationHeroDescription) && (
                /* eslint-disable react/no-danger */
                <div
                  dangerouslySetInnerHTML={{ __html: homeview.motivationHeroDescription }}
                  className={styles.widgetHead}
                />
                /* eslint-enable react/no-danger */
              )}

              {renderMissingInfoMessage()}

              {(homeview.motivationHeroPointsText &&
                leaderboard.data.user.points !== null && currentLeaderboardUser) && (
                <div className={styles.widgetRow}>
                  <StarIcon />
                  <p>{homeview.motivationHeroPointsText}</p>
                  <p>{currentLeaderboardUser.points}</p>
                </div>
              )}
              {(homeview.motivationHeroPositionText &&
                leaderboard.data.user.position !== null && currentLeaderboardUser) && (
                <div className={styles.widgetRow}>
                  <UserIcon />
                  <p>{homeview.motivationHeroPositionText}</p>
                  <p>{currentLeaderboardUser.position}</p>
                </div>
              )}
              {(homeview.motivationHeroPointsToFirstText &&
                leaderboard.data.user.points !== null && currentLeaderboardUser) && (
                <div className={styles.widgetRow}>
                  <ArrowUpIcon />
                  <p>{homeview.motivationHeroPointsToFirstText}</p>
                  <p>{pointsDeff !== null && pointsDeff}</p>
                </div>
              )}
              {(info.code === "alcro_sek" || info.code === "beckers_sek") && customer.state === "LOGGED_IN" &&
                customer.data.awarditTotchildinhouse !== null && (
                <>
                  <div className={styles.widgetRow}>
                    <UserIcon />
                    <p>Sålda liter färg</p>
                    <p>{leaderboard.data.totalreported.sumamount}</p>
                  </div>
                  <div className={styles.widgetRow}>
                    <StarIcon />
                    <p>Procent av vårorder</p>
                    <p>
                      {(leaderboard.data.totalreported.sumamount !== 0 &&
                        customer.data.awarditTotchildinhouse !== 0) ?
                        parseInt((parseInt(leaderboard.data.totalreported.sumamount, 10) /
                        parseInt(customer.data.awarditTotchildinhouse, 10)) * 100, 10) : 0
                      }
                    </p>
                  </div>
                  <div className={styles.widgetRow}>
                    <StarIcon />
                    <p>Vårorder</p>
                    <p>{customer.data.awarditTotchildinhouse}</p>
                  </div>
                  <div className={styles.widgetRow}>
                    <ArrowUpIcon />
                    <p>Kvar att sälja</p>
                    <p>
                      { parseInt(customer.data.awarditTotchildinhouse, 10) >
                    parseInt(leaderboard.data.totalreported.sumamount, 10) ?
                        parseInt(customer.data.awarditTotchildinhouse, 10) -
                    parseInt(leaderboard.data.totalreported.sumamount, 10) : 0
                      }
                    </p>
                  </div>
                </>
              )}
              {(homeview.motivationLeaderboardWidgetIsActiveNameTable) && (
                <HeroLeaderboardTable
                  currentLeaderboardUser={currentLeaderboardUser}
                  leaderboardRows={leaderboardRows}
                />
              )}
              {(homeview.motivationDescription) && (
                <div className={styles.motivationDescription}>
                  <p>{homeview.motivationDescription}</p>
                </div>
              )}
              <div className={styles.cta}>
                {(homeview.motivationHeroButtonPrimaryText &&
                  homeview.motivationHeroButtonPrimaryLink) && (
                  <Button to={homeview.motivationHeroButtonPrimaryLink} type="button" variant="primary">
                    {homeview.motivationHeroButtonPrimaryText}
                  </Button>
                )}
                {(homeview.motivationHeroButtonSecondaryText &&
                  homeview.motivationHeroButtonSecondaryLink) && (
                  <Button to={homeview.motivationHeroButtonSecondaryLink} type="button" variant="ghost">
                    {homeview.motivationHeroButtonSecondaryText}
                  </Button>
                )}
              </div>
            </section>
          )}
          {(leaderboard.state === "LOADED" &&
            (homeview.motivationHeroReportTitle ||
            homeview.motivationHeroReportDescription ||
            homeview.motivationHeroButtonReportText)) && (
            <section className={cn(styles.widget, styles.report)}>
              {homeview.motivationHeroReportTitle &&
                <h2>{homeview.motivationHeroReportTitle}</h2>
              }
              {homeview.motivationHeroReportDescription &&
                <div className={styles.description}>
                  {/* eslint-disable react/no-danger */}
                  <p
                    dangerouslySetInnerHTML={{ __html: homeview.motivationHeroReportDescription }}
                  />
                  {/* eslint-enable react/no-danger */}
                </div>
              }
              {(homeview.motivationHeroButtonReportText &&
              homeview.motivationHeroButtonReportLink) && (
                <div className={styles.cta}>
                  <Button to={homeview.motivationHeroButtonReportLink} variant="primary">
                    {homeview.motivationHeroButtonReportText}
                  </Button>
                </div>
              )}
            </section>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeHero;
