/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useHistory, Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

const CLOSED_SUFFIX = "/f/closed";
const ANSWERED_SUFFIX = "/f/answered";

const Questionnaires = () => {
  const { location: { pathname } } = useHistory();
  const { routes, content: { questionnairelistview } } = useContext(StoreInfoContext);
  const questionaireUrl = routes.questionnaireView && routes.questionnaireView.url;

  if (!questionaireUrl) {
    return null;
  }

  return (
    <div className={cn("awardit-subMenuQuestionnaire", styles.questionnaire)}>
      {questionnairelistview.open &&
        <Link
          className={cn("awardit-subMenuQuestionnaireLink", styles.link, {
            [styles.active]: pathname === questionaireUrl,
          })}
          to={questionaireUrl}
        >
          {questionnairelistview.open}
        </Link>
      }
      {questionnairelistview.closed &&
        <Link
          className={cn("awardit-subMenuQuestionnaireLink", styles.link, {
            [styles.active]: pathname === questionaireUrl + CLOSED_SUFFIX,
          })}
          to={questionaireUrl + CLOSED_SUFFIX}
        >
          {questionnairelistview.closed}
        </Link>
      }
      {questionnairelistview.answered &&
        <Link
          className={cn("awardit-subMenuQuestionnaireLink", styles.link, {
            [styles.active]: pathname === questionaireUrl + ANSWERED_SUFFIX,
          })}
          to={questionaireUrl + ANSWERED_SUFFIX}
        >
          {questionnairelistview.answered}
        </Link>
      }
    </div>
  );
};

export default Questionnaires;
