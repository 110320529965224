/* @flow */

import type { CurrentInfoItem } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";

import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: ?CurrentInfoItem }
  | { state: "ERROR", error: string };

export type CurrentInfoItemResponse = Response<typeof CURRENT_INFO_ITEM_RESPONSE, ?CurrentInfoItem>;

export type CurrentInfoItemRequest = {
  tag: typeof CURRENT_INFO_ITEM_REQUEST,
  id: string,
};

export const CURRENT_INFO_ITEM_RESPONSE: "response/current_info_item" = "response/current_info_item";
export const CURRENT_INFO_ITEM_REQUEST: "request/current_info_item" = "request/current_info_item";

export const CurrentInfoItemModel: Model<
  Data,
  { id: string },
  CurrentInfoItemRequest |
  CurrentInfoItemResponse
> = {
  id: "current_info_item",
  init: ({ id }) =>
    updateData(
      { state: "LOADING" },
      { tag: CURRENT_INFO_ITEM_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case CURRENT_INFO_ITEM_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        return updateData({ state: "LOADED", data: msg.data ? msg.data : null });
      default:
    }
  },
};
