/* @flow */

import type { Content } from "state/messages";
import type { Level } from "shop-state/types";

import React, { useState, useRef, useEffect, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { removeMessage } from "state/messages";
import { MessagesData } from "data";
import { useData, useSendMessage } from "crustate/react";
import InsufficientFundsMessage from "components/SystemMessages/InsufficientFundsMessage";
import DetailsMissing from "components/SystemMessages/DetailsMissing";
import RemoveIcon from "icons/close-small.svg";

import cn from "classnames";
import styles from "./styles.scss";

type MessageProps = {
  content: Content,
  level: Level,
  translated: boolean,
  onRemove: () => void,
};

const SELFCLOSING_MESSAGE_DURATION = 8000;

const Message = ({ content, level, onRemove, translated }: MessageProps) => {
  const t = useTranslate();
  const [deleting, setDeleting] = useState(false);
  const messageRef = useRef(null);
  const transitionDuration = Number.parseInt(styles.messageTransition, 10);
  const timer = useRef(null);

  const {
    content: {
      systemMessages: {
        generalErrorMessage,
        generalSuccessMessage,
      },
    },
  } = useContext(StoreInfoContext);

  useEffect(() => {
    if (messageRef.current) {
      const height = messageRef.current.clientHeight;
      messageRef.current.style.setProperty("--message-height", height + "px");
    }
  }, [messageRef]);

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  const close = (animate: boolean = true) => {
    if (animate) {
      setDeleting(true);

      if (timer.current) {
        clearTimeout(timer.current);
      }

      timer.current = setTimeout(() => {
        onRemove();
      }, transitionDuration);
    }
    else {
      onRemove();
    }
  };

  if (level === "insufficient-funds") {
    return (
      <InsufficientFundsMessage
        ref={messageRef}
        deleting={deleting}
        close={close}
      />
    );
  }

  if (level === "account-details-missing" && typeof content === "string") {
    return (
      <DetailsMissing
        ref={messageRef}
        content={content}
        deleting={deleting}
        close={close}
      />
    );
  }

  if (timer.current == null) {
    timer.current = setTimeout(() => {
      close();
    }, (transitionDuration * 2) + SELFCLOSING_MESSAGE_DURATION);
  }

  let text = "";

  if (!translated) {
    text = typeof content === "string" ?
      t("STATUS_CODE." + content) :
      t("STATUS_CODE." + content.translationKey, content.variable);
  }

  return (
    <div
      ref={messageRef}
      className={cn(
        styles.message,
        styles[level],
        { [styles.deleting]: deleting }
      )}
      onClick={close}
    >
      <div className={styles.itemInner}>
        <div className={styles.itemPadding}>
          <RemoveIcon className={styles.icon} />
          {level === "error" ? (
            generalErrorMessage ? <h2>{generalErrorMessage}</h2> : <h2>{t(`MESSAGE.${level.toUpperCase()}`)}</h2>
          ) : null }
          {level === "success" ? (
            generalSuccessMessage ? <h2>{generalSuccessMessage}</h2> : <h2>{t(`MESSAGE.${level.toUpperCase()}`)}</h2>
          ) : null }

          {translated ?
            <p>{typeof content === "string" && content} </p> :
            <p>{text ? text : t("STATUS_CODE.FALLBACK")}</p>
          }
        </div>
      </div>
    </div>
  );
};

const SystemMessages = () => {
  const sendMessage = useSendMessage();
  const messages = useData(MessagesData);

  return (
    <div className={styles.block}>
      <div className={styles.messages}>
        {messages.map(x => (
          <Message
            {...x}
            key={x.id}
            onRemove={() => {
              if (typeof x !== "undefined" && typeof x.id !== "undefined") {
                sendMessage(removeMessage(x.id));
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default SystemMessages;
