/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
    heading: string,
    linkUrl: string,
    image?: string,
    description?: string,
};

const TopLevelCard = ({ heading, linkUrl, image, description }: Props) => (
  <div className={cn(styles.item, "awardit-topLevelCard")}>
    <Link
      to={{
        pathname: linkUrl,
        state: { hint: { title: heading } },
      }}
    >
      {image &&
        <img src={image} alt={heading} />
      }
      <div className={styles.itemContent}>
        <h3>{heading}</h3>
        {description &&
          <p>{description}</p>
        }
      </div>
    </Link>
  </div>
);

export default TopLevelCard;
