/* @flow */

import type { ExtendedProps } from "components/MemberGroupView";
import type { ValidationError } from "@awardit/formaggio";
import type { GroupMember } from "shop-state/types";
import type { FormData } from "@awardit/formaggio/src/types";

import React, { Fragment, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Form, rules, isRequired, isEmail, isPhone } from "@awardit/formaggio";
import { useClient } from "entrypoint/shared";
import { useSendMessage } from "crustate/react";
import { addMessage, addMessageTranslated } from "state/messages";
import { membergroupList, membergroupRemoveMember, membergroupUpdateMember } from "queries";
import { focusInvalidField } from "helpers/utils";
import useFormat from "helpers/use-format";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import { useTranslate } from "@awardit/react-use-translate";
import { Foldable, Dialogue, Tooltip } from "@crossroads/ui-components";
import { Title } from "components/UiComponents";
import { BtnComponent } from "components/MemberGroupView";
import Transactions from "./Transactions";
import AddMemberForm from "./AddMemberForm";
import Breadcrumbs from "components/Breadcrumbs";
import Field, { CheckboxField } from "components/Field";
import Spinner from "components/Spinner";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { usePointsLabel } from "helpers/points";
import ChevronIcon from "icons/chevron-mini.svg";
import DeleteIcon from "icons/close-mini.svg";
import OwnerIcon from "icons/user-owner.svg";
import EditIcon from "icons/edit.svg";
import SaveIcon from "icons/save.svg";
import PlusIcon from "icons/plus.svg";
import ListIcon from "icons/list.svg";

import cn from "classnames";
import styles from "./styles.scss";

type TableProps = {
  items: Array<GroupMember>,
  onUserSelect: GroupMember => void,
  selectedEditMember: string | null,
  setSelectedEditMember: (((string | null) => string | null) | string | null) => void,
  editMemberFormState: FormData,
  setEditMemberFormState: any => void,
  formErrors: Array<ValidationError>,
  setSelectedMember: (string | null) => void,
  setShowTransactionsModal: boolean => void,
  setShowRemoveMemberModal: boolean => void,
  submitEditedMember: (SyntheticEvent<HTMLFormElement>) => Promise<void>,
};

type TableBtnProps = {
  x: GroupMember,
  onUserSelect: GroupMember => void,
  selectedEditMember: string | null,
  setSelectedEditMember: (((string | null) => string | null) | string | null) => void,
  setSelectedMember: (string | null) => void,
  editMemberFormState: FormData,
  setEditMemberFormState: FormData => void,
  setShowTransactionsModal: boolean => void,
  setShowRemoveMemberModal: boolean => void,
};

const initialEditMemberFormState = {
  memberuserid: "",
  firstname: "",
  lastname: "",
  phonenumber: "",
  email: "",
  signins: "",
  balance: 0,
  master: 0,
  membertypeid: 0,
  owner: false,
  active: false,
};

const initialAddMemberFormState = {
  memberuserid: "",
  firstname: "",
  lastname: "",
  phonenumber: "",
  email: "",
  isOwner: false,
  isActive: true,
};

const validation = rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("phonenumber"),
  isPhone("phonenumber"),
  isRequired("email"),
  isEmail("email"),
]);

const isUserType = (member: GroupMember) => {
  if (member.master === 1 && (member.membertypeid !== 1)) {
    return "OWNER";
  }

  if (member.master === 9 && (member.membertypeid === 2)) {
    return "OWNER";
  }

  if (member.membertypeid === 1) {
    return "COMPANY";
  }

  return "MEMBER";
};

const memberInfoHasChanged = (member: GroupMember, formState: FormData) => {
  if (
    (isUserType(member) === "OWNER" || isUserType(member) === "MASTER") !== (formState.owner) ||
    member.active !== formState.active ||
    member.firstname !== formState.firstname ||
    member.lastname !== formState.lastname ||
    member.phonenumber !== formState.phonenumber ||
    member.email !== formState.email
  ) {
    return true;
  }

  return false;
};

export const addIcon = () => {
  return <PlusIcon className={styles.addIcon} />;
};

const MembersSettings = ({ basePath, page, routes, content }: ExtendedProps): React$Node => {
  const [loading, setLoading] = useState<boolean>(false);
  const [memberGroupList, setMemberGroupList] = useState(null);
  const [editMemberFormState, setEditMemberFormState] = useState(initialEditMemberFormState);
  const [addMemberFormState, setAddMemberFormState] = useState(initialAddMemberFormState);
  const [showAddMemberForm, setShowAddMemberForm] = useState<boolean>(false);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState<boolean>(false);
  const [showTransactionsModal, setShowTransactionsModal] = useState<boolean>(false);
  const [selectedMember, setSelectedMember] = useState<string | null>(null);
  const [selectedEditMember, setSelectedEditMember] = useState<string | null>(null);

  const { accountView } = routes;
  const { memberGroupView: { membersPageTitle, membersHeading, membersDescription } } = content;

  const accountViewTitle = accountView && accountView.title ? accountView.title : "";
  const accountViewLink = accountView && accountView.url ? accountView.url : "";
  const parentLink = { name: accountViewTitle, url: accountViewLink };
  const formErrors = validation(editMemberFormState);
  const sendMessage = useSendMessage();
  const client = useClient();
  const t = useTranslate();

  useEffect(() => {
    getMembers();
  }, [client]);

  const getMembers = async () => {
    setLoading(true);
    await client(membergroupList).then(({ membergroupList }) => {
      setMemberGroupList(membergroupList);
    });
    setLoading(false);
  };

  const submitRemovedMember = async () => {
    if (selectedMember === null || selectedMember === undefined) {
      return;
    }

    setLoading(true);
    const response = await client(membergroupRemoveMember, { memberuserid: selectedMember });
    const { message } = await response.membergroupRemoveMember;

    if (response.membergroupRemoveMember.result === "SUCCESS") {
      sendMessage(addMessage(`REMOVE_MEMBER_SUCCESS`, "success"));
      setShowRemoveMemberModal(false);
      getMembers();
      setLoading(false);
    }

    if (response.membergroupRemoveMember.result === "ERROR" && message) {
      sendMessage(addMessageTranslated(message, "error"));
      setLoading(false);
    }

    if (response.membergroupRemoveMember.result === "ERROR" && !message) {
      sendMessage(addMessage(`REMOVE_MEMBER_ERROR`, "error"));
      setLoading(false);
    }
  };

  const submitEditedMember = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const response = await client(membergroupUpdateMember, {
      memberuserid: editMemberFormState.memberuserid,
      membertypeid: editMemberFormState.membertypeid,
      master: editMemberFormState.master,
      owner: editMemberFormState.owner,
      firstname: editMemberFormState.firstname,
      lastname: editMemberFormState.lastname,
      phonenumber: editMemberFormState.phonenumber,
      email: editMemberFormState.email,
      active: editMemberFormState.active,
    });

    const { message } = await response.membergroupUpdateMember;

    if (response.membergroupUpdateMember.result === "SUCCESS") {
      sendMessage(addMessage(`UPDATE_MEMBER_SUCCESS`, "success"));
      getMembers();
      setSelectedEditMember(null);
      setLoading(false);
    }

    if (response.membergroupUpdateMember.result === "ERROR" && message) {
      sendMessage(addMessageTranslated(message, "error"));
      setLoading(false);
    }

    if (response.membergroupUpdateMember.result === "ERROR" && !message) {
      sendMessage(addMessage(`UPDATE_MEMBER_ERROR`, "error"));
      setLoading(false);
    }
  };

  return (
    <>
      <Helmet title={`${accountViewTitle} / ${membersPageTitle ?? ""}`} />
      <Breadcrumbs parentLink={parentLink} current={membersPageTitle ?? ""} />
      <Title>{membersHeading ?? ""}</Title>
      <div className={styles.description}><p>{membersDescription ?? ""}</p></div>
      <BtnComponent basePath={basePath} page={page} routes={routes} content={content} />
      <Button
        className={styles.addBtn}
        slotLeft={<div className={styles.addIconWrapper}>{addIcon()}</div>}
        onClick={() => setShowAddMemberForm(!showAddMemberForm)}
      >
        {t("ACCOUNT.MEMBER_GROUP.ADD_MEMBER")}
      </Button>
      <AddMemberForm
        isOpen={showAddMemberForm}
        setIsOpen={setShowAddMemberForm}
        addMemberFormState={addMemberFormState}
        setAddMemberFormState={setAddMemberFormState}
        initialAddMemberFormState={initialAddMemberFormState}
        setLoading={setLoading}
        loading={loading}
        getMembers={() => {
          getMembers();
        }}
      />
      {!loading && (
        <TableLarge
          items={memberGroupList ?? []}
          selectedEditMember={selectedEditMember}
          setSelectedEditMember={setSelectedEditMember}
          formErrors={formErrors}
          setSelectedMember={setSelectedMember}
          editMemberFormState={editMemberFormState}
          setEditMemberFormState={setEditMemberFormState}
          setShowTransactionsModal={setShowTransactionsModal}
          setShowRemoveMemberModal={setShowRemoveMemberModal}
          submitEditedMember={submitEditedMember}
          onUserSelect={x => {
            setSelectedEditMember(selectedEditMember !== x.memberuserid ? x.memberuserid : null);
          }}
        />
      )}
      {!loading && (
        <TableSmall
          items={memberGroupList ?? []}
          selectedEditMember={selectedEditMember}
          setSelectedEditMember={setSelectedEditMember}
          formErrors={formErrors}
          setSelectedMember={setSelectedMember}
          editMemberFormState={editMemberFormState}
          setEditMemberFormState={setEditMemberFormState}
          setShowTransactionsModal={setShowTransactionsModal}
          setShowRemoveMemberModal={setShowRemoveMemberModal}
          submitEditedMember={submitEditedMember}
          onUserSelect={x => {
            setSelectedEditMember(selectedEditMember !== x.memberuserid ? x.memberuserid : null);
          }}
        />
      )}
      {/* TODO: add skeleton component */}
      {loading && <Wrapper className={styles.spinnerWrapper}><Spinner /></Wrapper>}

      {showTransactionsModal && selectedMember !== null && (
        <Transactions
          isOpen={showTransactionsModal}
          setOpen={open => setShowTransactionsModal(open)}
          memberGroupList={memberGroupList ?? []}
          selectedMember={selectedMember}
        />
      )}

      {showRemoveMemberModal && selectedMember !== null && (
        <Dialogue
          open={showRemoveMemberModal}
          setOpen={open => setShowRemoveMemberModal(open)}
          title={t("ACCOUNT.MEMBER_GROUP.REMOVE_MEMBER")}
          secondaryAction={t("COMMON.CANCEL")}
          primaryAction={t("COMMON.REMOVE")}
          onSecondaryAction={() => setShowRemoveMemberModal(false)}
          onPrimaryAction={() => {
            submitRemovedMember();
          }}
        >
          <p>{t("ACCOUNT.MEMBER_GROUP.REMOVE_MEMBER_CONFIRM", { member: `${selectedMember}` })}</p>
        </Dialogue>
      )}
    </>
  );
};

export default MembersSettings;

const TableLarge = ({
  items,
  onUserSelect,
  formErrors,
  selectedEditMember,
  setSelectedEditMember,
  setSelectedMember,
  editMemberFormState,
  setEditMemberFormState,
  setShowTransactionsModal,
  setShowRemoveMemberModal,
  submitEditedMember,
}: TableProps) => {
  const t = useTranslate();
  const { formatPoints } = useFormat(false);
  const label = usePointsLabel();

  if (items.length === 0) {
    return (
      <div className={cn(styles.table, styles.tableLarge)}>
        <div className={styles.thead}>
          <div className={cn(styles.th_spacer, styles.th_icon)} />
          <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.MEMBER_ID")}</div>
          <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.FULL_NAME")}</div>
          <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.SIGN_INS")}</div>
          <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.POINTS", { pointsLabel: label() })}</div>
          <div className={styles.th_spacer} />
        </div>
        <div className={styles.tbody}>
          <div className={styles.noMembersField}>{t("ACCOUNT.MEMBER_GROUP.NO_MEMBERS")}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.table, styles.tableLarge)}>
      <div className={styles.thead}>
        <div className={cn(styles.th_spacer, styles.th_icon)} />
        <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.MEMBER_ID")}</div>
        <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.FULL_NAME")}</div>
        <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.SIGN_INS")}</div>
        <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.POINTS", { pointsLabel: label() })}</div>
        <div className={styles.th_spacer} />
      </div>
      <div className={styles.tbody}>
        {items.map(x => ((isUserType(x) !== "COMPANY") &&
          <Fragment key={x.memberuserid}>
            <div className={cn(styles.tr,
              { [styles.open]: selectedEditMember === x.memberuserid },
              { [styles.outOfFocus]:
                selectedEditMember !== x.memberuserid && selectedEditMember !== null,
              })}
            >
              <div className={cn(styles.tr_icon, { [styles.no_icon]: isUserType(x) !== "OWNER" })}>
                {isUserType(x) === "OWNER" && (
                  <Tooltip direction="top" text={t("ACCOUNT.MEMBER_GROUP.OWNER")}><OwnerIcon /></Tooltip>
                )}
              </div>
              <div className={styles.shrink}><span>{x.memberuserid}</span></div>
              <div><span>{x.firstname} {x.lastname}</span></div>
              <div><span>{x.signins}</span></div>
              <div><span>{formatPoints(x.balance)}</span></div>
              <TableBtnComponent
                x={x}
                selectedEditMember={selectedEditMember}
                setSelectedEditMember={setSelectedEditMember}
                setSelectedMember={setSelectedMember}
                editMemberFormState={editMemberFormState}
                setEditMemberFormState={setEditMemberFormState}
                setShowTransactionsModal={setShowTransactionsModal}
                setShowRemoveMemberModal={setShowRemoveMemberModal}
                onUserSelect={onUserSelect}
              />
            </div>
            <Foldable open={selectedEditMember === x.memberuserid} className={styles.expandable}>
              <div className={styles.formWrapper}>
                <Form
                  className={styles.form}
                  id={`editUserForm-${x.memberuserid}`}
                  value={editMemberFormState}
                  errors={formErrors}
                  onError={focusInvalidField}
                  onSubmit={event => submitEditedMember(event)}
                  onChange={x => {
                    setEditMemberFormState({ ...editMemberFormState, ...x });
                  }}
                >
                  <Field variant="float" wrapperClassName={styles.field} name="firstname" label={t("ADDRESS.FIRSTNAME")} />
                  <Field variant="float" wrapperClassName={styles.field} name="lastname" label={t("ADDRESS.LASTNAME")} />
                  <Field variant="float" wrapperClassName={styles.field} name="phonenumber" label={t("ADDRESS.TELEPHONE")} />
                  <Field variant="float" wrapperClassName={styles.field} name="email" label={t("ADDRESS.EMAIL")} />
                  <CheckboxField name="owner" className={styles.checkbox}>
                    {t("ACCOUNT.MEMBER_GROUP.OWNER")}
                  </CheckboxField>
                  <CheckboxField name="active" className={styles.checkbox}>
                    {t("ACCOUNT.MEMBER_GROUP.CAN_LOGIN")}
                  </CheckboxField>
                </Form>
              </div>
            </Foldable>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

const TableSmall = ({
  items,
  onUserSelect,
  formErrors,
  selectedEditMember,
  setSelectedEditMember,
  setSelectedMember,
  editMemberFormState,
  setEditMemberFormState,
  setShowTransactionsModal,
  setShowRemoveMemberModal,
  submitEditedMember,
}: TableProps) => {
  const t = useTranslate();
  const { formatPoints } = useFormat(false);
  const label = usePointsLabel();

  const openMemberEditFoldable = item => {
    onUserSelect(item);
    const itemState = { ...item, owner: (isUserType(item) === "OWNER") };
    setEditMemberFormState(itemState);
  };

  if (items.length === 0) {
    return (
      <div className={cn(styles.table, styles.tableSmall)}>
        <div className={styles.thead}>
          <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.FULL_NAME")}</div>
          <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.POINTS", { pointsLabel: label() })}</div>
          <div className={styles.th_spacer} />
        </div>
        <div className={styles.tbody}>
          <div className={styles.noMembersField}>{t("ACCOUNT.MEMBER_GROUP.NO_MEMBERS")}</div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <div className={styles.thead}>
        <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.FULL_NAME")}</div>
        <div className={styles.th}>{t("ACCOUNT.MEMBER_GROUP.POINTS", { pointsLabel: label() })}</div>
        <div className={styles.th_spacer} />
      </div>
      <div className={styles.tbody}>
        {items.map(x => ((isUserType(x) !== "COMPANY") &&
          <Fragment key={x.memberuserid}>
            <div
              className={cn(styles.tr,
                { [styles.open]: selectedEditMember === x.memberuserid },
                { [styles.outOfFocus]:
                  selectedEditMember !== x.memberuserid && selectedEditMember !== null,
                })}
              onClick={() => openMemberEditFoldable(x)}
            >
              <div>{x.firstname} {x.lastname} {isUserType(x) === "OWNER" && (
                <Tooltip direction="top" text={t("ACCOUNT.MEMBER_GROUP.OWNER")}>
                  <OwnerIcon className={styles.ownerIcon} />
                </Tooltip>
              )}
              </div>
              <div>{formatPoints(x.balance)}</div>
              <div className={styles.chevron}><ChevronIcon /></div>
            </div>
            <Foldable open={selectedEditMember === x.memberuserid} className={styles.expandable}>
              <div className={cn(styles.table, styles.tableSmall, styles.subTable)}>
                <div className={styles.tbody}>
                  <div className={styles.tr}>
                    <div>{t("ACCOUNT.MEMBER_GROUP.MEMBER_ID")}</div>
                    <div className={styles.shrink}>{x.memberuserid}</div>
                  </div>
                  <div className={styles.tr}>
                    <div>{t("ACCOUNT.MEMBER_GROUP.SIGN_INS")}</div>
                    <div className={styles.shrink}>{x.signins}</div>
                  </div>
                  <div className={styles.tr}>
                    <div className={styles.th_spacer} />
                    <div>
                      <TableBtnComponent
                        x={x}
                        selectedEditMember={selectedEditMember}
                        setSelectedEditMember={setSelectedEditMember}
                        setSelectedMember={setSelectedMember}
                        editMemberFormState={editMemberFormState}
                        setEditMemberFormState={setEditMemberFormState}
                        setShowTransactionsModal={setShowTransactionsModal}
                        setShowRemoveMemberModal={setShowRemoveMemberModal}
                        onUserSelect={onUserSelect}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.formWrapper}>
                <Form
                  className={styles.form}
                  id={`editUserFormMobile-${x.memberuserid}`}
                  value={editMemberFormState}
                  errors={formErrors}
                  onError={focusInvalidField}
                  onSubmit={event => submitEditedMember(event)}
                  onChange={x => setEditMemberFormState({ ...editMemberFormState, ...x })}
                >
                  <Field variant="float" wrapperClassName={styles.field} name="firstname" label={t("ADDRESS.FIRSTNAME")} />
                  <Field variant="float" wrapperClassName={styles.field} name="lastname" label={t("ADDRESS.LASTNAME")} />
                  <Field variant="float" wrapperClassName={styles.field} name="phonenumber" label={t("ADDRESS.TELEPHONE")} />
                  <Field variant="float" wrapperClassName={styles.field} name="email" label={t("ADDRESS.EMAIL")} />
                  <CheckboxField name="owner" className={styles.checkbox}>
                    {t("ACCOUNT.MEMBER_GROUP.OWNER")}
                  </CheckboxField>
                  <CheckboxField name="active" className={styles.checkbox}>
                    {t("ACCOUNT.MEMBER_GROUP.CAN_LOGIN")}
                  </CheckboxField>
                </Form>
              </div>
            </Foldable>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

const TableBtnComponent = ({
  x,
  onUserSelect,
  selectedEditMember,
  setSelectedEditMember,
  setSelectedMember,
  editMemberFormState,
  setEditMemberFormState,
  setShowTransactionsModal,
  setShowRemoveMemberModal,
}: TableBtnProps) => {
  const smallViewport = Number.parseInt(styles.small, 10);
  const isMobile = useBrowserDimensions().width < smallViewport;
  const t = useTranslate();

  const closeMemberEditFoldable = () => {
    setSelectedEditMember(null);
    setEditMemberFormState(initialEditMemberFormState);
  };

  const openMemberEditFoldable = item => {
    onUserSelect(item);
    const itemState = { ...item, owner: isUserType(item) === "OWNER" };
    setEditMemberFormState(itemState);
  };

  const openRemoveMemberModal = item => {
    setSelectedMember(item.memberuserid);
    setShowRemoveMemberModal(true);
  };

  const openTransactionsModal = item => {
    setSelectedMember(item.memberuserid);
    setShowTransactionsModal(true);
  };

  return (
    <div className={styles.tableBtnWrapper}>
      {(selectedEditMember !== x.memberuserid || isMobile) && (
        <Tooltip direction="top" text={t("ACCOUNT.MEMBER_GROUP.REMOVE_MEMBER")}>
          <Button className={styles.tableBtn} onClick={() => openRemoveMemberModal(x)}>
            <DeleteIcon className={styles.deleteIcon} />
          </Button>
        </Tooltip>
      )}
      {(selectedEditMember === x.memberuserid && !isMobile) && (
        <Button
          className={styles.tableBtn}
          style={{ transform: "rotate(-90deg)" }}
          onClick={() => closeMemberEditFoldable()}
        ><ChevronIcon />
        </Button>
      )}
      {(
        <Tooltip direction="top" text={t("ACCOUNT.MEMBER_GROUP.TRANSACTIONS.DISPLAY")}>
          <Button className={styles.tableBtn} onClick={() => openTransactionsModal(x)}>
            <ListIcon />
          </Button>
        </Tooltip>
      )}
      {(selectedEditMember !== x.memberuserid) && (
        <Tooltip direction="top" text={t("ACCOUNT.MEMBER_GROUP.EDIT_MEMBER")}>
          <Button className={styles.tableBtn} onClick={() => openMemberEditFoldable(x)}>
            <EditIcon />
          </Button>
        </Tooltip>
      )}
      {(selectedEditMember === x.memberuserid) && (
        <Tooltip direction="top" text={t("ACCOUNT.MEMBER_GROUP.SAVE_CHANGES")}>
          <Button
            disabled={!memberInfoHasChanged(x, editMemberFormState)}
            className={cn(styles.tableBtn,
              { [styles.disabledBtn]: !memberInfoHasChanged(x, editMemberFormState) })
            }
            form={isMobile ? `editUserFormMobile-${x.memberuserid}` : `editUserForm-${x.memberuserid}`}
            type="submit"
          >
            <SaveIcon />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};
