/* @flow */

import { useContext, useMemo } from "react";
import { useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";

const useOnFullMenuHiddenRoute = () => {
  const { routes, configuration } = useContext(StoreInfoContext);
  const { pathname } = useLocation();
  const privacyNoticeUrl = "/privacy-notice";
  const earnView = ((routes.earnViewSubpage && routes.earnViewSubpage.toggle) ||
  (routes.earnViewSubpage2 && routes.earnViewSubpage2.toggle)) && true;

  const shopTermsUrl = (configuration.customShopTermsUrl &&
    configuration.hasCustomShopTermsUrl === true) ?
    configuration.customShopTermsUrl : "checkout-terms";

  const FULL_MENU_HIDDEN_ROUTES = useMemo(() => ([
    ...routes.accountView && routes.accountView.url ? [routes.accountView.url] : [],
    ...routes.offerListView && routes.offerListView.url ? [routes.offerListView.url] : [],
    ...routes.newsListView && routes.newsListView.url ? [routes.newsListView.url] : [],
    ...routes.quizList && routes.quizList.url ? [routes.quizList.url] : [],
    ...routes.quizHistory && routes.quizHistory.url ? [routes.quizHistory.url] : [],
    ...routes.termsView && routes.termsView.url ? [routes.termsView.url] : [],
    ...routes.currentInfoView1 && routes.currentInfoView1.url ? [routes.currentInfoView1.url] : [],
    ...routes.currentInfoView2 && routes.currentInfoView2.url ? [routes.currentInfoView2.url] : [],
    ...routes.currentInfoView3 && routes.currentInfoView3.url ? [routes.currentInfoView3.url] : [],
    ...routes.currentInfoView4 && routes.currentInfoView4.url ? [routes.currentInfoView4.url] : [],
    ...routes.currentInfoView5 && routes.currentInfoView5.url ? [routes.currentInfoView5.url] : [],
    ...routes.currentInfoView6 && routes.currentInfoView6.url ? [routes.currentInfoView6.url] : [],
    privacyNoticeUrl,
    shopTermsUrl,
    ...routes.leadsView && routes.leadsView.url ? [routes.leadsView.url] : [],
    ...routes.teamPot && routes.teamPot.url ? [routes.teamPot.url] : [],
    ...routes.calculatorView && routes.calculatorView.url ? [routes.calculatorView.url] : [],
    earnView === false ? routes.earnView?.url : undefined,
  ]), [routes]);

  return FULL_MENU_HIDDEN_ROUTES.some(route => pathname.includes(route));
};

export default useOnFullMenuHiddenRoute;
