/* @flow */

import React from "react";
import { Helmet } from "react-helmet-async";
import Wrapper from "components/Wrapper";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const ErrorView = () => {
  const t = useTranslate();
  return (
    <>
      <Helmet title={t("ERRORVIEW.TITLE")} />
      <Wrapper className={styles.block}>
        <h1>{t("ERRORVIEW.TITLE")}</h1>
      </Wrapper>
    </>
  );
};

export default ErrorView;
