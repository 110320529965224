/* @flow */

import { createStateData } from "crustate/react";

import { RouteModel } from "@crossroads/shop-state/route";
import { CustomerModel } from "state/customer";
import { QuoteModel } from "state/quote";
import { HomeModel } from "state/home";
import { RedeemModel } from "state/redeem";
import { OrderModel } from "state/order";
import { CheckoutStepModel } from "state/checkout-step";
import { BrandModel } from "@crossroads/shop-state/brand";
import { SearchModel } from "@crossroads/shop-state/search";
import { MessagesModel } from "state/messages";
import { NewsPostsModel } from "state/news-posts";
import { AffiliateModel } from "state/affiliate";
import { AffiliateCategoriesModel } from "state/affiliate-categories";
import { AffiliateListModel } from "state/affiliate-list";
import { AffiliateListSelectedModel } from "state/affiliate-list-selected";
import { TransactionsModel } from "state/transactions";
import { LeaderboardModel, LeaderboardDistrictsModel } from "state/leaderboard";
import { ReportFormModel, ReportListModel, ReportHistoryModel } from "state/reports";
import { VerifyListModel } from "state/verify-list";
import { ViewModeModel } from "state/view-mode";
import { ConnectedAccountsModel } from "state/connected-accounts";
import { QuestionnaireListModel } from "state/questionnaire-list";
import { QuizListModel } from "state/quiz-list";
import { QuizModel } from "state/quiz";
import { QuizHistoryModel, QuizHistoryListModel, QuizHistoryRowsModel } from "state/quiz-history";
import { TeampotMembersModel, TeampotHistoryModel, TeampotSettingsModel, TeampotStatusModel, TeampotActivitiesModel, TeampotHistoryItemsModel } from "state/teampot";
import { QuestionnaireModel } from "state/questionnaire";
import { DistrictInfoModel } from "state/district-info";
import { DistrictListModel } from "state/district-list";
import { AllProductsModel } from "@crossroads/shop-state/all-products";
import { OffersModel } from "state/offers";
import { TinkTransactionsModel } from "state/tink-transactions";
import { TinkProvidersModel } from "state/tink-providers";
import { CurrentPageInfoModel } from "state/current-page-info";
import { CurrentInfoItemModel } from "state/current-info-item";
import { CurrentInfoListModel } from "state/current-info-list";
import { PhotoServiceModel } from "state/photo-service";
import { PhotoServiceListModel } from "state/photo-service-list";
import { AllFormsModel, FormRowsModel, FormModel, FormSubmitsModel } from "state/forms";
import { CmsModel } from "state/cms";
import { RegisterFormConfigModel, AccountFormConfigModel } from "state/account-forms";
import { MemberGroupIsOwnerModel } from "state/member-group";

export const RouteData = createStateData<typeof RouteModel>(RouteModel);
export const QuoteData = createStateData<typeof QuoteModel>(QuoteModel);
export const CustomerData = createStateData<typeof CustomerModel>(CustomerModel);
export const HomeData = createStateData<typeof HomeModel>(HomeModel);
export const RedeemData = createStateData<typeof RedeemModel>(RedeemModel);
export const BrandData = createStateData<typeof BrandModel>(BrandModel);
export const SearchData = createStateData<typeof SearchModel>(SearchModel);
export const OrderData = createStateData<typeof OrderModel>(OrderModel);
export const CheckoutStepData = createStateData<typeof CheckoutStepModel>(CheckoutStepModel);
export const MessagesData = createStateData<typeof MessagesModel>(MessagesModel);
export const NewsPostsData = createStateData<typeof NewsPostsModel>(NewsPostsModel);
export const TransactionsData = createStateData<typeof TransactionsModel>(TransactionsModel);
export const LeaderboardData = createStateData<typeof LeaderboardModel>(LeaderboardModel);
export const LeaderboardDistrictsData = createStateData<typeof LeaderboardDistrictsModel>(
  LeaderboardDistrictsModel
);
export const ReportFormData = createStateData<typeof ReportFormModel>(ReportFormModel);
export const ReportListData = createStateData<typeof ReportListModel>(ReportListModel);
export const ReportHistoryData = createStateData<typeof ReportHistoryModel>(ReportHistoryModel);
export const VerifyListData = createStateData<typeof VerifyListModel>(VerifyListModel);
export const ViewModeData = createStateData<typeof ViewModeModel>(ViewModeModel);
export const DistrictInfoData = createStateData<typeof DistrictInfoModel>(DistrictInfoModel);
export const DistrictListData = createStateData<typeof DistrictListModel>(DistrictListModel);
export const AllProductsData = createStateData<typeof AllProductsModel>(AllProductsModel);
export const OffersData = createStateData<typeof OffersModel>(OffersModel);
export const CmsData = createStateData<typeof CmsModel>(CmsModel);

export const RegisterFormConfigData = createStateData<typeof RegisterFormConfigModel>(
  RegisterFormConfigModel
);
export const AccountFormConfigData = createStateData<typeof AccountFormConfigModel>(
  AccountFormConfigModel
);

export const TinkProvidersData = createStateData<typeof TinkProvidersModel>(TinkProvidersModel);
export const TinkTransactionsData = createStateData<typeof TinkTransactionsModel>(
  TinkTransactionsModel
);

export const AffiliateListData = createStateData<typeof AffiliateListModel>(
  AffiliateListModel
);
export const AffiliateListSelectedData = createStateData<typeof AffiliateListSelectedModel>(
  AffiliateListSelectedModel
);
export const AffiliateCategoriesData = createStateData<typeof AffiliateCategoriesModel>(
  AffiliateCategoriesModel
);
export const AffiliateData = createStateData<typeof AffiliateModel>(
  AffiliateModel
);
export const ConnectedAccountsData = createStateData<typeof ConnectedAccountsModel>(
  ConnectedAccountsModel
);
export const QuestionnaireListData = createStateData<typeof QuestionnaireListModel>(
  QuestionnaireListModel
);
export const QuizListData = createStateData<typeof QuizListModel>(
  QuizListModel
);
export const QuizData = createStateData<typeof QuizModel>(
  QuizModel
);
export const QuizHistoryData = createStateData<typeof QuizHistoryModel>(
  QuizHistoryModel
);
export const QuizHistoryRowsData = createStateData<typeof QuizHistoryRowsModel>(
  QuizHistoryRowsModel
);
export const QuizHistoryListData = createStateData<typeof QuizHistoryListModel>(
  QuizHistoryListModel
);
export const AllFormsData = createStateData<typeof AllFormsModel>(
  AllFormsModel
);
export const FormData = createStateData<typeof FormModel>(
  FormModel
);
export const FormRowsData = createStateData<typeof FormRowsModel>(
  FormRowsModel
);
export const FormSubmitsData = createStateData<typeof FormSubmitsModel>(
  FormSubmitsModel
);
export const TeampotMembersData = createStateData<typeof TeampotMembersModel>(
  TeampotMembersModel
);
export const TeampotHistoryData = createStateData<typeof TeampotHistoryModel>(
  TeampotHistoryModel
);
export const TeampotSettingsData = createStateData<typeof TeampotSettingsModel>(
  TeampotSettingsModel
);
export const TeampotStatusData = createStateData<typeof TeampotStatusModel>(
  TeampotStatusModel
);
export const TeampotActivitiesData = createStateData<typeof TeampotActivitiesModel>(
  TeampotActivitiesModel
);
export const TeampotHistoryItemsData = createStateData<typeof TeampotHistoryItemsModel>(
  TeampotHistoryItemsModel
);
export const QuestionnaireData = createStateData<typeof QuestionnaireModel>(
  QuestionnaireModel
);
export const CurrentPageInfoData = createStateData<typeof CurrentPageInfoModel>(
  CurrentPageInfoModel
);
export const CurrentInfoListData = createStateData<typeof CurrentInfoListModel>(
  CurrentInfoListModel
);
export const PhotoServiceData = createStateData<typeof PhotoServiceModel>(
  PhotoServiceModel
);
export const PhotoServiceListData = createStateData<typeof PhotoServiceListModel>(
  PhotoServiceListModel
);
export const CurrentInfoItemData = createStateData<typeof CurrentInfoItemModel>(
  CurrentInfoItemModel
);
export const MemberGroupIsOwnerData = createStateData<typeof MemberGroupIsOwnerModel>(
  MemberGroupIsOwnerModel
);
