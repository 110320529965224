/* @flow */

import type { AffiliateSingle } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";

import { updateData, updateNone } from "crustate";

type Data =
  | {| state: "LOADING" |}
  | {| state: "LOADED", data: AffiliateSingle |}
  | {| state: "ACTIVATING", data: AffiliateSingle |}
  | {| state: "ERROR", error: string, data: ?AffiliateSingle |};

export type AffiliateRequest = {
  tag: typeof AFFILIATE_REQUEST,
  id: number,
};
export type AffiliateEffectRequest = {
  tag: typeof AFFILIATE_EFFECT_REQUEST,
  id: number,
};

export type AffiliateEffectResponse = Response<typeof AFFILIATE_EFFECT_RESPONSE, ?AffiliateSingle>;

export type AffiliateActivateRequest = {
  tag: typeof AFFILIATE_ACTIVATE_REQUEST,
  id: number,
};
export type AffiliateActivateEffectRequest = {
  tag: typeof AFFILIATE_ACTIVATE_EFFECT_REQUEST,
  id: number,
};

export type AffiliateActivateEffectResponse = Response<
  typeof AFFILIATE_ACTIVATE_EFFECT_RESPONSE,
  ?AffiliateSingle
>;

export const AFFILIATE_REQUEST: "request/affiliate" = "request/affiliate";

export const AFFILIATE_EFFECT_RESPONSE: "response/affiliate_effect" = "response/affiliate_effect";
export const AFFILIATE_EFFECT_REQUEST: "request/affiliate_effect" = "request/affiliate_effect";

export const AFFILIATE_ACTIVATE_REQUEST: "request/affiliate_activate" = "request/affiliate_activate";

export const AFFILIATE_ACTIVATE_EFFECT_RESPONSE: "response/affiliate_activate_effect" = "response/affiliate_activate_effect";
export const AFFILIATE_ACTIVATE_EFFECT_REQUEST: "request/affiliate_activate_effect" = "request/affiliate_activate_effect";

export const activateAffiliate = (id: number): AffiliateActivateRequest => ({
  tag: AFFILIATE_ACTIVATE_REQUEST,
  id,
});

export const loadAffiliate = (id: number): AffiliateRequest => ({
  tag: AFFILIATE_REQUEST,
  id,
});

export const AffiliateModel: Model<
  Data,
  { id: number },
  AffiliateRequest |
  AffiliateActivateRequest |
  AffiliateEffectResponse |
  AffiliateActivateEffectResponse
> = {
  id: "affiliate",
  init: ({ id }) =>
    updateData({ state: "LOADING" }, { tag: AFFILIATE_EFFECT_REQUEST, id }),
  update: (state, msg) => {
    switch (msg.tag) {
      case AFFILIATE_REQUEST:
        return updateData({ state: "LOADING" }, { tag: AFFILIATE_EFFECT_REQUEST, id: msg.id });

      case AFFILIATE_ACTIVATE_REQUEST:
        if (state.data) {
          return updateData({ state: "ACTIVATING", data: state.data }, { tag: AFFILIATE_ACTIVATE_EFFECT_REQUEST, id: msg.id });
        }

        return updateNone();

      case AFFILIATE_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error, data: null });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        return updateData({ state: "ERROR", error: "Affiliate not found", data: null });

      case AFFILIATE_ACTIVATE_EFFECT_RESPONSE:
        if (state.state === "ACTIVATING") {
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error, data: state.data });
          }

          return updateData({ state: "LOADED", data: msg.data ? msg.data : state.data });
        }

        return updateNone();
      default:
    }
  },
};
