/* @flow */

import type { Address } from "shop-state/types";

import React, { useEffect, useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { AnalyticsContext } from "@crossroads/analytics";
import { usePriceToPoints, getEstimatedDeliveryText } from "helpers/utils";
import Wrapper from "components/Wrapper";
import CustomerServiceLink from "components/CustomerServiceLink";
import { OrderData } from "data";
import { useData, useSendMessage } from "crustate/react";
import { Box } from "components/Box";
import PriceSplit from "components/PriceSplit";
import {
  loadTransactions,
  PREVIEW_PAGESIZE as TRANSACTIONS_PREVIEW_PAGESIZE } from "state/transactions";
import usePrevious from "helpers/use-previous";
import CheckboxSuccessIcon from "icons/checkbox-success.svg";
import { pointsPriceByID } from "helpers/points";

import styles from "./styles.scss";

type Props = {
  history: {
    push: (path: string) => void,
  },
};

const mapText = (a: Address) => `
  ${a.firstname} ${a.lastname},
  ${a.street[0]}, ${a.postcode},
  ${a.city}
  `;

const SuccessView = ({ history }: Props) => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const toPoints = usePriceToPoints();
  const sendMessage = useSendMessage();
  const orderData = useData(OrderData);
  const prevOrderState = usePrevious(orderData.state);

  useEffect(() => {
    if (orderData.state === "LOADED" && orderData.state !== prevOrderState) {
      gaContext.registerCheckoutSuccess(
        orderData.data,
        toPoints(orderData.data.grandTotal.incVat || 0)
      );

      // Reload transactions, to also include the latest order in history
      sendMessage(loadTransactions({ size: TRANSACTIONS_PREVIEW_PAGESIZE }));
    }
  }, [orderData, gaContext]);

  if (orderData.state === "LOADING") {
    return null;
  }

  if (orderData.state !== "LOADED") {
    history.push("/");

    return null;
  }

  const order = orderData.data;
  const { id, items, addresses, email } = order;

  const shippingAddress = addresses.find(x => x.type === "shipping");

  const isVirtual = order.items.every(x => x.product.type === "virtual");

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <header className={styles.header}>
        <CheckboxSuccessIcon className={styles.checkIcon} />
        <div className={styles.heading}>
          <h1 className={styles.title}>
            {t("SUCCESS.TITLE")}
          </h1>
          <h2 className={styles.orderNo}>
            {t("SUCCESS.SUB_TITLE", { number: id })}
          </h2>
        </div>
      </header>

      <div className={styles.block}>
        <div className={styles.right}>
          {shippingAddress && !isVirtual &&
            <section className={cn(styles.mapContainer, styles.mapContainer__large)}>
              <p className={styles.mapText}>
                {mapText(shippingAddress)}
              </p>
            </section>
          }

          <div className={styles.items}>
            {items.map(x => (
              <Box
                key={x.product.sku}
                className={styles.item}
              >
                <img
                  className={styles.image}
                  alt={x.product.name}
                  src={x.product.attributes.image?.x1}
                />
                <div className={styles.contentWrapper}>
                  <div className={styles.left}>
                    <div className={styles.info}>
                      <p className={styles.name}>
                        {x.product.name}
                      </p>
                      <p className={styles.qty}>{t("SUCCESS.QTY", { qty: x.qty })}</p>
                      <p className={styles.brand}>{x.product.attributes.manufacturer}</p>
                      <span className={styles.price}>
                        <PriceSplit
                          className={styles.price}
                          pointsPrice={pointsPriceByID(x.product.pointsPrices, "awardit")}
                          price={x.product.price}
                        />
                      </span>
                    </div>
                    <img
                      className={cn(styles.image, styles.mobileImg)}
                      alt={x.product.name}
                      src={x.product.attributes.image?.x1}
                    />
                  </div>

                  <div className={styles.right}>
                    <span className={styles.deliveryTime}>
                      {getEstimatedDeliveryText(x.product)}
                    </span>
                  </div>
                </div>
              </Box>
            ))}
          </div>
        </div>

        <div className={styles.left}>
          <ol className={styles.steps}>
            <li>
              <h2>{t(`SUCCESS.STEPS.CONFIRMATION.1.HEADING`)}</h2>
              <p>{t(`SUCCESS.STEPS.CONFIRMATION.1.TEXT`, { email })}</p>
            </li>

            <li>
              <h2>{t(`SUCCESS.STEPS.CONFIRMATION.2.HEADING`)}</h2>
              {/* eslint-disable react/no-danger */}
              <p
                dangerouslySetInnerHTML={{ __html: t(`SUCCESS.STEPS.CONFIRMATION.2.TEXT`) }}
              />
              {/* eslint-enable react/no-danger */}
            </li>
          </ol>

          <div className={styles.customerService}>
            <CustomerServiceLink />
          </div>

        </div>
      </div>
    </Wrapper>
  );
};

export default SuccessView;
