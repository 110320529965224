/* @flow */

import type { AffiliateSingle } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { useLocation } from "react-router";
import { StoreInfoContext } from "entrypoint/shared";
import { decodeHTML } from "entities";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetAffiliateMeta } from "helpers/get-meta";
import NotFoundView from "components/NotFoundView";
import { activateAffiliate } from "state/affiliate";
import { AffiliateData } from "data";
import { useSendMessage, useData } from "crustate/react";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import HeroIntro from "components/HeroIntro";
import LoadingView from "components/LoadingView";

import styles from "./styles.scss";

type Props = {
  affiliate: AffiliateSingle,
};
type HintProps = {
  affiliate: {
    id: number,
    name: string,
    title: ?string,
    image: ?string,
  },
};

const STATUS = {
  ACTIVATED: "ACTIVATED",
  INACTIVE: "INACTIVE",
  PROCESSING: "PROCESSING",
};

const AffiliateView = ({ affiliate }: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const meta = useGetAffiliateMeta(affiliate);
  const activationStatus = affiliate.type === "AffiliateActivatable" ? affiliate.activationStatus : null;
  const { content: { heroIntroView } } = useContext(StoreInfoContext);
  const isActivatable = activationStatus === STATUS.INACTIVE;

  const { name, title, image, description, commissions, terms } = affiliate || {};

  return (
    <>
      {Boolean(heroIntroView.toggle) &&
      <HeroIntro
        title={name}
      />
      }
      <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.block)}>
        {meta &&
        <Helmet
          title={decodeHTML(`${name}${title ? ` - ${title}` : ""}`) || null}
          meta={meta.data || null}
          link={meta.link || null}
        />
        }

        {image && <img className={cn("awardit-affiliateLogo", styles.logo, styles.logoMobile)} src={image} alt={name} />}
        {Boolean(heroIntroView.toggle) === false &&
        <h1>{name}</h1>
        }
        <div className={styles.split}>
          <div className={styles.left}>
            {/* eslint-disable react/no-danger */}
            {description && (
              <div
                className={cn("typography", styles.text)}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            )}
            {commissions.length > 0 &&
            <ul className={cn("typography", styles.commissions)}>
              {commissions.map((x, i) => (<li key={i} dangerouslySetInnerHTML={{ __html: x }} />))}
            </ul>
            }

            {/* eslint-enable react/no-danger */}
          </div>
          <div className={cn("awardit-affiliateLogoField", styles.right)}>
            {image &&
            <img
              className={cn("awardit-affiliateLogo", styles.logo, styles.logoDesktop)}
              src={image}
              alt={name}
            />
            }
          </div>
        </div>
        <div className={styles.links}>
          {activationStatus && (
            <Button
              disabled={!isActivatable}
              className={styles.activateButton}
              variant="primary"
              onClick={() => {
                sendMessage(activateAffiliate(affiliate.id));
              }}
            >
              {t(`AFFILIATE.CTA.${activationStatus}`)}
            </Button>
          )}

          {affiliate.links
            .map((link, idx) => link &&
            <a
              key={link.url}
              className={styles.link}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant={(activationStatus || idx) ? "ghost" : "primary"}
                className={styles.buttonLink}
              >
                {/* eslint-disable react/no-danger */}
                <div
                  className={cn("typography", styles.text)}
                  dangerouslySetInnerHTML={{ __html: link.linkText }}
                />
                {/* eslint-enable react/no-danger */}
              </Button>
            </a>
            )}

        </div>
        {/* eslint-disable react/no-danger */}
        {terms &&
        <p className={cn("typography", styles.terms)} dangerouslySetInnerHTML={{ __html: terms }} />
        }
        {/* eslint-enable react/no-danger */}
      </Wrapper>
    </>
  );
};

const AffiliateHint = ({ affiliate }: HintProps) => {
  const { name, title, image } = affiliate;
  const { content: { heroIntroView } } = useContext(StoreInfoContext);
  return (
    <>
      {Boolean(heroIntroView.toggle) &&
      <HeroIntro
        title={name}
      />
      }
      <Wrapper key="affiliate_hint" variant="pageWrapper" className={cn("awardit-pageWrapper", styles.block)}>
        {title &&
        <Helmet
          title={decodeHTML(`${name}${title ? ` - ${title}` : ""}`) || null}
        />
        }

        {image && <img className={cn(styles.logo, styles.logoMobile)} src={image} alt={name} />}
        {Boolean(heroIntroView.toggle) === false &&
        <h1>{name}</h1>
        }
        <div className={cn(styles.hint, styles.split)}>
          <div className={styles.left}>
            <div className={cn(styles.text, styles.textHint)} />
            <div className={cn(styles.commissionsHint, styles.commissions)} />
            <div className={styles.termsHint} />
          </div>
          <div className={styles.right}>
            {image &&
              <img className={cn(styles.logo, styles.logoDesktop)} src={image} alt={name} />}
          </div>
        </div>
      </Wrapper>
    </>
  );
};

const AffiliateViewGuard = () => {
  const affiliateData = useData(AffiliateData);
  const { state } = useLocation();

  if (affiliateData.state === "LOADING" && state?.hint) {
    return <AffiliateHint affiliate={state.hint} />;
  }

  if (affiliateData.state === "LOADING") {
    return <LoadingView />;
  }

  if (!affiliateData.data) {
    return <NotFoundView />;
  }

  return <AffiliateView affiliate={affiliateData.data} />;
};

export default AffiliateViewGuard;
