/* @flow */

import type { GetReportFormData, ReportListData, GetReportHistoryData } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "UPDATING", data: GetReportFormData }
  | { state: "LOADED", data: GetReportFormData }
  | { state: "ERROR", error: string };

  type ListData =
  | { state: "LOADING" }
  | { state: "UPDATING", data: ReportListData }
  | { state: "LOADED", data: ReportListData }
  | { state: "ERROR", error: string };

type HistoryData =
  | { state: "LOADING" }
  | { state: "INIT", data: GetReportHistoryData }
  | { state: "UPDATING", data: GetReportHistoryData }
  | { state: "LOADED", data: GetReportHistoryData }
  | { state: "ERROR", error: string };

// Report Form
// Component -> State message-types
export type ReportFormStateRequest = {
  tag: typeof REPORTFORM_STATE_REQUEST,
  district?: string | null,
};

export type getReportsformProps = {
  district?: string | null,
};

// State <-> Effect message-types
export type ReportFormResponse =
  Response<typeof REPORTFORM_RESPONSE, ?GetReportFormData>;

export type ReportFormEffectRequest = {
  tag: typeof REPORTFORM_REQUEST,
  district?: string,
};

// Report History
// Component -> State message-types
export type ReportHistoryStateRequest = {
  tag: typeof REPORTHISTORY_STATE_REQUEST,
  id?: string | null,
};

export type ReportHistoryStateProps = {
  id?: string | null,
};
export type ReportHistoryEffectRequest = {
  tag: typeof REPORTHISTORY_REQUEST,
  id?: string,
  type: string,
};
export type ReportHistoryResponse =
  Response<typeof REPORTHISTORY_RESPONSE, ?GetReportHistoryData>;

// Report List
// Component -> State message-types
export type ReportListStateRequest = {
  tag: typeof REPORT_LIST_STATE_REQUEST,
};

// State <-> Effect message-types
export type ReportListResponse =
  Response<typeof REPORT_LIST_RESPONSE, ?ReportListData>;

export type ReportListEffectRequest = {
  tag: typeof REPORT_LIST_REQUEST,
};

// Component -> State messages
const REPORTFORM_STATE_REQUEST: "reportform/state/request" = "reportform/state/request";
// Report list:Component -> State messages
const REPORT_LIST_STATE_REQUEST: "reportlist/state/request" = "reportlist/state/request";
// Report history:Component -> State messages
const REPORTHISTORY_STATE_REQUEST: "reporthistory/state/request" = "reporthistory/state/request";

// State <-> Effect messages
export const REPORTFORM_RESPONSE: "reportform/effect/response" = "reportform/effect/response";
export const REPORTFORM_REQUEST: "reportform/effect/request" = "reportform/effect/request";
// Report list: State <-> Effect messages
export const REPORT_LIST_RESPONSE: "reportlist/effect/response" = "reportlist/effect/response";
export const REPORT_LIST_REQUEST: "reportlist/effect/request" = "reportlist/effect/request";
// Report history: State <-> Effect messages
export const REPORTHISTORY_RESPONSE: "reporthistory/effect/response" = "reporthistory/effect/response";
export const REPORTHISTORY_REQUEST: "reporthistory/effect/request" = "reporthistory/effect/request";

export const getReportsform = ({ district }: getReportsformProps): ReportFormStateRequest => {
  return {
    tag: REPORTFORM_STATE_REQUEST,
    district,
  };
};

export const loadReportsHistory = ({ id }: ReportHistoryStateProps): ReportHistoryStateRequest => {
  return {
    tag: REPORTHISTORY_STATE_REQUEST,
    id,
  };
};

export const ReportFormModel: Model<Data, {district?: string},
  ReportFormEffectRequest |
  ReportFormResponse |
  ReportFormStateRequest> = {
    id: "reportform",
    init: ({ district = null }) => {
      return updateData(
        { state: "LOADING" },
        { tag: REPORTFORM_REQUEST, district }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case REPORTFORM_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;

        case REPORTFORM_STATE_REQUEST:
          if (state.state === "LOADED") {
            return updateData(
              { state: "UPDATING", data: state.data },
              { ...msg, tag: REPORTFORM_REQUEST }
            );
          }

          if (state.state === "ERROR") {
            return updateData(
              { state: "LOADING" },
              { ...msg, tag: REPORTFORM_REQUEST }
            );
          }

          break;
        default:
      }
    },
  };

export const ReportListModel: Model<ListData, {},
ReportListEffectRequest |
  ReportListResponse |
  ReportListStateRequest> = {
    id: "reportlist",
    init: () => {
      return updateData(
        { state: "LOADING" },
        { tag: REPORT_LIST_REQUEST }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case REPORT_LIST_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;
        default:
      }
    },
  };

export const ReportHistoryModel: Model<HistoryData, {id?: string},
  ReportHistoryEffectRequest |
  ReportHistoryResponse |
  ReportHistoryStateRequest> = {
    id: "reporthistory",
    init: ({ id = null }) => {
      return updateData(
        { state: "INIT", data: {} },
        { tag: REPORTHISTORY_REQUEST, id }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case REPORTHISTORY_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;

        case REPORTHISTORY_STATE_REQUEST:
          if (state.state === "LOADED" || state.state === "INIT") {
            return updateData(
              { state: "UPDATING", data: state.data },
              { ...msg, tag: REPORTHISTORY_REQUEST }
            );
          }

          break;
        default:
      }
    },
  };
