
/* @flow */

import fetch from "node-fetch";

const endpoint = {
  toplist: "/infobaleen/api/v1/api-profiles/175/recommendations/user-to-items",
  pointBalance: "/infobaleen/api/v1/api-profiles/176/recommendations/user-to-items",
  category: "/infobaleen/api/v1/api-profiles/177/recommendations/user-to-items",
  relatedProducts: "/infobaleen/api/v1/api-profiles/194/recommendations/items-to-items",
};

export const useGetRecProductsSkus = async (
  customerId: string,
  partnerKey: string,
  limit: number
) => {
  // No good way to fetch name prop for StringValue.
  const context = [{ Name: "partner_input", StringValue: partnerKey }];

  const body = {
    "Limit": limit,
    "User": customerId,
    "Context": context,
  };

  try {
    if (!partnerKey || partnerKey === "") {
      return null;
    }

    const response = await fetch(endpoint.toplist, {
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Fetch failed (status: ${response.status})`);
    }

    const result = await response.json();
    return result;
  }
  catch (e) {
    throw e;
  }
};

export const useGetRecProductsCategory = async (
  partnerKey: string,
  limit: number,
  category: string,
  productSku?: string
) => {
  // No good way to fetch name prop for StringValue.
  const context = [{ Name: "partner_input", StringValue: partnerKey }, { Name: "category_input", StringValue: category }];

  const body = {
    "Limit": limit,
    "Context": context,
    "BlockedItems": [],
  };

  if (productSku) {
    body.BlockedItems = [productSku];
  }

  try {
    if (!partnerKey || partnerKey === "") {
      return null;
    }

    const response = await fetch(endpoint.category, {
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Fetch failed (status: ${response.status})`);
    }

    const result = await response.json();
    return result;
  }
  catch (e) {
    throw e;
  }
};

export const useGetRelatedProducts = async (
  sku: string,
  partnerKey: string,
  limit: number
) => {
  // No good way to fetch name prop for StringValue.
  const context = [{ Name: "partner_input", StringValue: partnerKey }, { Name: "item_categories", "Field": "category_level_1", "Option": "items" }];

  const body = {
    "Limit": limit,
    "Items": [sku],
    "Context": context,
  };

  try {
    if (!partnerKey || partnerKey === "") {
      return null;
    }

    const response = await fetch(endpoint.relatedProducts, {
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Fetch failed (status: ${response.status})`);
    }

    const result = await response.json();
    return result;
  }
  catch (e) {
    throw e;
  }
};

export const useGetRecProductByBalance = async (
  customerId: string,
  partnerKey: string,
  limit: number,
  pointLimit: string = "0"
) => {
  // No good way to fetch name prop for StringValue.
  const context = [{ Name: "points_input", StringValue: pointLimit }, { Name: "partner_input", StringValue: partnerKey }];

  const body = {
    "Limit": limit,
    "User": customerId,
    "Context": context,
  };

  try {
    if (!partnerKey || partnerKey === "") {
      return null;
    }

    const response = await fetch(endpoint.pointBalance, {
      method: "POST",
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`Fetch failed (status: ${response.status})`);
    }

    const result = await response.json();
    return result;
  }
  catch (e) {
    throw e;
  }
};
