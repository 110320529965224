
/* @flow */

import useFormat from "helpers/use-format";

const Points = ({ points }: { points: number }) => {
  const { formatPoints } = useFormat(false);

  return formatPoints(Number(points));
};

export default Points;
