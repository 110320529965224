/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { RedeemResponse } from "state/redeem";

import { REDEEM_REQUEST, REDEEM_RESPONSE } from "state/redeem";
import { redeem } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(redeem, undefined, { cache: true });

      return ({
        tag: REDEEM_RESPONSE,
        data,
      }: RedeemResponse);
    },
    subscribe: { [REDEEM_REQUEST]: true },
  });
};

export default registerClient;
