/* @flow */

import type { TinkTransactions } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import { Foldable } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron-mini.svg";
import Points from "components/AccountView/TransactionsView/Points";

import styles from "components/AccountView/TinkView/TableLarge/styles.scss";

type TableSmallProps = {
  items: TinkTransactions,
  onClick: any => void,
  current: number | null,
};

const TableSmallHead = () => {
  const { content: { tinkview } } = useContext(StoreInfoContext);

  return (
    <div className={styles.thead}>
      {tinkview.tableDescription &&
        <div className={styles.th}>{tinkview.tableDescription}</div>
      }
      {tinkview.tableTotal &&
        <div className={styles.th}>{tinkview.tableTotal}</div>
      }
    </div>
  );
};

const TableSmall = ({ items, onClick, current }: TableSmallProps) => {
  const { content: { tinkview } } = useContext(StoreInfoContext);

  return (
    <div className={cn(styles.table, styles.table__small)}>
      <TableSmallHead />
      <div className={styles.tbody}>
        {items.map((x, i) => (
          <React.Fragment key={i}>
            <div
              className={cn(styles.tr, { [styles.open]: current === i })}
              onClick={() => {
                onClick(i);
              }}
            >
              <div>{x.description}</div>
              <div className={cn({ [styles.negative]: x.amount < 0 })}>{x.amount}</div>
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === i} className={styles.expandable}>
              <table>
                <tbody>
                  <tr>
                    <td>{tinkview.tableDate ?? ""}</td>
                    <td>{x.date}</td>
                  </tr>
                  <tr>
                    <td>{tinkview.tableDescription ?? ""}</td>
                    <td>{x.description}</td>
                  </tr>
                  <tr>
                    <td>{tinkview.tableTotal ?? ""}</td>
                    <td className={cn({ [styles.negative]: x.amount < 0 })}>
                      <Points points={x.amount} />
                    </td>
                  </tr>
                  <tr>
                    <td>{tinkview.tableCurrency ?? ""}</td>
                    <td>{x.currency}</td>
                  </tr>
                  <tr>
                    <td>{tinkview.tableType ?? ""}</td>
                    <td>{x.type}</td>
                  </tr>
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const TableSmallHint = ({ numItems }: { numItems: number }) => (
  <div className={cn(styles.table, styles.table__small, styles.dummy)}>
    <TableSmallHead />
    <div className={styles.tbody}>
      {[...new Array(numItems)].map((x, i) => (
        <React.Fragment key={i}>
          <div className={styles.tr}>
            <div /><div />
          </div>
        </React.Fragment>
      ))}
    </div>
  </div>
);

export default TableSmall;
