/* @flow */

import React, { useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { RedeemData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { Section, Title } from "components/UiComponents";
import Wrapper from "components/Wrapper";
import CurrentPageInfo from "components/CurrentPageInfo";
import ProductCard from "components/ProductCard";
import LoadingView from "components/LoadingView";

import styles from "./styles.scss";

const RedeemView = () => {
  const data = useData(RedeemData);
  const { content: { redeemview } } = useContext(StoreInfoContext);
  if (data.state !== "LOADED") {
    return (
      <div className={styles.block}>
        <LoadingView />
      </div>
    );
  }

  return (
    <div className={styles.block}>
      <Helmet
        title={redeemview.pageTitle ?? ""}
      />
      <CurrentPageInfo />
      <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
        <Section className={styles.section}>
          {redeemview.popularProductsHeading &&
            <Title>
              {redeemview.popularProductsHeading}
            </Title>
          }

          <div className={styles.items}>
            {data.data.featuredProducts && data.data.featuredProducts.category.products.items
              .slice(0, 3)
              .map(p => (
                <div key={p.name} className={styles.item}>
                  <ProductCard product={p} />
                </div>
              ))}
          </div>
          {redeemview.popularProductsButtonLink && redeemview.popularProductsButtonText &&
            <div className={styles.popularProducts}>
              <Link
                to={redeemview.popularProductsButtonLink}
                className={cn("link", styles.cta)}
              >
                {redeemview.popularProductsButtonText}
              </Link>
            </div>
          }
        </Section>
      </Wrapper>
    </div>
  );
};

export default RedeemView;
