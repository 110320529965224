/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import type {
  AffiliateEffectRequest,
  AffiliateEffectResponse,
  AffiliateActivateEffectRequest,
  AffiliateActivateEffectResponse,
} from "state/affiliate";

import {
  AFFILIATE_EFFECT_REQUEST,
  AFFILIATE_EFFECT_RESPONSE,
  AFFILIATE_ACTIVATE_EFFECT_REQUEST,
  AFFILIATE_ACTIVATE_EFFECT_RESPONSE,
} from "state/affiliate";

import { addMessage } from "state/messages";

import { affiliate as affiliateQuery, activateAffiliate as activeAffiliateMutation } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: AffiliateEffectRequest) => {
      const { affiliate } = await client(affiliateQuery, { id: msg.id });

      if (affiliate.result === "SUCCESS") {
        return ({
          tag: AFFILIATE_EFFECT_RESPONSE,
          data: affiliate.data,
        }: AffiliateEffectResponse);
      }

      return ({
        tag: AFFILIATE_EFFECT_RESPONSE,
        error: "AFFILIATE_LOAD_ERROR",
      }: AffiliateEffectResponse);
    },
    subscribe: { [AFFILIATE_EFFECT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: AffiliateActivateEffectRequest) => {
      const { activateAffiliate } = await client(activeAffiliateMutation, { id: String(msg.id) });

      if (activateAffiliate.result === "errorNotLoggedIn") {
        storage.broadcastMessage(addMessage("ERROR_NOT_LOGGED_IN", "error"));

        return ({
          tag: AFFILIATE_ACTIVATE_EFFECT_RESPONSE,
          error: "User not logged in",
        }: AffiliateActivateEffectResponse);
      }

      if (activateAffiliate.result === "notActivated") {
        storage.broadcastMessage(addMessage("AFFILIATE.NOT_ACTIVATED", "error"));

        return ({
          tag: AFFILIATE_ACTIVATE_EFFECT_RESPONSE,
          data: null,
        }: AffiliateActivateEffectResponse);
      }

      if (activateAffiliate.result === "success") {
        const { affiliate } = await client(affiliateQuery, { id: msg.id });
        storage.broadcastMessage(addMessage("AFFILIATE.ACTIVATED", "success"));

        if (affiliate.result === "SUCCESS") {
          return ({
            tag: AFFILIATE_ACTIVATE_EFFECT_RESPONSE,
            data: affiliate.data,
          }: AffiliateActivateEffectResponse);
        }

        storage.broadcastMessage(addMessage("AFFILIATE.ERROR_RELOADING", "error"));

        return ({
          tag: AFFILIATE_ACTIVATE_EFFECT_RESPONSE,
          error: "AFFILIATE_LOAD_ERROR",
        }: AffiliateActivateEffectResponse);
      }
    },
    subscribe: { [AFFILIATE_ACTIVATE_EFFECT_REQUEST]: true },
  });
};

export default registerClient;
