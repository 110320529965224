/* @flow */

import React, { useContext } from "react";
import Wrapper from "components/Wrapper";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import { usePointsLabel } from "helpers/points";

import styles from "./styles.scss";

type Props = {
  criteria?: boolean,
  header: string,
  subHeader?: string,
  children: React$Node,
};

const Box = ({ criteria, header, subHeader, children }: Props) => {
  return (
    <Wrapper className={styles.box}>
      <div className={cn(styles.header, criteria ?? styles.criteria)}>
        <h4>{header}</h4>
      </div>
      <div className={styles.body}>
        {subHeader ?
          <h4 className={styles.subHeader}>{subHeader}</h4> :
          null
        }
        {children}
      </div>
    </Wrapper>
  );
};

const LeadsInfo = () => {
  const { info } = useContext(StoreInfoContext);
  const t = useTranslate();
  const label = usePointsLabel();

  const releaseLeadsInfo = (
    <>
      <Box criteria header={t("LEADS.CRITERIA.HEADER")}>
        <ul>
          <li>{t("LEADS.CRITERIA.LIST.FIRST")}</li>
        </ul>
      </Box>
      <Box header={t("LEADS.POINTS.HEADER", { pointsLabel: label(true) })} subHeader={t("LEADS.POINTS.SUBHEADER", { pointsLabel: label() })}>
        <ul>
          <li>{t("LEADS.POINTS.LIST.FIRST", { pointsLabel: label() })}</li>
          <li>{t("LEADS.POINTS.LIST.SECOND", { pointsLabel: label() })}</li>
          <li>{t("LEADS.POINTS.LIST.THIRD", { pointsLabel: label() })}</li>
        </ul>
      </Box>
      <Box header={t("LEADS.LEVEL_ONE.HEADER", { pointsLabel: label() })} subHeader={t("LEADS.LEVEL_ONE.SUBHEADER")}>
        <ul>
          <li>{t("LEADS.LEVEL_ONE.LIST.FIRST")}</li>
          <li>{t("LEADS.LEVEL_ONE.LIST.SECOND")}</li>
          <li>{t("LEADS.LEVEL_ONE.LIST.THIRD")}</li>
          <li>{t("LEADS.LEVEL_ONE.LIST.FOURTH")}</li>
          <li>{t("LEADS.LEVEL_ONE.LIST.FIFTH")}</li>
          <li>{t("LEADS.LEVEL_ONE.LIST.SIXTH")}</li>
        </ul>
        <div>
          <p>{t("LEADS.LEVEL_ONE.FOOTER", { pointsLabel: label(true) })}</p>
        </div>
      </Box>
      <Box header={t("LEADS.LEVEL_TWO.HEADER", { pointsLabel: label() })} subHeader={t("LEADS.LEVEL_TWO.SUBHEADER")}>
        <ul>
          <li>{t("LEADS.LEVEL_TWO.LIST.FIRST")}</li>
        </ul>
        <div>
          <p>{t("LEADS.LEVEL_TWO.FOOTER", { pointsLabel: label() })}</p>
        </div>
      </Box>
      <Box header={t("LEADS.LEVEL_THREE.HEADER", { pointsLabel: label() })} subHeader={t("LEADS.LEVEL_THREE.SUBHEADER")}>
        <ul>
          <li>{t("LEADS.LEVEL_THREE.LIST.FIRST")}</li>
        </ul>
        <div>
          <p>{t("LEADS.LEVEL_THREE.FOOTER", { pointsLabel: label() })}</p>
        </div>
      </Box>
    </>
  );

  const dreamteamLeadsInfo = (
    <>
      <Box header="Allmän information">
        <ul>
          <li>Här kan du ge tips till en potentiell ny medarbetare.</li>
          <li>Alla fält måste fyllas i för att skicka in ett tips.</li>
        </ul>
      </Box>
      <Box header="Poäng för tips">
        <ul>
          <li>Godkänt tips – 2 000 poäng</li>
          <li>Tips som leder till anställning – 25 000 poäng</li>
        </ul>
      </Box>
    </>
  );

  return (
    <Wrapper className={cn(styles.wrapper, styles.right)}>
      {info.code === "releasefinans_sek" ? releaseLeadsInfo : null}
      {info.code === "dreamteam_sek" ? dreamteamLeadsInfo : null}
    </Wrapper>
  );
};

export default LeadsInfo;
