/* @flow */

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { StoreInfoContext } from "entrypoint/shared";
import ChevronIcon from "icons/chevron-mini.svg";

import styles from "./styles.scss";

type Props = {
  text: string,
};

const Back = ({ text }: Props) => {
  const { routes } = useContext(StoreInfoContext);

  if (!routes.quizList) {
    return null;
  }

  return (
    <Link className={styles.back} to={routes.quizList.url}>
      <ChevronIcon />
      <span>{ text }</span>
    </Link>
  );
};

export default Back;
