/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import type {
  TransactionsEffectRequest,
  TransactionsEffectResponse,
} from "state/transactions";

import {
  TRANSACTIONS_EFFECT_REQUEST,
  TRANSACTIONS_EFFECT_RESPONSE } from "state/transactions";
import { transactions } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: TransactionsEffectRequest) => {
      try {
        const data = await client(transactions,
          {
            filter: msg.filter,
            size: msg.size,
            from: msg.from,
          },
          { cache: true });

        return ({
          tag: TRANSACTIONS_EFFECT_RESPONSE,
          data: data && data.transactions.data,
        }: TransactionsEffectResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: TRANSACTIONS_EFFECT_RESPONSE,
          error: "ERROR LOADING TRANSACTIONS",
        }: TransactionsEffectResponse);
      }
    },
    subscribe: { [TRANSACTIONS_EFFECT_REQUEST]: true },
  });
};

export default registerClient;
