/* @flow */

import type { GetVerifyListData } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "INIT", data: GetVerifyListData }
  | { state: "UPDATING", data: GetVerifyListData }
  | { state: "LOADED", data: GetVerifyListData }
  | { state: "ERROR", error: string };

// Component -> State message-types
export type VerifyListStateRequest = {
  tag: typeof VERIFYLIST_STATE_REQUEST,
  district?: string | null,
  pageLimit: number,
  pageNumber?: number | null,
};

type loadVerifyListProps = {
  district?: string | null,
  pageLimit: number,
  pageNumber?: number | null,
};

// State <-> Effect message-types
export type VerifyListResponse =
  Response<typeof VERIFYLIST_RESPONSE, ?GetVerifyListData>;

export type VerifyListEffectRequest = {
  tag: typeof VERIFYLIST_REQUEST,
  type: string,
  district?: string,
  pageLimit: number,
  pageNumber?: number,
};

// Component -> State messages
const VERIFYLIST_STATE_REQUEST: "verifylist/state/request" = "verifylist/state/request";

// State <-> Effect messages
export const VERIFYLIST_RESPONSE: "verifylist/effect/response" = "verifylist/effect/response";
export const VERIFYLIST_REQUEST: "verifylist/effect/request" = "verifylist/effect/request";

export const loadVerifyList = ({
  district,
  pageLimit,
  pageNumber,
}: loadVerifyListProps): VerifyListStateRequest => {
  return {
    tag: VERIFYLIST_STATE_REQUEST,
    district,
    pageLimit,
    pageNumber,
  };
};

export const VerifyListModel: Model<Data, {
  district?: string,
  pageNumber?: number,
  pageLimit?: number,
},
  VerifyListEffectRequest |
  VerifyListResponse |
  VerifyListStateRequest> = {
    id: "verifylist",
    init: ({ district, pageLimit, pageNumber }) => {
      return updateData(
        { state: "INIT", data: { verifyList: { title: null, header: null, table: null }, totalCount: 0 } },
        { tag: VERIFYLIST_REQUEST, district, pageLimit, pageNumber }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case VERIFYLIST_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;

        case VERIFYLIST_STATE_REQUEST:
          if (state.state === "LOADED" || state.state === "INIT") {
            return updateData(
              { state: "UPDATING", data: state.data },
              { ...msg, tag: VERIFYLIST_REQUEST }
            );
          }

          break;
        default:
      }
    },
  };
