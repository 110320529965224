/* @flow */

import { useState, useCallback, useEffect } from "react";
import { useBrowser } from "@awardit/react-use-browser";
import Cookies from "js-cookie";

const readCookie = <T>(key: string, initial: T, options?: Cookies.CookieAttributes): T => {
  try {
    const serialized = Cookies.get(key);

    return serialized ? JSON.parse(serialized) : initial;
  }
  catch (e) {
    Cookies.remove(key, options);

    return initial;
  }
};

const useCookie = <T>(
  key: string,
  initial: T,
  options?: Cookies.CookieAttributes
): { value: T, setValue: (value: T) => void, isRead: boolean} => {
  const browser = useBrowser();
  const [item, setInnerValue] = useState(initial);
  const [cookieRead, setCookieRead] = useState(false);

  useEffect(() => {
    if (browser) {
      setInnerValue(readCookie(key, initial, options));
      setCookieRead(true);
    }
  }, [browser, setInnerValue, initial, key, options]);

  const setValue = useCallback((value: T) => {
    setInnerValue(value);

    Cookies.set(key, JSON.stringify(value), options);
  }, [key, options, setInnerValue]);

  return { value: item, setValue, isRead: cookieRead };
};

export default useCookie;
