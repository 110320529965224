/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import Spinner from "components/Spinner";
import Account from "icons/account.svg";
import type { LeaderboardFlags, LeaderboardItem } from "shop-state/types";

import styles from "./styles.scss";

type TableLargeProps = {
  items: $ReadOnlyArray<LeaderboardItem>,
  hightlightedItemsLimit: number,
  customerId?: string | null,
  flags: LeaderboardFlags,
  missingDataMessage: React$Node | null,
};

const TableLargeHead = ({ flags }: { flags: LeaderboardFlags }) => {
  const { content: { leaderboardview } } = useContext(StoreInfoContext);
  return (
    <thead>
      <tr>
        {leaderboardview.placementTableHeader &&
          <th>{leaderboardview.placementTableHeader}</th>
        }
        {!flags && Boolean(leaderboardview.nameIsEnabled) && (
          <th>{leaderboardview.nameTableHeader ?? ""}</th>
        )}
        {!flags && Boolean(leaderboardview.companyIsEnabled) && (
          <th>{leaderboardview.companyTableHeader ?? ""}</th>
        )}
        {flags && flags.showname && (
          <th>{leaderboardview.nameTableHeader ?? ""}</th>
        )}
        {flags && flags.showavatar && (
          <th>{leaderboardview.avatarTableHeader ?? ""}</th>
        )}
        {flags && flags.showcompany && (
          <th>{leaderboardview.companyTableHeader ?? ""}</th>
        )}
        {leaderboardview.pointsTableHeader &&
          <th>{leaderboardview.pointsTableHeader}</th>
        }
      </tr>
    </thead>
  );
};

const TableLarge = ({
  items,
  hightlightedItemsLimit,
  customerId,
  flags,
  missingDataMessage,
}: TableLargeProps) => {
  const { content: { leaderboardview } } = useContext(StoreInfoContext);
  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead flags={flags} />
        <tbody>
          {items.map((x, i) => (
            <tr
              key={`${i}-${x.name}`}
              className={cn(
                { [styles.selectedCustomer]: x.name === customerId },
                { [styles.dimmedRow]: hightlightedItemsLimit > 0 &&
                  x.position > hightlightedItemsLimit },
                { [styles.firstDimmed]: hightlightedItemsLimit > 0 &&
                  x.position === hightlightedItemsLimit + 1 }
              )}
            >
              <td>{x.position}</td>
              {!flags && Boolean(leaderboardview.nameIsEnabled) && (
                <td>{x.name} {x.name === customerId ? <Account /> : null }</td>
              )}
              {!flags && Boolean(leaderboardview.companyIsEnabled) && (
                <td>{x.company}</td>
              )}
              {flags && flags.showname && (
                <td>{x.name} {x.name === customerId ? <Account /> : null }</td>
              )}
              {flags && flags.showavatar && (
                <td>
                  <img
                    className={styles.avatarImg}
                    src={x.avatar !== null ? x.avatar : "/avatars/avatar.jpg"}
                    alt="user-avatar" />
                </td>
              )}
              {flags && flags.showcompany && (
                <td>{x.company}</td>
              )}
              <td>{x.points}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {(missingDataMessage !== null) && (
        <div className={styles.missingData}>
          <p>{missingDataMessage}</p>
        </div>
      )}

      <div className={styles.spacer} />
    </div>
  );
};

export const TableLargeHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
