/* @flow */

import React from "react";
import cn from "classnames";

import { Form } from "@awardit/formaggio";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";

import Options from "../options";

import styles from "../styles.scss";

const SingleView = ({
  answers,
  submitAnswers,
  state,
  setState,
  quiz,
  setOptionsAnswer,
  loading }: any) => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <Wrapper className={styles.wrapper}>
        <Form
          className={cn("awardit-quizForm")}
          value={(state: any)}
          onSubmit={(e, value) => {
            submitAnswers(e, value);
          }}
          onChange={x => {
            setState({ ...state, ...(x: any) });
          }}
        >
          {quiz.length > 0 ?
            quiz.map(item => (
              <div key={item.nr} className={styles.quizContainer}>
                {/* eslint-disable react/no-danger */}
                <div
                  dangerouslySetInnerHTML={{ __html: item.name }}
                  className={styles.question} />
                {/* eslint-enable react/no-danger */}
                <div className={styles.answers}>
                  <Options
                    question={item}
                    answers={answers || []}
                    setAnswer={x => setOptionsAnswer(x, item.id, item.type)}
                  />
                </div>
              </div>
            )) :
            null}
          <Button
            loading={loading}
            type="submit"
            variant="primary"
          >
            {t(`QUIZ.SUBMIT`)}
          </Button>
        </Form>
      </Wrapper>
    </div>
  );
};

export default SingleView;
