/* @flow */

const localeToBCP47 = (locale: string) => {
  const parts = locale.split("_");
  return parts[0] + "-" + parts[1].toUpperCase();
};

export const formatTimestampToLocaleDate = (locale: string) => (date: string) => {
  const d = new Date(date);

  return d.toLocaleDateString(localeToBCP47(locale), { dateStyle: "medium" });
};

export const timestampToTimeDateString = (timestamp: number) => {
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  return new Date(timestamp).toLocaleDateString("sv-SE", dateOptions);
};

export const timestampToDateString = (timestamp: number) => {
  const dateOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };

  return new Date(timestamp).toLocaleDateString("sv-SE", dateOptions);
};
