/* @flow */

import type { Gender } from "shop-state/types";
import type { FormData } from "@awardit/formaggio/src/types";
import type { Validator, ValidationError } from "@awardit/formaggio/src";

const dobRegex = /\d{4}-\d{2}-\d{2}/;

export const numberGt = (f: string, n: number): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    Number(t[f]) > n ? [] : [{ error: "NUMBER_GT", field: f }];

export const numberLt = (f: string, n: number): Validator<FormData> =>
  (t: FormData): Array<ValidationError> =>
    Number(t[f]) < n ? [] : [{ error: "NUMBER_LT", field: f }];

export const isDob = (f: string): Validator<FormData> =>
  (t: FormData): Array<ValidationError> => {
    if (!(typeof t === "object" && t && (t[f] || t[f] === 0))) {
      return [{ error: "REQUIRED", field: f }];
    }

    if (!dobRegex.test(String(t[f]))) {
      return [{ error: "DATE_INVALID_FORMAT", field: f }];
    }

    const date = new Date(String(t[f]));

    if (!date.getTime()) {
      return [{ error: "DATE_INVALID_DATE", field: f }];
    }

    return [];
  };

export const isGender = (f: string, genders: Array<Gender>): Validator<FormData> =>
  (t: FormData): Array<ValidationError> => {
    if (!(typeof t === "object" && t && (t[f] || t[f] === 0))) {
      return [{ error: "REQUIRED", field: f }];
    }

    if (!genders.includes(t[f])) {
      return [{ error: "NOT_A_VALID_GENDER", field: f }];
    }

    return [];
  };
