/* @flow */

import React, { useState } from "react";
import cn from "classnames";

import { Checkbox } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import Spinner from "components/Spinner";

import styles from "./styles.scss";

type TableSmallProps = {
  approvedIDs: Array<{id: string, status: boolean}>,
  setApprovedIDs: Array<{id: string, status: boolean}> => any,
  headers: Array<string>,
  table: Array<Array<string>>,
};

const TableItem = ({ row, headers, handleChange }: any) => {
  const [currentItemApproved, setCurrentItemApproved] = useState<boolean>(false);
  const [currentItemDeclined, setCurrentItemDeclined] = useState<boolean>(false);
  const t = useTranslate();

  return (
    <React.Fragment key={headers[0]}>
      <div
        className={cn(styles.tr, styles.open)}
      />
      <table className={styles.table}>
        <tbody>
          {headers.slice(0, headers.length - 1).map((header, i) => (
            <tr key={`${header}-${i}`}>
              <td>{header}</td>
              <td>{row[i]}</td>
            </tr>
          ))}
          <tr>
            <td>{headers[headers.length - 1]}</td>
            <td>
              <Checkbox
                value={currentItemApproved}
                name={row[3]}
                onChange={() => {
                  setCurrentItemApproved(!currentItemApproved);
                  setCurrentItemDeclined(false);
                  handleChange({ id: row[3], status: 1 });
                }}
              />
            </td>
          </tr>
          <tr>
            {/* TODO Replace translation when subheader is added to headers by backend */}
            <td>{t("TINK.REMOVE_PROVIDER")}</td>
            <td>
              <Checkbox
                value={currentItemDeclined}
                name={`${row[3]}-decline`}
                onChange={() => {
                  setCurrentItemDeclined(!currentItemDeclined);
                  setCurrentItemApproved(false);
                  handleChange({ id: row[3], status: 0 });
                }}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  );
};

const TableSmall = ({ approvedIDs, setApprovedIDs, table, headers }: TableSmallProps) => {
  const handleChange = ({ id, status }) => {
    let newArray = [...approvedIDs];

    if (newArray.length > 0) {
      const foundItemIndex = newArray.findIndex(item => item.id === id);

      if (foundItemIndex >= 0 && newArray[foundItemIndex].status !== status) {
        const tempArr = [...approvedIDs];
        tempArr[foundItemIndex] = { id, status };
        newArray = tempArr;
        setApprovedIDs(newArray);

        return;
      }

      if (foundItemIndex >= 0 && newArray[foundItemIndex].status === status) {
        const tempArr = [...approvedIDs];
        tempArr.splice(foundItemIndex, 1);
        newArray = tempArr;
        setApprovedIDs(newArray);

        return;
      }

      if (foundItemIndex < 0) {
        newArray.push({ id, status });
        setApprovedIDs(newArray);

        return;
      }
    }

    newArray.push({ id, status });
    setApprovedIDs(newArray);
  };

  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <div className={styles.tbody}>
        {table.map(row => (
          <TableItem
            key={row[0]}
            headers={headers}
            row={row}
            handleChange={item => handleChange(item)}
          />
        ))
        }
      </div>
    </div>
  );
};

export const TableSmallHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
