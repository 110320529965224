/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { SearchRequest, SearchResponse } from "@crossroads/shop-state/search";

import { SEARCH_REQUEST, SEARCH_RESPONSE } from "@crossroads/shop-state/search";
import { search } from "queries";

import { PAGE_SIZE } from "./route";

type History = {
  push: (path: string) => void,
};

const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  storage.addEffect({
    effect: (msg: SearchRequest, path) => {
      return client(search, {
        query: msg.query,
        filter: msg.filters,
        sort: msg.sort,
        page: typeof msg.page === "number" ? msg.page : 1,
        pageSize: PAGE_SIZE,
      }, { cache: true })
        .then(({ productsBySearch }) => {
          storage.replyMessage((({
            tag: SEARCH_RESPONSE,
            data: productsBySearch,
          }): SearchResponse), path);
        })
        .catch(() => {
          // TODO: Handle the error
          history.push("/");
        });
    },
    subscribe: { [SEARCH_REQUEST]: true },
  });
};

export default registerClient;
