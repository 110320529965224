/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import bindHomeEffects from "./home";
import bindAllProducts from "./all-products";
import bindRedeemEffects from "./redeem";
import bindCustomerEffects from "./customer";
import bindQuoteEffects from "./quote";
import bindRouteEffects from "./route";
import bindOrderEffects from "./order";
import bindBrandEffects from "./brand";
import bindSearchEffects from "./search";
import bindNewsPostsEffects from "./news-posts";
import bindAffiliateEffects from "./affiliate";
import bindAffiliateCategoriesEffects from "./affiliate-categories";
import bindAffiliateListEffects from "./affiliate-list";
import bindAffiliateListSelectedEffects from "./affiliate-list-selected";
import bindTransactionEffects from "./transaction";
import bindLeaderboardEffects from "./leaderboard";
import bindReportEffects from "./reports";
import bindVerifyListEffects from "./verify-list";
import bindConnectedAccountsEffects from "./connected-accounts";
import bindQuestionnaireEffects from "./questionnaire";
import bindDistrictInfoEffects from "./district-info";
import bindDistrictListEffects from "./district-list";
import bindOffersEffects from "./offers";
import bindTinkTransactionsEffects from "./tink-transactions";
import bindTinkProvidersEffects from "./tink-providers";
import bindCurrentPageInfo from "./current-page-info";
import bindQuizEffects from "./quiz";
import bindTeampotEffects from "./teampot";
import bindCurrentInfo from "./current-info";
import bindPhotoService from "./photo-service";
import bindPhotoServiceList from "./photo-service-list";
import bindFormsEffect from "./forms";
import bindCmsEffects from "./cms";
import bindAccountFormsEffects from "./account-forms";
import bindMembergroupEffects from "./member-group";

type History = {
  push: (path: string) => void,
};

export const registerClient = (
  storage: Storage,
  client: Client<{}>,
  history: History) => {
  bindRouteEffects(storage, client);
  bindHomeEffects(storage, client);
  bindRedeemEffects(storage, client);
  bindCustomerEffects(storage, client, history);
  bindQuoteEffects(storage, client);
  bindOrderEffects(storage, client);
  bindBrandEffects(storage, client);
  bindSearchEffects(storage, client, history);
  bindNewsPostsEffects(storage, client);
  bindAffiliateEffects(storage, client);
  bindAffiliateCategoriesEffects(storage, client);
  bindAffiliateListEffects(storage, client);
  bindAffiliateListSelectedEffects(storage, client);
  bindTransactionEffects(storage, client);
  bindLeaderboardEffects(storage, client);
  bindReportEffects(storage, client);
  bindVerifyListEffects(storage, client);
  bindConnectedAccountsEffects(storage, client);
  bindQuestionnaireEffects(storage, client);
  bindDistrictInfoEffects(storage, client);
  bindDistrictListEffects(storage, client);
  bindAllProducts(storage, client);
  bindOffersEffects(storage, client);
  bindTinkTransactionsEffects(storage, client);
  bindTinkProvidersEffects(storage, client);
  bindCurrentPageInfo(storage, client);
  bindQuizEffects(storage, client);
  bindTeampotEffects(storage, client);
  bindCurrentInfo(storage, client);
  bindPhotoService(storage, client);
  bindPhotoServiceList(storage, client);
  bindFormsEffect(storage, client);
  bindCmsEffects(storage, client);
  bindAccountFormsEffects(storage, client);
  bindMembergroupEffects(storage, client);
};
