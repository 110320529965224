/* @flow */

import type { Product, Quote, Customer } from "shop-state/types";
import type { CSSModule } from "css-module";
import type { Option } from "@crossroads/ui-components";
import type { PointsPrice } from "helpers/points";
import type { Mode } from "state/view-mode";

import React, { useState, useRef, useEffect, useCallback, useContext } from "react";
import cn from "classnames";

import { setMode, MODE } from "state/view-mode";
import { useTranslate } from "@awardit/react-use-translate";
import { syncQuote } from "@crossroads/shop-state/quote";
import useFormat from "helpers/use-format";
import { productIsNotLackingPrice, getEstimatedDeliveryText } from "helpers/utils";
import { useData, useSendMessage } from "crustate/react";
import { QuoteData } from "data";
import { useClient, StoreInfoContext } from "entrypoint/shared";

import { removeQuote as removeQuoteRequest, quote as quoteQuery } from "queries";

import PriceSplit from "components/PriceSplit";
import {
  AddToCart as AddToCartComponent,
  Dialogue,
  inStock,
} from "@crossroads/ui-components";
import SystemMessageBanner from "components/SystemMessageBanner";
import Button from "components/Button";
import { ColorSelect } from "components/ProductOptions/color";

type Props = {
  location: {
    state: {
        breadcrumbLink: string,
        list: string,
        position: number,
    },
  },
  quote: Quote,
  customer: Customer,
  product: Product,
  selected: Option,
  setSelected: ((Option => Option) | Option) => void,
  pointsPrice: ?PointsPrice,
  gaContext: any,
  styles: CSSModule,
};

export const AddToCartBtn = ({
  loading = false, outOfStock, typeButton, btnAction, setRef, styles,
}: { loading?: boolean,
    outOfStock: boolean,
    typeButton: boolean,
    btnAction?: () => void,
    setRef: any,
    styles: CSSModule,
  }) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";
  const { content: { productView: { hideAddToCartBtn } } } = useContext(StoreInfoContext);

  if (hideAddToCartBtn !== null && hideAddToCartBtn !== undefined && hideAddToCartBtn === true) {
    return null;
  }

  return (
    <div ref={setRef}>
      <Button
        className={cn(styles.addToCart, "awardit-addToCartBtn")}
        variant="primary"
        loading={addingToCart || loading}
        type={!typeButton ? "submit" : "button"}
        onClick={() => btnAction && btnAction()}
      >
        {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
      </Button>
    </div>
  );
};

const AddToCart = ({
  location,
  quote,
  customer,
  product,
  selected,
  setSelected,
  pointsPrice,
  gaContext,
  styles,
}: Props) => {
  const client = useClient();
  const { formatPoints } = useFormat();
  const sendMessage = useSendMessage();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [resubmitAddToCart, setResubmitAddToCart] = useState(false);
  const [specificPointDeficit, setSpecificPointDeficit] = useState({ partnerName: "", partnerPointDeficit: 0 });
  const [isConflictingItem, setIsConflictingItem] = useState(false);
  const [typeButton, setTypeButton] = useState(false);
  const [productRestrictedToPartner, setProductRestrictedToPartner] = useState(false);
  const productAwarditRestrictionPid =
        product.attributes.awarditRestrictionPid && product.attributes.awarditRestrictionPid;
  const brand = product.attributes.manufacturer;
  const outOfStock = !inStock(product, selected);
  const buttonRef = useRef(null);
  const t = useTranslate();
  const onAdd = p => {
    const GAProduct = {
      sku: p.sku,
      name: p.name,
      price: p.price,
      qty: 1,
      attributes: {
        manufacturer: p.attributes.manufacturer,
      },
      categories: p.categories,
    };
    gaContext.registerModifyCart(GAProduct, "add_to_cart", p.price.exVat);
  };

  const setViewMode = useCallback((mode: Mode) => {
    sendMessage(setMode(mode));
  }, [sendMessage]);

  const removeQuoteAndAddToCart = async () => {
    try {
      await client(removeQuoteRequest);
      setIsConflictingItem(false);
      // Trigger click on button when cart is cleared
      const buttonEl = buttonRef.current?.children[0];
      if (buttonEl) {
        buttonEl.click();
      }

      if (!quote) {
        return;
      }

      setTimeout(async () => {
        const { quote } = await client(quoteQuery);
        sendMessage(syncQuote(quote));

        if (quote.items.length > 0) {
          setViewMode(MODE.CART);
        }
      }, 1000);
    }
    catch (e) {
      console.log(e);
    }
  };

  const onPrimaryAction = () => {
    // Change to submit
    setTypeButton(false);
    // Trigger click
    setResubmitAddToCart(true);
    // Hide dialog
    setShowConfirmDialog(false);
  };

  const openConfirmDialog = () => {
    if (isConflictingItem) {
      setShowConfirmDialog(true);
    }
  };

  useEffect(() => {
    if (resubmitAddToCart && typeButton === false) {
      if (quote) {
        removeQuoteAndAddToCart();
      }
    }
  }, [resubmitAddToCart, typeButton, buttonRef]);

  useEffect(() => {
    const p = {
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    };

    const locationState = (location.state !== null && location.state !== undefined) ?
      location.state : { breadcrumbLink: "", list: brand, position: 0 };

    gaContext.registerProductDetailsView(
      p,
      product.price.exVat,
      locationState.list,
      locationState.position
    );

    /** Check if the product is restricted to a partner group or not,
    if the customer doesn't belong to the same partner or don't have
    enough points an error message will be displayed */

    if (productAwarditRestrictionPid) {
      // If customer has points in required partner
      if (customer &&
          customer.pointsPerPartner &&
          customer.pointsPerPartner.some(partner =>
            partner.partnerUserId === productAwarditRestrictionPid)) {
        // eslint-disable-next-line array-callback-return
        customer.pointsPerPartner.find(partner => {
          if (partner.partnerUserId === productAwarditRestrictionPid) {
            setSpecificPointDeficit({
              partnerPointDeficit:
                  (partner.sumBalance - product.pointsPrices[0].points.value.exVat) * -1,
              partnerName: partner.partnerName });
          }
        });
      }
      // If customer does not have points in required partner
      else {
        setSpecificPointDeficit({
          partnerPointDeficit:
              (product.pointsPrices[0].points.value.exVat),
          partnerName: product.attributes.awarditRestrictionPname });
      }
    }

    /**
    Logic to handle products restricted to certain partner:
    Check if the shopping cart is empty or
    only contains products with the same awarditRestrictionPid
    as the current product. If so, we approve the purchase
    provided that we have enough points from the specific partner
    If there are other items in the shopping cart
    we need to clear them before we can add our restricted product.
    Is the product restricted by Partner ID */

    const productIsRestrictedToPid =
      product.attributes.awarditRestrictionPid !== undefined &&
      product.attributes.awarditRestrictionPid !== null;

    if (productIsRestrictedToPid) {
      setProductRestrictedToPartner(productIsRestrictedToPid);
    }

    // Is any of the items in the cart restricted by Partner ID
    const cartIsRestrictedToPid = quote.items.some(x => Object.keys(x.product.attributes).includes("awarditRestrictionPid") && x.product.attributes.awarditRestrictionPid !== null);

    // If neither applies then skip rest of logic
    if (cartIsRestrictedToPid !== true && !productIsRestrictedToPid) {
      return;
    }

    if (cartIsRestrictedToPid && quote && quote.items.some(x =>
      x.product.attributes.awarditRestrictionPid !==
      product.attributes.awarditRestrictionPid)) {
      setTypeButton(true);
      setIsConflictingItem(true);
    }
    else if (quote.items.length > 0 && productIsRestrictedToPid) {
      setTypeButton(true);
      setIsConflictingItem(true);
    }
  }, []);

  return (
    <>
      {/* Only show add to cart if condition is true to prevent accidental free products */}
      { productIsNotLackingPrice(product) && (
        <>
          <PriceSplit
            className={styles.price} pointsPrice={pointsPrice} price={product.price} />
          <div className={styles.estimatedDeliveryDays}>
            <p>{getEstimatedDeliveryText(product)}</p>
          </div>
          {specificPointDeficit.partnerPointDeficit <= 0 ?
            <AddToCartComponent
              product={product}
              selected={selected}
              setSelected={setSelected}
              templates={{ color: ColorSelect }}
              qty={1}
              onAdd={onAdd}
            >
              <div className={styles.action}>
                <AddToCartBtn
                  setRef={buttonRef}
                  outOfStock={outOfStock}
                  typeButton={typeButton}
                  btnAction={openConfirmDialog}
                  styles={styles}
                />
              </div>
            </AddToCartComponent> :
            <SystemMessageBanner
              warningStyling
              className={cn(
                { [cn(styles.warning)]: false }
              )}
              heading={t("PRODUCT.SPECIFIC_POINT_DEFICIT_HEADING")}
              body={t("PRODUCT.SPECIFIC_POINT_DEFICIT_BODY",
                { partner: specificPointDeficit.partnerName,
                  points: formatPoints(specificPointDeficit.partnerPointDeficit) })}
            />
          }
          {showConfirmDialog &&
          <Dialogue
            open={showConfirmDialog}
            setOpen={() => setShowConfirmDialog(false)}
            title={t("PRODUCT.PRODUCTS_CANNOT_BE_COMBINED")}
            primaryAction={t("TINK.CONFIRM.OK")}
            secondaryAction={t("TINK.CONFIRM.CANCEL")}
            onPrimaryAction={onPrimaryAction}
            onSecondaryAction={() => setShowConfirmDialog(false)}
          >
            {!productRestrictedToPartner ? t("PRODUCT.RESTRICTION_PRODUCT_ADD_TO_CART") : t("PRODUCT.RESTRICTION_PRODUCT_IN_CART")}
          </Dialogue>
          }
        </>
      )}
    </>
  );
};

export default AddToCart;
