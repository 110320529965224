/* @flow */

import type { QuizHistoryItemRes, QuizHistoryListItemRes, QuizHistory } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: ?QuizHistory }
  | { state: "LOADED", data: QuizHistory }
  | { state: "ERROR", error: string };

type ListData =
| { state: "LOADING", data: ?Array<QuizHistoryListItemRes> }
| { state: "LOADED", data: Array<QuizHistoryListItemRes> }
| { state: "ERROR", error: string };

type RowsData =
  | { state: "LOADING", data: ?Array<QuizHistoryItemRes> }
  | { state: "LOADED", data: Array<QuizHistoryItemRes> }
  | { state: "ERROR", error: string };

export type QuizHistoryEffectResponse =
  Response<typeof QUIZ_HISTORY_EFFECT_RESPONSE, QuizHistory>;

export type QuizHistoryEffectRequest = {
  tag: typeof QUIZ_HISTORY_EFFECT_REQUEST,
  id: number,
};
type QuizHistoryRequest = {
  tag: typeof QUIZ_HISTORY_EFFECT_REQUEST,
};

// list
export type QuizHistoryListEffectResponse =
  Response<typeof QUIZ_HISTORY_LIST_EFFECT_RESPONSE, Array<QuizHistoryListItemRes>>;

export type QuizHistoryListEffectRequest = {
  tag: typeof QUIZ_HISTORY_LIST_EFFECT_REQUEST,
};
type QuizHistoryListRequest = {
  tag: typeof QUIZ_HISTORY_LIST_REQUEST,
};

// rows
export type QuizHistoryRowsEffectResponse =
  Response<typeof QUIZ_HISTORY_ROWS_EFFECT_RESPONSE, Array<QuizHistoryItemRes>>;

export type QuizHistoryRowsEffectRequest = {
  tag: typeof QUIZ_HISTORY_ROWS_EFFECT_REQUEST,
  id: number,
};
type QuizHistoryRowsRequest = {
  tag: typeof QUIZ_HISTORY_ROWS_EFFECT_REQUEST,
};

export const QUIZ_HISTORY_ROWS_EFFECT_RESPONSE: "response/quiz_history_rows" = "response/quiz_history_rows";
export const QUIZ_HISTORY_ROWS_EFFECT_REQUEST: "request/quiz_history_rows" = "request/quiz_history_rows";
export const QUIZ_HISTORY_EFFECT_RESPONSE: "response/quiz_history" = "response/quiz_history";
export const QUIZ_HISTORY_EFFECT_REQUEST: "request/quiz_history" = "request/quiz_history";
export const QUIZ_HISTORY_LIST_EFFECT_RESPONSE: "response/quiz_history_list" = "response/quiz_history_list";
export const QUIZ_HISTORY_LIST_EFFECT_REQUEST: "request/quiz_history_list" = "request/quiz_history_list";
export const QUIZ_HISTORY_LIST_REQUEST: "request/quiz_history_list" = "request/quiz_history_list";

export const getQuizHistoryRows = (id: number): QuizHistoryRowsRequest => ({
  tag: QUIZ_HISTORY_ROWS_EFFECT_REQUEST,
  id,
});

export const getQuizHistoryList = (): QuizHistoryListRequest => {
  return {
    tag: QUIZ_HISTORY_LIST_REQUEST,
  };
};

export const QuizHistoryRowsModel: Model<
  RowsData,
  {id: number | null},
  QuizHistoryRowsEffectRequest | QuizHistoryRowsEffectResponse | QuizHistoryRowsRequest
> = {
  id: "quiz_history_rows",
  init: ({ id }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: QUIZ_HISTORY_ROWS_EFFECT_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case QUIZ_HISTORY_ROWS_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const QuizHistoryListModel: Model<
  ListData,
  {},
  QuizHistoryListEffectRequest | QuizHistoryListEffectResponse | QuizHistoryListRequest
> = {
  id: "quiz_history_list",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: QUIZ_HISTORY_LIST_EFFECT_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case QUIZ_HISTORY_LIST_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      case QUIZ_HISTORY_LIST_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: QUIZ_HISTORY_LIST_EFFECT_REQUEST });

      default:
    }
  },
};

export const QuizHistoryModel: Model<
  Data,
  {id: number},
  QuizHistoryEffectRequest | QuizHistoryEffectResponse | QuizHistoryRequest
> = {
  id: "quiz_history",
  init: ({ id }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: QUIZ_HISTORY_EFFECT_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case QUIZ_HISTORY_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
