/* @flow */

import type { FilterLocation as Location, ProductSortInput, StoreConfiguration } from "shop-state/types";

import { useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import usePrevious from "helpers/use-previous";
import { usePointsToPrice } from "helpers/utils";
import { StoreInfoContext } from "entrypoint/shared";

import {
  getInputFilters,
  getInputSort,
  getPage,
  getSearchFromFilter } from "@crossroads/shop-state/filter";

export const getDefaultSort = (configuration: StoreConfiguration): ProductSortInput | null => {
  if ((configuration.productSortOrder !== "ASC" && configuration.productSortOrder !== "DESC") ||
    configuration.productSortType === undefined) {
    return null;
  }

  return {
    code: configuration.productSortType,
    order: configuration.productSortOrder,
  };
};

export const locationWithDefaults = (
  location: Location,
  pointsToPrice: number => number,
  defaultSort?: ?ProductSortInput): Location => {
  const productFilters = getInputFilters(location).reduce((acc, curr) => {
    if (curr.code === "price") {
      const price = {
        ...curr,
        minValue: typeof curr.minValue === "number" ? pointsToPrice(curr.minValue) : undefined,
        maxValue: typeof curr.maxValue === "number" ? pointsToPrice(curr.maxValue) : undefined,
        incVat: false,
      };

      return [...acc, price];
    }

    return [...acc, curr];
  }, []);
  const productSort = defaultSort ? getInputSort(location, defaultSort) : getInputSort(location);
  const page = getPage(location);

  return {
    ...location,
    search: getSearchFromFilter(productFilters, productSort, page),
  };
};

export const useUpdateProductList = (load: Location => void) => {
  const { location } = useHistory();
  const prevLocation = usePrevious(location);
  const pointsToPrice = usePointsToPrice();
  const { configuration } = useContext(StoreInfoContext);
  const defaultSort = getDefaultSort(configuration);

  useEffect(() => {
    if (prevLocation && location.search !== prevLocation.search) {
      load(locationWithDefaults(location, pointsToPrice, defaultSort));
    }
  }, [location, prevLocation]);
};
