/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  AffiliateListSelectedEffectResponse,
  AffiliateListSelectedEffectRequest } from "state/affiliate-list-selected";

import {
  AFFILIATE_LIST_SELECTED_EFFECT_REQUEST,
  AFFILIATE_LIST_SELECTED_EFFECT_RESPONSE,
} from "state/affiliate-list-selected";

import { affiliatesListSelected as affiliatesListSelectedQuery } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: AffiliateListSelectedEffectRequest, path) => {
      const { affiliatesListSelected } = await client(affiliatesListSelectedQuery, {
        size: msg.size,
      });

      if (affiliatesListSelected.result === "SUCCESS") {
        return storage.replyMessage(({
          tag: AFFILIATE_LIST_SELECTED_EFFECT_RESPONSE,
          data: affiliatesListSelected.data.filter(f => f.name !== "tom/deletad/gömd"),
        }: AffiliateListSelectedEffectResponse), path);
      }

      return storage.replyMessage(({
        tag: AFFILIATE_LIST_SELECTED_EFFECT_RESPONSE,
        error: "AFFILIATE_LIST_SELECTED_LOAD_ERROR",
      }: AffiliateListSelectedEffectResponse), path);
    },
    subscribe: { [AFFILIATE_LIST_SELECTED_EFFECT_REQUEST]: true },
  });
};

export default registerClient;
