/* @flow */

import React, { useState, useContext } from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import Button from "components/Button";
import { CustomerData } from "data";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { focusInvalidField } from "helpers/utils";
import Field from "components/Field";
import { useData, useSendMessage } from "crustate/react";
import { login } from "@crossroads/shop-state/customer";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const validation = rules([
  isRequired("username"),
  isRequired("password"),
]);

const LoginForm = ({ className }: Props) => {
  const t = useTranslate();
  const { routes, content: { loginview } } = useContext(StoreInfoContext);
  const customer = useData(CustomerData);
  const sendMessage = useSendMessage();
  const [state, setState] = useState({});
  const loading = customer.state === "LOGGING_IN";

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (loading) {
      return;
    }

    sendMessage(login(state.username, state.password));
  };

  const errors = validation(state);

  return (
    <div
      className={cn(styles.block, className, "awardit-loginForm")}
      style={loginview.formOpacity ? { backgroundColor: `rgba(255, 255, 255, ${loginview.formOpacity})` } : {}}
    >
      <h1>{t("LOGIN.TITLE")}</h1>
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <Form
            method="POST"
            value={state}
            errors={errors}
            onError={focusInvalidField}
            onSubmit={submit}
            onChange={(x: any) => setState({ ...state, ...x })}
          >
            <Field type="text" name="username" placeholder={loginview.usernameText ?? ""} />

            <Field type="password" name="password" autoComplete="off" placeholder={loginview.userPasswordText ?? ""} />

            <Button
              id="login"
              type="submit"
              className={styles.button}
              loading={loading}
              variant="primary"
            >
              {t("LOGIN.SUBMIT")}
            </Button>
            <noscript>
              <p className={styles.noScriptParagraph}>
                {t("NO_JAVASCRIPT.LOGIN")}
              </p>
            </noscript>
          </Form>
        </div>
        <div className={styles.bottom}>
          {routes.passwordResetView && routes.passwordResetView.url &&
          loginview.forgotPasswordButtonText && (
            <div className={cn(styles.forgot_password, "awardit-loginFormForgotPassword")}>
              <Link to={routes.passwordResetView?.url}>
                {loginview.forgotPasswordButtonText}
              </Link>
            </div>
          )}

          {loginview.createAccountEnabled !== undefined &&
          loginview.createAccountEnabled !== null &&
          loginview.createAccountEnabled === true &&
          routes.registerView && routes.registerView.url &&
            <div className={cn(styles.no_account, "awardit-loginFormNoAccount")}>
              <Link to={routes.registerView?.url}>{loginview.createAccountButtonText ?? t("LOGIN.CREATE_ACCOUNT")}</Link>
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
