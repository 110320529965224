/* @flow */

import type { CurrentInfoItem, CurrentInfoRoute } from "shop-state/types";

import React, { useContext } from "react";
import { useData } from "crustate/react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { CurrentInfoItemData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import Breadcrumbs from "components/Breadcrumbs";
import LoadingView from "components/LoadingView";
import ErrorView from "components/ErrorView";
import NotFoundView from "components/NotFoundView";
import Wrapper from "components/Wrapper";
import Pixel from "components/Pixel";
import HeroIntro from "components/HeroIntro";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  data: CurrentInfoItem,
  parentRoute: ?CurrentInfoRoute,
};

type DummyProps = {
  title: ?string,
  parentRoute: ?CurrentInfoRoute,
};

const CurrentInfoItemView = ({ data, parentRoute }: Props) => {
  const { content: { heroIntroView } } = useContext(StoreInfoContext);
  const parentUrl = parentRoute ? parentRoute.url : null;

  return (
    <>
      {Boolean(heroIntroView.toggle) && <HeroIntro title={data.title} />}
      <Wrapper variant="pageWrapper" className={cn(styles.wrapper, "awardit-pageWrapper")}>
        <Helmet title={data.title || ""} />
        <div className={cn(styles.currentInfoItemView, "awardit-currentInfoItemView")}>
          {parentRoute &&
          <Breadcrumbs current={data.title || ""} links={[{ url: parentRoute.url, name: parentRoute.title }]} />
          }
          {data.image &&
          <img src={data.image} className={styles.mainImage} />
          }
          {data.title && Boolean(heroIntroView.toggle) === false &&
          <h1>{data.title}</h1>
          }
          {/* eslint-disable react/no-danger */}
          {data.content &&
          <div className="typography" dangerouslySetInnerHTML={{ __html: data.content }} />
          }
          {/* eslint-enable react/no-danger */}
          {data.tags && data.tags.length > 0 &&
            <div className={styles.tags}>
              {data.tags.map((tag, i) => (
                <Link
                  key={i}
                  className={styles.tag}
                  to={parentUrl ? `${parentUrl}?tags=${tag}` : null}
                >
                  {tag}
                </Link>
              ))}
            </div>
          }
        </div>
      </Wrapper>
    </>
  );
};

const CurrentInfoItemDummyView = ({ title, parentRoute }: DummyProps) => {
  const { content: { heroIntroView } } = useContext(StoreInfoContext);
  return (
    <>
      {Boolean(heroIntroView.toggle) && <HeroIntro title={title} />}
      <Wrapper variant="pageWrapper" className={cn((styles.dummy), "awardit-pageWrapper")}>
        <Helmet title={title || ""} />
        <div className={cn(styles.currentInfoItemView, "awardit-currentInfoItemView")}>
          {parentRoute &&
          <Breadcrumbs current={title || ""} links={[{ url: parentRoute.url, name: parentRoute.title }]} />
          }
          <Pixel className={styles.mainImage} />
          {title && Boolean(heroIntroView.toggle) === false &&
          <h1>{title}</h1>
          }
          <div key="ciid_1" className={styles.row} />
          <div key="ciid_3" className={styles.row} />
          <div key="ciid_2" className={styles.row} />
          <div key="ciid_4" className={styles.row} />
          <div key="ciid_5" className={styles.row} />
          <div key="ciid_6" className={styles.row} />
        </div>
      </Wrapper>
    </>
  );
};

const CurrentInfoItemViewWrapper = ({ parentRoute }: { parentRoute: ?CurrentInfoRoute }) => {
  const data = useData(CurrentInfoItemData);
  const location = useLocation();

  if (data.state === "LOADING") {
    if (typeof location.state?.hint?.title === "string") {
      return (
        <CurrentInfoItemDummyView
          parentRoute={parentRoute}
          title={location.state.hint.title}
        />
      );
    }

    return <LoadingView />;
  }

  if (data.state === "ERROR") {
    return <ErrorView />;
  }

  if (data.state === "LOADED" && data.data) {
    return <CurrentInfoItemView parentRoute={parentRoute} data={data.data} />;
  }

  return <NotFoundView />;
};

export default CurrentInfoItemViewWrapper;
