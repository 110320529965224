
/* @flow */

import type { AffiliatesListItem } from "shop-state/types";

import React from "react";
import AffiliateCard, { DummyAffiliateCard } from "components/AffiliateCard";
import { Items, Item } from "components/UiComponents";

import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  items: Array<AffiliatesListItem>,
};

const AffiliateList = ({ items }: Props) => {
  return (
    items.length > 0 && (
      <Items className={styles.affiliateList}>
        {items.map((x, i) => (
          <Item key={`${x.name}_${i}`} className={cn("awardit-affiliateCard", styles.item)}>
            <AffiliateCard affiliate={x} />
          </Item>
        ))}
      </Items>
    )
  );
};

export const AffiliateDummyList = ({ items }: { items: Array<null>}) => {
  return (
    <Items>
      {items.map((x, i) => (
        <Item key={`dummy_${i}`} className={styles.item}>
          <DummyAffiliateCard />
        </Item>
      ))}
    </Items>
  );
};

export default AffiliateList;
