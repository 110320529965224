/* @flow */

import type { TeamPotHistoryItem } from "shop-state/types";
import React from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import Spinner from "components/Spinner";
import ChevronIcon from "icons/chevron-mini.svg";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import UsersModal from "../UsersModal";
import { usePointsLabel } from "helpers/points";

import styles from "./styles.scss";

type historyItem = {
  commentar: string,
  countmem: string,
  datum: string,
  sumpoints: string,
  sumrank: string,
  description: string,
};
type TableSmallProps = {
  items: $ReadOnlyArray<historyItem>,
  current?: string | null,
  onClick: historyItem => void,
  users: $ReadOnlyArray<TeamPotHistoryItem>,
  onUsersClick: string => void,
};

const TableSmallHead = () => {
  return (
    <div className={styles.thead}>
      <div className={styles.th} />
    </div>
  );
};

const TableSmall = ({ items, current, onClick, users, onUsersClick }: TableSmallProps) => {
  const t = useTranslate();
  const label = usePointsLabel();
  const { formatPoints } = useFormat(false);

  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <TableSmallHead />
      <div className={styles.tbody}>
        {items.map((x, i) => (
          <React.Fragment key={`${i}-${x.datum}`}>
            <div
              className={cn(styles.tr, { [styles.open]: current === x.datum })}
              onClick={() => {
                onClick(x);
              }}
            >
              <div>{x.datum}</div>
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === x.datum} className={styles.expandable}>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td>{t("MOTIVATION_POT.ACTIVITY")}</td>
                    <td>{x.description}</td>
                  </tr>
                  <tr>
                    <td>{t("MOTIVATION_POT.DATE")}</td>
                    <td className={styles.noWrap}>{x.datum}</td>
                  </tr>
                  <tr>
                    <td>{t("MOTIVATION_POT.MEMBER_COUNT")}</td>
                    <td>
                      <UsersModal
                        users={users}
                        countmem={x.countmem}
                        date={x.datum}
                        onClickUsers={onUsersClick}
                      />
                    </td>
                  </tr>
                  {parseInt(x.sumrank, 10) > 0 ?
                    <tr>
                      <td>{t("MOTIVATION_POT.RANK", { pointsLabel: label(true) })}</td>
                      <td>{formatPoints(parseInt(x.sumrank, 10))}</td>
                    </tr> :
                    <tr>
                      <td>{t("MOTIVATION_POT.SHARED_POINTS")}</td>
                      <td>{formatPoints(parseInt(x.sumpoints, 10))}</td>
                    </tr>
                  }
                  <tr>
                    <td>{t("MOTIVATION_POT.COMMENT")}</td>
                    <td>{x.commentar}</td>
                  </tr>
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.spacer} />
    </div>
  );
};

export const TableSmallHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
