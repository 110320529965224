/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

export type BlockCategory = {
  text: string,
  loggedInText: string, // Mapped to Sub-Heading
  smallImage: string,
  largeImage: string,
  cta: string,
  link: string,
  modifier: string,
};

export type Home = {
  featuredProducts: Array<ProductCardProduct>,
};

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Home }
  | { state: "ERROR", error: string };

export type HomeResponse = Response<typeof HOME_RESPONSE, ?Home>;

export type HomeRequest = {
  tag: typeof HOME_REQUEST,
  type: "home",
};

export const HOME_RESPONSE: "response/home" = "response/home";
export const HOME_REQUEST: "request/home" = "request/home";

export const HomeModel: Model<Data, { }, HomeRequest | HomeResponse> = {
  id: "home",
  init: () =>
    updateData(
      { state: "LOADING", type: "home" },
      { tag: HOME_REQUEST, type: "home" }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case HOME_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
