/* @flow */

import type { FormSubmitsDetails, FormRow } from "shop-state/types";

import React, { useState, useEffect } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { useClient } from "entrypoint/shared";
import { updateFormData } from "queries";
import { useSendMessage } from "crustate/react";
import { addMessage } from "state/messages";
import { getFormSubmits } from "state/forms";
import { Dialogue, Foldable } from "@crossroads/ui-components";
import FormComponent from "components/FormComponent";
import Button from "components/Button";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";

type Props = {
  item: FormSubmitsDetails,
  customerId: string,
  formId: number,
  formRows: Array<FormRow>,
  deleteItem: () => Promise<void>,
};

const FormListItem = ({ item, customerId, formId, formRows, deleteItem }: Props): React$Node => {
  const [activeItem, setActiveItem] = useState<{ id: number } | null>(null);
  const [foldableOpen, setFoldableOpen] = useState(false);
  const [formState, setFormState] = useState({});
  const sendMessage = useSendMessage();
  const client = useClient();
  const t = useTranslate();

  const rows = formRows !== undefined && formRows !== null ? formRows : [];
  const injectObj = {};

  item.data.forEach((data, i) => {
    if (rows.length > 0) {
      injectObj[`varid${rows[i].id}`] = data.value;
    }
  });

  const formatObject = (obj: {}, formId, submitId) => {
    if (typeof obj === "object" && obj !== null) {
      const keys = Object.keys(obj);
      const res = {
        id: formId.toString(),
        submitid: submitId.toString(),
        values: [],
      };

      for (const key of keys) {
        if (obj[key]) {
          res.values.push(`${key}:${obj[key]}`);
        }
      }

      return res;
    }
  };

  const updateFormDetails = async () => {
    const data = formatObject(formState, formId, item.id);

    if (data?.values.length || data?.values) {
      const response = await client(updateFormData, { form: (data: any) });

      if (response.updateFormData.result === false) {
        sendMessage(addMessage(`POST_FORM_ERROR`, "error"));
      }
      else {
        window.scroll({ top: 0, left: 0, behavior: "auto" });
        sendMessage(addMessage(`POST_FORM_SUCCESS`, "success"));
        sendMessage(getFormSubmits(formId));
        setFoldableOpen(false);
      }
    }
  };

  const deleteAction = () => {
    deleteItem();
    setActiveItem(null);
  };

  useEffect(() => {
    if (foldableOpen === true) {
      setFormState(injectObj);
    }
  }, [foldableOpen]);

  return (
    <div className={styles.formListItem}>
      <Dialogue
        className={styles.confirmDialogue}
        open={activeItem !== null}
        setOpen={() => setActiveItem(null)}
        title={t("FORM_LIST.REMOVE_DIALOGUE.TITLE")}
        closeIcon={<CloseIcon />}
        primaryAction={t("FORM_LIST.REMOVE_DIALOGUE.CONFIRM")}
        secondaryAction={t("FORM_LIST.REMOVE_DIALOGUE.CANCEL")}
        onPrimaryAction={() => deleteAction()}
        onSecondaryAction={() => setActiveItem(null)}
      >
        {t("FORM_LIST.REMOVE_DIALOGUE.MESSAGE")}
      </Dialogue>
      <Foldable open={!foldableOpen}>
        {customerId === item.memberuserid ?
          <div className={styles.controls}>
            <Button variant="link" onClick={() => setFoldableOpen(true)}>{t("COMMON.EDIT")}</Button>
            <Button variant="link" onClick={() => setActiveItem({ id: item.id })}>{t("COMMON.REMOVE")}</Button>
          </div> : null
        }
        <div className={styles.textRows}>
          {item.data.map((column, j) => (
            <p key={j} className={styles.textRow}>
              <span className={styles.muted}>{column.name}: </span>
              <span>{column.value}</span>
            </p>
          ))}
        </div>
      </Foldable>
      <Foldable open={foldableOpen}>
        <div className={styles.controls}>
          <Button variant="link" onClick={() => setFoldableOpen(false)}>{t("COMMON.CLOSE")}</Button>
        </div>
        <FormComponent
          state={formState}
          setValue={x => {
            setFormState(x);
          }}
          formData={formRows}
          postForm={updateFormDetails}
          className={styles.form}
        />
      </Foldable>
    </div>
  );
};

export default FormListItem;
