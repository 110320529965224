/* @flow */

import type { Offers } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | {| +state: "LOADING", data: ?Offers |}
  | {| +state: "LOADED", data: Offers |}
  | {| +state: "ERROR", error: mixed |};

export type OffersResponse = Response<typeof OFFERS_RESPONSE, ?Offers>;
export type OffersRequest = {
  tag: typeof OFFERS_REQUEST,
  page: number,
  size: number,
  prio?: ?string,
};

export const OFFERS_RESPONSE: "response/offers" = "response/offers";
export const OFFERS_REQUEST: "request/offers" = "request/offers";

export const getOffers = () => ({
  tag: OFFERS_REQUEST,
});

export const OffersModel: Model<
  Data,
  {},
  OffersRequest |
  OffersResponse |
  EffectErrorMessage
> = {
  id: "offers",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      getOffers()
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });
      case OFFERS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
