/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { TinkProvidersResponse, TinkProvidersRequest } from "state/tink-providers";

import {
  TINK_PROVIDERS_INIT_REQUEST,
  TINK_PROVIDERS_INIT_RESPONSE,
  TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST,
  TINK_PROVIDERS_REMOVE_PROVIDER_RESPONSE,
} from "state/tink-providers";
import { tinkProviders, removeTinkProvider } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: TinkProvidersRequest) => {
      if (msg.tag === TINK_PROVIDERS_INIT_REQUEST) {
        const { tinkProviders: providers } = await client(tinkProviders);

        return ({
          tag: TINK_PROVIDERS_INIT_RESPONSE,
          data: providers,
        }: TinkProvidersResponse);
      }
    },
    subscribe: { [TINK_PROVIDERS_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: TinkProvidersRequest) => {
      if (msg.tag === TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST) {
        const { id } = msg;
        await client(removeTinkProvider, { id });

        const { tinkProviders: providers } = await client(tinkProviders);

        return ({
          tag: TINK_PROVIDERS_REMOVE_PROVIDER_RESPONSE,
          data: providers,
        }: TinkProvidersResponse);
      }
    },
    subscribe: { [TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST]: true },
  });
};

export default registerClient;
