/* @flow */

import type { TransferHistory } from "shop-state/types";
import React from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import Spinner from "components/Spinner";
import ChevronIcon from "icons/chevron-mini.svg";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { formatDate } from "helpers/utils";

import styles from "./styles.scss";

type TableSmallProps = {
  data: $ReadOnlyArray<TransferHistory>,
  current?: string | null,
  onClick: TransferHistory => void,
};

const TableSmall = ({ data, current, onClick }: TableSmallProps) => {
  const t = useTranslate();
  const { formatPoints } = useFormat(false);

  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <div className={styles.tbody}>
        {data.map((x, i) => (
          <React.Fragment key={`${i}-${x.date}`}>
            <div
              className={cn(styles.tr, { [styles.open]: current === x.date })}
              onClick={() => {
                onClick(x);
              }}
            >
              <div>{formatDate(x.date)}</div>
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === x.date} className={styles.expandable}>
              <table className={styles.table}>
                <tbody>
                  <tr>
                    <td>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.DATE")}</td>
                    <td>{formatDate(x.date)}</td>
                  </tr>
                  <tr>
                    <td>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.DISTRIBUTED")}</td>
                    <td>{formatPoints(x.sumpoints)}</td>
                  </tr>
                  <tr>
                    <td>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.RECIPIENTS")}</td>
                    <td>{x.countmembers}</td>
                  </tr>
                  <tr>
                    <td>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.COMMENT")}</td>
                    <td>{x.comment}</td>
                  </tr>
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const TableSmallHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
