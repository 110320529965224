/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { TinkTransactionsResponse, TinkTransactionsRequest } from "state/tink-transactions";

import {
  TINK_TRANSACTIONS_INIT_REQUEST,
  TINK_TRANSACTIONS_INIT_RESPONSE,
  TINK_TRANSACTIONS_FETCH_REQUEST,
  TINK_TRANSACTIONS_FETCH_RESPONSE } from "state/tink-transactions";
import { tinkTransactions as tinkTransactionsQuery } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  const fetchTransactions = async (params: { page: number, pageSize: number }) => {
    return client(tinkTransactionsQuery, params);
  };

  storage.addEffect({
    effect: async (msg: TinkTransactionsRequest) => {
      if (msg.tag === TINK_TRANSACTIONS_FETCH_REQUEST) {
        const { tinkTransactions } = await fetchTransactions(msg);

        return ({
          tag: TINK_TRANSACTIONS_FETCH_RESPONSE,
          data: tinkTransactions,
        }: TinkTransactionsResponse);
      }

      if (msg.tag === TINK_TRANSACTIONS_INIT_REQUEST) {
        const { tinkTransactions } = await fetchTransactions(msg);

        return ({
          tag: TINK_TRANSACTIONS_INIT_RESPONSE,
          data: tinkTransactions,
        }: TinkTransactionsResponse);
      }
    },
    subscribe: {
      [TINK_TRANSACTIONS_FETCH_REQUEST]: true,
      [TINK_TRANSACTIONS_INIT_REQUEST]: true,
    },
  });
};

export default registerClient;
