/* @flow */

import type { AffiliateCategory } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: $ReadOnlyArray<AffiliateCategory> }
  | { state: "ERROR", error: string };

export type AffiliateCategoriesResponse =
  Response<typeof AFFILIATE_CATEGORIES_RESPONSE, Array<AffiliateCategory>>;

export type AffiliateCategoriesRequest = {
  tag: typeof AFFILIATE_CATEGORIES_REQUEST,
};

export const AFFILIATE_CATEGORIES_RESPONSE: "response/affiliate_categories" = "response/affiliate_categories";
export const AFFILIATE_CATEGORIES_REQUEST: "request/affiliate_categories" = "request/affiliate_categories";

export const AffiliateCategoriesModel: Model<
  Data,
  {},
  AffiliateCategoriesRequest | AffiliateCategoriesResponse
> = {
  id: "affiliate_categories",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: AFFILIATE_CATEGORIES_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case AFFILIATE_CATEGORIES_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
