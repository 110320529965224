/* @flow */

import type { Transaction, TransactionFilter } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData, updateNone } from "crustate";
import format from "date-fns/format";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: $ReadOnlyArray<Transaction> }
  | { state: "UPDATING", data: $ReadOnlyArray<Transaction> }
  | { state: "ERROR", error: string };

type LoadTransactionsProps = {
  filter?: ?TransactionFilter,
  size?: number,
  from?: ?Date,
};

// Component -> State message-types
export type TransactionsStateRequest = {
  tag: typeof TRANSACTIONS_STATE_REQUEST,
  filter?: ?TransactionFilter,
  size: number,
  from?: ?string,
};

// State <-> Effect message-types
export type TransactionsEffectResponse =
  Response<typeof TRANSACTIONS_EFFECT_RESPONSE, ?$ReadOnlyArray<Transaction>>;

export type TransactionsEffectRequest = {
  tag: typeof TRANSACTIONS_EFFECT_REQUEST,
  filter?: ?TransactionFilter,
  size: number,
  from?: string,
};

const DEFAULT_PAGESIZE = 1000;
export const PREVIEW_PAGESIZE = 10;

// Component -> State messages
const TRANSACTIONS_STATE_REQUEST: "transactions/state/request" = "transactions/state/request";

// State <-> Effect messages
export const TRANSACTIONS_EFFECT_RESPONSE: "transactions/effect/response" = "transactions/effect/response";
export const TRANSACTIONS_EFFECT_REQUEST: "transactions/effect/request" = "transactions/effect/request";

export const loadTransactions = ({
  filter,
  size = DEFAULT_PAGESIZE,
  from }: LoadTransactionsProps): TransactionsStateRequest => {
  const f = from ? format(from, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx") : undefined;

  return {
    tag: TRANSACTIONS_STATE_REQUEST,
    filter,
    size,
    from: f,
  };
};

export const TransactionsModel: Model<Data, { size?: number, filter?: TransactionFilter },
  TransactionsEffectRequest |
  TransactionsEffectResponse |
  TransactionsStateRequest> = {
    id: "transactions",
    init: ({ size = DEFAULT_PAGESIZE, filter }) => {
      return updateData(
        { state: "LOADING" },
        { tag: TRANSACTIONS_EFFECT_REQUEST, size, filter }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case TRANSACTIONS_EFFECT_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          return updateNone();

        case TRANSACTIONS_STATE_REQUEST:
          if (state.state === "LOADED") {
            return updateData(
              { state: "UPDATING", data: state.data },
              { ...msg, tag: TRANSACTIONS_EFFECT_REQUEST }
            );
          }

          if (state.state === "ERROR") {
            return updateData(
              { state: "LOADING" },
              { ...msg, tag: TRANSACTIONS_EFFECT_REQUEST }
            );
          }

          return updateNone();

        default:
      }
    },
  };
