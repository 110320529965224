/* @flow */

import type { Node as ReactNode } from "react";
import type { Client, GraphQLResponse, QueryRunner } from "@awardit/graphql-ast-client";
import type { StoreInfo } from "shop-state/types";

import React, { useContext, createContext } from "react";

// FIXME: Use the cached version
type CachedGraphQLClient = Client<{}>;

export type ClientProviderProps = {
  children: ReactNode,
  value: CachedGraphQLClient,
};

export const StoreInfoContext = createContext<StoreInfo>({});

const ClientContext = createContext<CachedGraphQLClient | null>(null);

export const ClientProvider = ({ children, value }: ClientProviderProps) =>
  <ClientContext.Provider value={value}>{children}</ClientContext.Provider>;

export const useClient = (): Client<{}> => {
  const client = useContext(ClientContext);

  if (!client) {
    throw new Error("useClient(): Usage must be wrapped in a <ClientProvider />.");
  }

  return (client: any);
};

export const createRunQuery = (
  fetch: typeof fetch,
  prefix: string
): QueryRunner => request => fetch(`${prefix}/graphql`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(request),
}).then(handleFetchResponse);

export const handleFetchResponse = (
  response: Response
): Promise<GraphQLResponse<mixed>> => response.text()
  .then((bodyText: string): GraphQLResponse<mixed> => {
    try {
      // Attempt to parse all, ignore response.ok
      return JSON.parse(bodyText);
    }
    catch (e) {
      const error = new Error(`JSON Parse error: ${e && typeof e.getMessage === "function" ? e.getMessage() : e}: ${bodyText}`);

      (error: any).bodyText = bodyText;
      (error: any).response = response;
      (error: any).statusCode = response.status;

      throw error;
    }
  });

export const APP_KEY = "root";
