/* @flow */

import type { ExtendedProps } from "components/MemberGroupView";
import type { TransferableMember, TransferHistory } from "shop-state/types";

import React, { useState, useEffect } from "react";
import { useData, useSendMessage } from "crustate/react";
import { Helmet } from "react-helmet-async";
import Breadcrumbs from "components/Breadcrumbs";
import { syncCustomer } from "@crossroads/shop-state/customer";
import { useClient } from "entrypoint/shared";
import { listTransferableMembers, transferMemberPoints, customer as customerQuery, transferHistory } from "queries";
import { usePointsLabel } from "helpers/points";
import { addMessage } from "state/messages";
import Field from "components/Field";
import { BtnComponent } from "components/MemberGroupView";
import { Title } from "components/UiComponents";
import History from "components/MemberGroupView/TransferPoints/History";
import { Checkbox, Button } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { CustomerData } from "data";
import { Form } from "@awardit/formaggio";
import useFormat from "helpers/use-format";
import cn from "classnames";

import styles from "./styles.scss";

type FormState = {
  points: number,
  message: string,
};

const TransferPoints = ({ basePath, page, routes, content }: ExtendedProps): React$Node => {
  const client = useClient();
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { formatPoints } = useFormat(false);
  const label = usePointsLabel();
  const customer = useData(CustomerData);
  const [formState, setFormState] = useState<FormState>({});
  const [members, setMembers] = useState<Array<TransferableMember>>([]);
  const [selectedMembers, setSelectedMemberIDs] = useState<Array<string>>([]);
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [sharedPoints, setSharedPoints] = useState<number>(0);
  const [loading, setIsLoading] = useState<boolean>(false);
  const [history, setHistory] = useState<Array<TransferHistory>>([]);
  const [company, setCompany] = useState<TransferableMember>({});
  const { accountView } = routes;
  const { memberGroupView: { sharePageTitle, shareHeading, shareDescription } } = content;
  const accountViewTitle = accountView && accountView.title ? accountView.title : "";
  const accountViewLink = accountView && accountView.url ? accountView.url : "";
  const parentLink = { name: accountViewTitle, url: accountViewLink };
  const overBalance = customer.data ?
    sharedPoints * selectedMembers.length > customer.data.awardit.activePoints : null;

  const getHistory = async () => {
    await client(transferHistory, { max: 5 }).then(({ transferHistory }) => {
      if (transferHistory !== null && transferHistory.result === "SUCCESS") {
        setHistory(transferHistory.data);
      }
    });
  };

  const getMemberList = async () => {
    await client(listTransferableMembers).then(({ listTransferableMembers }) => {
      if (listTransferableMembers !== null && listTransferableMembers.result === "SUCCESS") {
        const foundCompany = listTransferableMembers.data.find(member => member.isCompany);
        if (foundCompany) {
          setCompany(foundCompany);
        }

        if (customer.data) {
          setMembers(listTransferableMembers.data.filter(member =>
            (member.memberuserid !== customer.data.awarditMemberId) && !member.isCompany
          ));
        }
      }
      else {
        sendMessage(addMessage("GET_TRANSFERABLE_MEMBERS_ERROR", "error"));
      }
    });
  };

  useEffect(() => {
    getMemberList();
    getHistory();
  }, [client]);

  const checkAllUsers = e => {
    setAllChecked(!allChecked);

    const usersIDs = members.map(user => user.memberuserid);

    setSelectedMemberIDs(e.target.checked ? [...usersIDs] : []);
  };

  const onCheckboxChange = (id: string) => {
    const memberInList = selectedMembers.includes(id);

    if (memberInList) {
      setSelectedMemberIDs(selectedMembers.filter(i => i !== id));
      if (allChecked) {
        setAllChecked(false);
      }
    }
    else {
      setSelectedMemberIDs([...selectedMembers, id]);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);

    if (customer.data) {
      const res = await client(transferMemberPoints, {
        fromMember: company.memberuserid,
        toMembers: selectedMembers,
        points: sharedPoints,
        message: formState.message ?? t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.DEFAULT_MESSAGE"),
      });

      if (res.transferMemberPoints !== null && res.transferMemberPoints.result === "SUCCESS") {
        await client(customerQuery).then(({ customer }) => {
          sendMessage(syncCustomer(customer));
          sendMessage(addMessage(`TRANSFER_MEMBER_POINTS_SUCCESS`, "success"));
          setFormState({});
          setAllChecked(false);
          setSelectedMemberIDs([]);
          setIsLoading(false);
          getMemberList();
          getHistory();
        });
      }
      else {
        sendMessage(addMessage("TRANSFER_MEMBER_POINTS_ERROR", "error"));
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet title={`${accountViewTitle} / ${sharePageTitle ?? ""}`} />
      <Breadcrumbs parentLink={parentLink} current={sharePageTitle ?? ""} />
      <Title>{shareHeading ?? ""}</Title>
      <div className={styles.description}>
        <p>{shareDescription ?? ""}</p>
        <BtnComponent basePath={basePath} page={page} routes={routes} content={content} />
        <h3>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.FORMHEADER", { pointsLabel: label().toLocaleLowerCase() })}</h3>
      </div>
      {customer.state === "LOGGED_IN" &&
      customer.data &&
      members.length > 0 &&
        <div className={styles.wrapper}>
          <Form
            className={styles.formblock}
            value={(formState: any)}
            onChange={x => {
              setFormState({ ...formState, ...(x: any) });
              setSharedPoints(isNaN(parseInt(x.points, 10)) ?
                0 : parseInt(x.points, 10));
            }}
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <>
              <div className={styles.checklistItemAll}>
                <Checkbox
                  value={allChecked}
                  name="all"
                  onChange={e => checkAllUsers(e)}
                >
                  {t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.CHOOSE_ALL")}
                </Checkbox>
              </div>

              <div className={styles.checklist}>
                {members.map((member, x) => (
                  <div key={x} className={styles.checklistItem}>
                    <Checkbox
                      className={styles.checkboxName}
                      value={selectedMembers.includes(member.memberuserid)}
                      name={member.firstname}
                      onChange={() => onCheckboxChange(member.memberuserid)}
                    >
                      {member.firstname + " " + member.lastname}
                    </Checkbox>
                  </div>
                ))}
              </div>

              <div className={styles.inputWrapper}>
                <Field
                  label={t("ACCOUNT.MEMBER_GROUP.POINTS", { pointsLabel: label() })}
                  type="number"
                  name="points"
                  className={styles.input}
                />
              </div>

              <Field
                label={t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.COMMENT")}
                name="message"
                className={styles.input}
              />
              <Button
                variant="primary"
                size="small"
                type="submit"
                disabled={
                  selectedMembers.length <= 0 ||
                  sharedPoints <= 0 ||
                  sharedPoints * selectedMembers.length > customer.data.awardit.groupPoints
                }
                loading={loading}
              >
                {t("COMMON.SEND")}
              </Button>
            </>
          </Form>

          <aside className={styles.sidebar}>
            <div className={styles.sidebarWrapper}>
              <div className={styles.pointlist}>
                <div className={styles.pointlist__item}>
                  <span>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.POINTS_AVAILABLE", { pointsLabel: label().toLocaleLowerCase() })}</span>
                  <span>{formatPoints(customer.data.awardit.groupPoints)}</span>
                </div>
                <div
                  className={cn(
                    styles.pointlist__item,
                    { [styles.pointlist__itemError]: overBalance }
                  )}
                >
                  <span>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.POINTS_HANDED_OUT", { pointsLabel: label() })}</span>
                  <span>{formatPoints(sharedPoints * selectedMembers.length)}</span>
                </div>
                <div
                  className={cn(
                    styles.pointlist__item,
                    { [styles.pointlist__itemError]: overBalance }
                  )}
                >
                  <span>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.POINTS_LEFT", { pointsLabel: label() })}</span>
                  <span>
                    {formatPoints(customer.data.awardit.groupPoints -
                      (sharedPoints * selectedMembers.length))}
                  </span>
                </div>
              </div>
            </div>
          </aside>
        </div>
      }
      <History history={history} />
    </>
  );
};

export default TransferPoints;
