/* @flow */

import React, { useState, useContext, useEffect, useRef } from "react";
import cn from "classnames";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Form, rules, isRequired, isEmail } from "@awardit/formaggio";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { setMode, MODE } from "state/view-mode";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { ViewModeData } from "data";
import Field, { TextAreaField } from "components/Field";
import { useHideSubMenu } from "components/AppHeader/Large/SubMenu";
import Button from "components/Button";
import { addMessageTranslated } from "state/messages";
import { recommendMember } from "queries";
import ChevronIcon from "icons/chevron-mini.svg";
import CloseIcon from "icons/close-large.svg";
import CheckmarkIcon from "icons/check-small.svg";

import styles from "./styles.scss";

type ModalProps = {
  overlayConfig: {
    color: string,
    opacity: number,
  },
};

type PropsModalInner = {
  overlayConfig: {
    color: string,
    opacity: number,
  },
  close: () => void,
};

const RecruitAFriend = () => {
  const sendMessage = useSendMessage();
  const { content: { accountview } } = useContext(StoreInfoContext);

  const onClick = () => {
    sendMessage(setMode(MODE.RECRUIT));
  };

  return (
    <div className={styles.block}>
      <header className={styles.header}>
        {accountview.recruitAFriendBlockTitle &&
          <h2 className={styles.heading}>{accountview.recruitAFriendBlockTitle}</h2>
        }
        <Button onClick={onClick}>
          {accountview.recruitAFriendBlockButton &&
            <span>{accountview.recruitAFriendBlockButton}</span>
          }
          <ChevronIcon />
        </Button>
      </header>
      {accountview.recruitAFriendBlockText &&
        <section className={styles.body}>
          <p>{accountview.recruitAFriendBlockText}</p>
        </section>
      }
    </div>
  );
};

export const RecruitAFriendModal = ({ overlayConfig }: ModalProps) => {
  const viewMode = useData(ViewModeData);
  const sendMessage = useSendMessage();

  if (viewMode !== MODE.RECRUIT) {
    return null;
  }

  return (
    <RecruitAFriendModalInner
      overlayConfig={overlayConfig}
      close={() => {
        sendMessage(setMode(MODE.NORMAL));
      }}
    />
  );
};

const RecruitAFriendModalInner = ({
  close,
  overlayConfig }: PropsModalInner) => {
  const [step, setStep] = useState<number>(0);
  const containerNode = useRef();
  const hideSubMenu = useHideSubMenu();

  const setClosed = () => {
    close();
    setStep(0);
  };

  useEffect(() => {
    const node = containerNode;

    if (node.current) {
      disableBodyScroll(node.current);
    }

    return () => {
      if (node.current) {
        enableBodyScroll(node.current);
      }
    };
  }, []);

  return (
    <div
      style={{ "--overlayColor": overlayConfig.color, "--overlayOpacity": overlayConfig.opacity }}
      className={cn(
        styles.recruitAFriendModal,
        { [styles.subMenuHidden]: hideSubMenu }
      )}
    >
      <div className={styles.overlay} onClick={setClosed} />
      <div ref={containerNode} className={styles.container}>
        <Button className={styles.closeButton} onClick={setClosed}>
          <CloseIcon />
        </Button>
        <div className={styles.body}>
          {step === 0 && <RecruitAFriendModalForm onSuccess={() => setStep(1)} />}
          {step === 1 && <RecruitAFriendModalSuccess />}
        </div>
      </div>
    </div>
  );
};

const RecruitAFriendModalSuccess = () => {
  const { content: { accountview } } = useContext(StoreInfoContext);

  return (
    <>
      {accountview.recruitAFriendSuccessTitle &&
        <h1>{accountview.recruitAFriendSuccessTitle}</h1>
      }
      {accountview.recruitAFriendSuccessSubTitle &&
        <p>{accountview.recruitAFriendSuccessSubTitle}</p>
      }
      <div className={styles.checkmark}>
        <div className={styles.checkmarkIcon}>
          <CheckmarkIcon />
        </div>
      </div>
    </>
  );
};

const validation = rules([
  isRequired("name"),
  isEmail("email"),
]);

const RecruitAFriendModalForm = ({ onSuccess }: { onSuccess: () => void }) => {
  const t = useTranslate();
  const [formState, setFormState] = useState({ name: "", email: "", phone: "", message: "" });
  const [processing, setProcessing] = useState(false);
  const sendMessage = useSendMessage();
  const client = useClient();
  const { content: { accountview } } = useContext(StoreInfoContext);

  const submit = async e => {
    e.preventDefault();

    setProcessing(true);

    try {
      const { recommendMember: { result, message } } =
         await client(recommendMember, { ...formState });

      if (result !== "SUCCESS") {
        sendMessage(addMessageTranslated(message ?? "", "error"));
      }
      else {
        onSuccess();
      }

      setProcessing(false);
    }
    catch {
      setProcessing(false);
    }
  };

  const errors = validation(formState);

  return (
    <>
      <header>
        {accountview.recruitAFriendFormTitle &&
          <h1>{accountview.recruitAFriendFormTitle}</h1>
        }
        {accountview.recruitAFriendFormSubTitle &&
          <p>{accountview.recruitAFriendFormSubTitle}</p>
        }
      </header>
      <Form
        className={styles.recruitAFriendModalForm}
        disabled={processing}
        errors={errors}
        value={formState}
        onChange={(x: any) => {
          setFormState({ ...formState, ...x });
        }}
        onSubmit={submit}
      >
        <Field name="name" label={t("RECRUIT_A_FRIEND.LABEL_NAME")} />
        <Field name="email" label={t("RECRUIT_A_FRIEND.LABEL_EMAIL")} />
        <Field name="phone" label={t("RECRUIT_A_FRIEND.LABEL_PHONE")} />
        <TextAreaField name="message" label={t("RECRUIT_A_FRIEND.LABEL_MESSAGE")} />
        {accountview.recruitAFriendFormButton &&
          <Button variant="primary" type="submit">
            {accountview.recruitAFriendFormButton}
          </Button>
        }
      </Form>
    </>
  );
};

export default RecruitAFriend;
