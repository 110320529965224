/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import {
  PHOTO_SERVICE_REQUEST,
  PHOTO_SERVICE_RESPONSE,
} from "state/photo-service";

import { photoServiceInfo } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          photoServiceInfo
        );

        return ({
          tag: PHOTO_SERVICE_RESPONSE,
          data: data.photoServiceInfo,
        });
      }
      catch (e) {
        return ({
          tag: PHOTO_SERVICE_RESPONSE,
          error: "Error in photo service effect",
        });
      }
    },
    subscribe: { [PHOTO_SERVICE_REQUEST]: true },
  });
};

export default registerClient;
