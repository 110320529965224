/* @flow */

import type { TransferHistory } from "shop-state/types";

import React, { useState } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import TableLarge, { TableLargeHint } from "./TableLarge";
import TableSmall, { TableSmallHint } from "./TableSmall";

import styles from "./styles.scss";

type Props = {
  history: Array<TransferHistory>,
};

const History = ({ history }: Props): React$Node => {
  const t = useTranslate();
  const [current, setCurrent] = useState<string | null>(null);

  return (
    <div className={styles.history}>
      <h3 className={styles.historyHeader}>{t("ACCOUNT.MEMBER_GROUP.TRANSFER_POINTS.HISTORY.HEADER")}</h3>
      {history.length === 0 &&
        <>
          <TableSmallHint />
          <TableLargeHint />
        </>
      }
      <TableLarge data={history} />
      <TableSmall
        current={current}
        data={history}
        onClick={x => setCurrent(current !== x.date ? x.date : null)}
      />
    </div>
  );
};

export default History;
