/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  CurrentPageInfoResponse as Response,
  CurrentPageInfoRequest as Request,
} from "state/current-page-info";

import { CURRENT_PAGE_INFO_REQUEST, CURRENT_PAGE_INFO_RESPONSE } from "state/current-page-info";
import { infoBlock } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: Request) => {
      try {
        const { info } = await client(infoBlock, {
          page: msg.page,
          prio: msg.prio,
        }, { cache: true });

        if (info.result !== "SUCCESS") {
          return ({
            tag: CURRENT_PAGE_INFO_RESPONSE,
            data: null,
            error: "ERROR",
          }: Response);
        }

        return ({
          tag: CURRENT_PAGE_INFO_RESPONSE,
          data: info.data,
          page: msg.page,
        }: Response);
      }
      catch (e) {
        return ({
          tag: CURRENT_PAGE_INFO_RESPONSE,
          data: null,
          error: "ERROR",
        }: Response);
      }
    },
    subscribe: { [CURRENT_PAGE_INFO_REQUEST]: true },
  });
};

export default registerClient;
