/* @flow */

import type { TinkProviders } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "IDLE" }
  | { state: "LOADING" }
  | { state: "LOADED", data: TinkProviders }
  | { state: "FETCHING", data: TinkProviders }
  | { state: "REMOVING", data: TinkProviders }
  | { state: "ERROR", error: mixed };

export type TinkProvidersResponse =
  Response<typeof TINK_PROVIDERS_INIT_RESPONSE, TinkProviders>
  | Response<typeof TINK_PROVIDERS_FETCH_RESPONSE, TinkProviders>
  | Response<typeof TINK_PROVIDERS_REMOVE_PROVIDER_RESPONSE, TinkProviders>;

export type TinkProvidersRequest = {
  tag: typeof TINK_PROVIDERS_INIT_REQUEST,
} | {
  tag: typeof TINK_PROVIDERS_FETCH_REQUEST,
} | {
  tag: typeof TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST,
  id: number,
};

export const TINK_PROVIDERS_INIT_REQUEST: "request/init/tink_providers" = "request/init/tink_providers";
export const TINK_PROVIDERS_INIT_RESPONSE: "response/init/tink_providers" = "response/init/tink_providers";
export const TINK_PROVIDERS_FETCH_REQUEST: "request/fetch/tink_providers" = "request/fetch/tink_providers";
export const TINK_PROVIDERS_FETCH_RESPONSE: "response/fetch/tink_providers" = "response/fetch/tink_providers";
export const TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST: "request/remove_provider/tink_providers" = "request/remove_provider/tink_providers";
export const TINK_PROVIDERS_REMOVE_PROVIDER_RESPONSE: "response/remove_provider/tink_providers" = "response/remove_provider/tink_providers";

export const load = () => ({
  tag: TINK_PROVIDERS_FETCH_REQUEST,
});

export const removeTinkProvider = (id: number) => ({
  tag: TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST,
  id,
});

export const TinkProvidersModel: Model<
  Data, { load: boolean }, TinkProvidersRequest | TinkProvidersResponse | EffectErrorMessage
> = {
  id: "tink_providers",
  init: ({ load }) => {
    if (load) {
      return updateData(
        { state: "LOADING" },
        { tag: TINK_PROVIDERS_INIT_REQUEST }
      );
    }

    return updateData({ state: "IDLE" });
  },
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });

      case TINK_PROVIDERS_INIT_RESPONSE:
        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      case TINK_PROVIDERS_FETCH_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            { state: "FETCHING", data: state.data },
            { tag: TINK_PROVIDERS_FETCH_REQUEST }
          );
        }

        break;

      case TINK_PROVIDERS_FETCH_RESPONSE:
        if (state.state === "FETCHING") {
          if (msg.data) {
            return updateData({ state: "LOADED", data: state.data });
          }
        }

        break;

      case TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST:
        if (state.state === "LOADED") {
          return updateData(
            { state: "REMOVING", data: state.data },
            { tag: TINK_PROVIDERS_REMOVE_PROVIDER_REQUEST, id: msg.id }
          );
        }

        break;

      case TINK_PROVIDERS_REMOVE_PROVIDER_RESPONSE:
        if (state.state === "REMOVING" && msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
