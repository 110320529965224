/* @flow */

import type { AdditionalMemberNumber } from "shop-state/types";

import React, { useContext, useEffect, useState } from "react";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import { Helmet } from "react-helmet-async";
import Back from "components/AccountView/Back";
import Header from "components/AccountView/Header";
import { additionalMemberNumbers } from "queries";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const AdditionalMemberNumbersView = () => {
  const { content: { accountview } } = useContext(StoreInfoContext);
  const client = useClient();
  const [memberNumberData, setMemberNumberData] = useState<Array<AdditionalMemberNumber>>([]);
  const t = useTranslate();

  useEffect(() => {
    client(additionalMemberNumbers).then(({ additionalMemberNumbers }) => {
      if (additionalMemberNumbers.length > 0) {
        setMemberNumberData(additionalMemberNumbers);
      }
    });
  }, []);

  return (
    <Wrapper variant="pageWrapper" className="awardit-pageWrapper">
      <Helmet title={accountview.additionalMemberNumbersHeading} />
      <Back />
      {accountview.additionalMemberNumbersHeading &&
        <Header subHeader={accountview.additionalMemberNumbersHeading} />
      }
      {memberNumberData.length > 0 &&
        <div className={styles.table}>
          <table>
            <thead>
              <tr>
                <th>{t("ADDITIONAL_MEMBER_NUMBERS.TABLE.NAME")}</th>
                <th>{t("ADDITIONAL_MEMBER_NUMBERS.TABLE.NUMBER")}</th>
              </tr>
            </thead>
            <tbody>
              {memberNumberData.map(x => (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  <td>{x.id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      }
    </Wrapper>
  );
};

export default AdditionalMemberNumbersView;
