/* @flow */

import type { LeaderboardData, leaderboardDistrictData } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "INIT", data: LeaderboardData }
  | { state: "UPDATING", data: LeaderboardData }
  | { state: "LOADED", data: LeaderboardData }
  | { state: "ERROR", error: string };

  type DistrictsData =
  | { state: "LOADING" }
  | { state: "INIT", data: ?Array<leaderboardDistrictData> }
  | { state: "UPDATING", data: ?Array<leaderboardDistrictData> }
  | { state: "LOADED", data: Array<leaderboardDistrictData> }
  | { state: "ERROR", error: string };

// Component -> State message-types
export type LeaderboardStateRequest = {
  tag: typeof LEADERBOARD_STATE_REQUEST,
  district?: string,
  fromdate?: string,
  todate?: string,
  pagesize?: number,
};

export type LeaderboardStateProps = {
  district?: string,
  fromdate?: string,
  todate?: string,
};

export type LeaderboardDistrictsStateRequest = {
  tag: typeof LEADERBOARD_DISTRICTS_STATE_REQUEST,
};
// State <-> Effect message-types
export type LeaderboardDistrictsResponse =
  Response<typeof LEADERBOARD_DISTRICTS_RESPONSE, ?Array<leaderboardDistrictData>>;

export type LeaderboardDistrictsEffectRequest = {
  tag: typeof LEADERBOARD_DISTRICTS_REQUEST,
  type: string,
};
export type LeaderboardResponse =
  Response<typeof LEADERBOARD_RESPONSE, ?LeaderboardData>;

export type LeaderboardEffectRequest = {
  tag: typeof LEADERBOARD_REQUEST,
  type: string,
};

// Component -> State messages
const LEADERBOARD_STATE_REQUEST: "leaderboard/state/request" = "leaderboard/state/request";
const LEADERBOARD_DISTRICTS_STATE_REQUEST: "leaderboardDistricts/state/request" = "leaderboardDistricts/state/request";

// State <-> Effect messages
export const LEADERBOARD_RESPONSE: "leaderboard/effect/response" = "leaderboard/effect/response";
export const LEADERBOARD_REQUEST: "leaderboard/effect/request" = "leaderboard/effect/request";

export const LEADERBOARD_DISTRICTS_RESPONSE: "leaderboardDistricts/effect/response" = "leaderboardDistricts/effect/response";
export const LEADERBOARD_DISTRICTS_REQUEST: "leaderboardDistricts/effect/request" = "leaderboardDistricts/effect/request";

export const getLoadLeaderboard =
(): LeaderboardStateRequest => {
  return {
    tag: LEADERBOARD_STATE_REQUEST,
  };
};

export const getLoadLeaderboardByDate =
({ fromdate, todate, district }: LeaderboardStateProps): LeaderboardStateRequest => {
  return {
    tag: LEADERBOARD_STATE_REQUEST,
    fromdate,
    todate,
    district,
  };
};

export const getLoadLeaderboardDistricts =
(): LeaderboardDistrictsStateRequest => {
  return {
    tag: LEADERBOARD_DISTRICTS_STATE_REQUEST,
  };
};

export const LeaderboardModel: Model<Data, {
  fromdate?: string,
  todate?: string,
  district?: number,
  pagesize?: number,
},
  LeaderboardEffectRequest |
  LeaderboardResponse |
  LeaderboardStateRequest> = {
    id: "leaderboard",
    init: ({ fromdate, todate, district, pagesize }) => {
      return updateData(
        { state: "INIT", data: {} },
        { tag: LEADERBOARD_REQUEST, fromdate, todate, district, pagesize }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case LEADERBOARD_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;

        case LEADERBOARD_STATE_REQUEST:
          if (state.state === "LOADED" || state.state === "INIT") {
            return updateData(
              { state: "UPDATING", data: state.data },
              { ...msg, tag: LEADERBOARD_REQUEST }
            );
          }

          break;
        default:
      }
    },
  };

export const LeaderboardDistrictsModel: Model<DistrictsData, {},
  LeaderboardDistrictsEffectRequest |
  LeaderboardDistrictsResponse |
  LeaderboardDistrictsStateRequest> = {
    id: "leaderboard_districs",
    init: () => {
      return updateData(
        { state: "INIT", data: [] },
        { tag: LEADERBOARD_DISTRICTS_REQUEST }
      );
    },
    update: (state, msg) => {
      switch (msg.tag) {
        case LEADERBOARD_DISTRICTS_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: msg.data });
          }

          break;

        case LEADERBOARD_DISTRICTS_STATE_REQUEST:
          if (state.state === "LOADED" || state.state === "INIT") {
            return updateData(
              { state: "UPDATING", data: state.data },
              { ...msg, tag: LEADERBOARD_DISTRICTS_REQUEST }
            );
          }

          break;
        default:
      }
    },
  };
