/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  AllFormsEffectResponse,
  FormEffectResponse,
  FormEffectRequest,
  FormRowsEffectResponse,
  FormRowsEffectRequest,
  FormSubmitsEffectResponse,
  FormSubmitsEffectRequest,
} from "state/forms";

import {
  ALL_FORMS_REQUEST,
  ALL_FORMS_RESPONSE,
  FORM_REQUEST,
  FORM_RESPONSE,
  FORM_ROWS_REQUEST,
  FORM_ROWS_RESPONSE,
  FORM_SUBMITS_REQUEST,
  FORM_SUBMITS_RESPONSE,
} from "state/forms";

import { formRows, allForms, form, formSubmits } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: FormRowsEffectRequest) => {
      try {
        const data = await client(
          formRows,
          { id: msg.id }
        );

        return ({
          tag: FORM_ROWS_RESPONSE,
          data: data.formRows,
        }: FormRowsEffectResponse);
      }
      catch (e) {
        return ({
          tag: FORM_ROWS_RESPONSE,
          error: "Error in formrows effect",
        }: FormRowsEffectResponse);
      }
    },
    subscribe: { [FORM_ROWS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          allForms
        );

        return ({
          tag: ALL_FORMS_RESPONSE,
          data: data.allForms,
        }: AllFormsEffectResponse);
      }
      catch (e) {
        return ({
          tag: ALL_FORMS_RESPONSE,
          error: "Error in forms effect",
        }: AllFormsEffectResponse);
      }
    },
    subscribe: { [ALL_FORMS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: FormEffectRequest) => {
      try {
        const data = await client(
          form,
          { id: msg.id }
        );

        return ({
          tag: FORM_RESPONSE,
          data: data.form,
        }: FormEffectResponse);
      }
      catch (e) {
        return ({
          tag: FORM_RESPONSE,
          error: "Error in forms effect",
        }: FormEffectResponse);
      }
    },
    subscribe: { [FORM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: FormSubmitsEffectRequest) => {
      try {
        const data = await client(
          formSubmits,
          { id: msg.id }
        );

        return ({
          tag: FORM_SUBMITS_RESPONSE,
          data: data.formSubmits,
        }: FormSubmitsEffectResponse);
      }
      catch (e) {
        return ({
          tag: FORM_SUBMITS_RESPONSE,
          error: "Error in forms effect",
        }: FormSubmitsEffectResponse);
      }
    },
    subscribe: { [FORM_SUBMITS_REQUEST]: true },
  });
};

export default registerClient;
