/* @flow */

import type { Quote, QuoteItem, QuoteItemConfigurable } from "shop-state/types";

import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useSendMessage } from "crustate/react";
import cn from "classnames";
import { AnalyticsContext } from "@crossroads/analytics";
import { removeQuoteItem, updateQuoteItemQty } from "@crossroads/shop-state/quote";
import PriceSplit from "components/PriceSplit";
import QtyPicker from "components/QtyPicker";
import { Box } from "components/Box";
import { pointsPriceByID } from "helpers/points";
import { getEstimatedDeliveryText } from "helpers/utils";

import styles from "./styles.scss";

type Props = {
  item: QuoteItem | QuoteItemConfigurable,
  quoteData: { +state: string, +data: Quote },
  processingItem: null | string,
  sendGAEvent: (any, number, any) => void,
};

const CartItem = ({ item, sendGAEvent, processingItem, quoteData }: Props): React$Node => {
  const product = item.configOption ? {
    categories: item.product.categories,
    ...item.product,
    ...item.configOption.product,
  } : item.product;

  const pointPayment = pointsPriceByID(item.availablePointPayments, "awardit");
  const gaContext = useContext(AnalyticsContext);
  const sendMessage = useSendMessage();

  const remove = () => {
    gaContext.registerModifyCart(item.product, "remove_from_cart");
    sendMessage(removeQuoteItem(item.itemBuyRequest));
  };

  return (
    <Box
      key={item.itemBuyRequest}
      className={
        cn(styles.item, {
          [styles.processing]: processingItem === item.itemBuyRequest,
          [styles.disabled]: quoteData.state === "UPDATING_ITEM",
        })}
    >
      <img
        className={styles.image}
        alt={product.name}
        src={product.attributes.image?.x1}
      />

      <div className={styles.contentWrapper}>
        <div className={styles.left}>
          <div className={styles.info}>
            <Link
              to={{
                pathname: product.url,
                state: { hint: {
                  type: "product",
                  product,
                  image: product.attributes.image?.x1,
                } },
              }}
              className={styles.name}
            >
              {product.name}
            </Link>
            <p className={styles.brand}>{product.attributes.manufacturer}</p>
            <span className={styles.price}>
              <PriceSplit
                className={styles.price}
                pointsPrice={pointPayment}
                price={product.price}
              />
            </span>
            {item.bundleOptions &&
              <ul className={styles.bundleOptions}>
                {item.bundleOptions.map(o => o.products.map(p =>
                  <li key={o.title}>{p.product.name}</li>
                ))}
              </ul>
            }

          </div>
          <img
            className={cn(styles.image, styles.mobileImg)}
            alt={product.name}
            src={product.attributes.image?.x1}
          />
        </div>

        <div className={styles.right}>
          <QtyPicker
            className={styles.qtyPicker}
            value={item.qty}
            min={1}
            remove={remove}
            setValue={(v: number) => {
              sendMessage(updateQuoteItemQty(item.itemBuyRequest, v));
              const type = v > item.qty ? "add_to_cart" : "remove_from_cart";
              sendGAEvent(product, Math.abs(v - item.qty), type);
            }}
          />
          <span className={styles.deliveryTime}>
            {getEstimatedDeliveryText(product)}
          </span>
        </div>
      </div>
    </Box>
  );
};

export default CartItem;
