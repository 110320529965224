/* @flow */

import type { SortableProductList } from "shop-state/types";

import React, { memo, useMemo } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import useDevice from "helpers/use-device";
import { useData } from "crustate/react";
import { CustomerData } from "data";
import { parseParams, stringifyParams } from "helpers/location-search-string";
import ProductList, { ListItem } from "components/ProductList";
import { Pagination } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  updating: boolean,
  numPages: number,
  page: number,
  productList: SortableProductList,
  category?: string,
};

type PaginationButtonT = {
  page: number,
  active: boolean,
  children: React$Node,
};

const PaginatedProductList = memo<Props>(({
  updating, page, numPages,
  productList,
  className,
  category,
}: Props) => {
  const t = useTranslate();
  const location = useLocation();
  const params = useMemo(() => parseParams(location.search), [location.search]);
  const isDesktop = useDevice("gt", 801);
  const customer = useData(CustomerData);
  const memberTargetList = customer.state === "LOGGED_IN" &&
  customer.data &&
  customer.data.memberTargetList &&
  customer.data.memberTargetList.list.length > 0 ?
    customer.data.memberTargetList.list :
    null;

  if (updating) {
    return (
      <div className={className}>
        <ProductList products={[null, null, null, null, null, null, null, null]} />
      </div>
    );
  }

  const PaginationButton = ({ active, children, page }: PaginationButtonT) => {
    const newSearch = {
      ...params,
      page: String(page),
    };

    return (
      <Link
        disabled={active}
        className={
          cn(styles.paginationButton, { [styles.active]: active })
        }
        to={stringifyParams(newSearch)}
      >
        {children}
      </Link>
    );
  };

  return (
    <div className={className}>
      {productList.items.length > 0 ?
        <div>
          <ProductList>
            {productList.items
              .filter(p => !memberTargetList ||
              memberTargetList.includes(p.attributes.awarditTargetId) ||
              !p.attributes.awarditTargetId)
              .map((p, i) => (
                <ListItem
                  key={`p${p.sku}`}
                  loading={false}
                  product={p}
                  listName={category}
                  idx={i}
                />
              ))}
          </ProductList>

          {numPages > 1 &&
            <div className={cn("awardit-pagination", styles.paginationWrapper)}>
              <Pagination
                className={styles.pagination}
                currentPage={page}
                pageCount={numPages}
                ButtonComponent={PaginationButton}
                maxButtonCount={isDesktop ? 10 : 5}
              />
            </div>
          }
        </div> :
        <p className={styles.empty}>{t("CATEGORY.EMPTY")}</p>
      }
    </div>
  );
});

export default PaginatedProductList;
