/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { useData } from "crustate/react";
import { QuizHistoryRowsData, QuizData, QuizHistoryData } from "data";
import useFormat from "helpers/use-format";
import Options from "./options";
import Back from "components/QuizHistoryView/Back";

import LoadingView from "components/LoadingView";
import { Wrapper } from "@crossroads/ui-components";
import cn from "classnames";

import styles from "./styles.scss";

const QuizView = () => {
  const t = useTranslate();
  const quizData = useData(QuizData);
  const quizHistoryRowsData = useData(QuizHistoryRowsData);
  const quizHistoryData = useData(QuizHistoryData);
  const { formatPoints } = useFormat();

  return (
    <div className={styles.block}>
      {quizData.state === "LOADED" &&
      quizHistoryData.state === "LOADED" &&
      quizHistoryRowsData.state === "LOADED" &&
      quizData.data &&
      quizHistoryRowsData.data ?
        <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
          <div className={styles.quizHeader}>
            <h2>{quizHistoryData.data.offername}</h2>
            <Back text={t("QUIZ.HISTORY_BACK")} />
          </div>
          {quizHistoryRowsData.data.length > 0 &&
          quizData.data.length > 0 ?
            quizData.data.map((item, i) => (
              <div key={item.nr} className={styles.quizContainer}>
                <div className={styles.quizQuestion}>
                  {/* eslint-disable react/no-danger */}
                  <div
                    dangerouslySetInnerHTML={{ __html: item.name }}
                    className={styles.question} />
                  {/* eslint-enable react/no-danger */}
                  {(item.points > 0) && (
                    <p>{quizHistoryRowsData.data[i].points} / {formatPoints(item.points)}</p>
                  )}
                </div>
                <div className={styles.answers}>
                  <Options
                    userAnswers={quizHistoryRowsData.data}
                    question={item}
                  />
                </div>
              </div>
            )) :
            null}
        </Wrapper> :
        <LoadingView />
      }

    </div>
  );
};

export default QuizView;
