/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import type {
  VerifyListEffectRequest,
  VerifyListResponse,
} from "state/verify-list";

import {
  VERIFYLIST_REQUEST,
  VERIFYLIST_RESPONSE } from "state/verify-list";
import { getVerifyList } from "queries";

export const PAGE_SIZE = 32;

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: VerifyListEffectRequest) => {
      try {
        if (msg.district) {
          const data = await client(getVerifyList,
            {
              district: msg.district,
              pageNumber: msg.pageNumber,
              pageLimit: msg.pageLimit,
            },
            { cache: true });

          return ({
            tag: VERIFYLIST_RESPONSE,
            data: data && data.getVerifyList,
          }: VerifyListResponse);
        }
      }
      catch (e) {
        return ({
          tag: VERIFYLIST_RESPONSE,
          data: null,
          error: "ERROR",
        });
      }
    },
    subscribe: { [VERIFYLIST_REQUEST]: true },
  });
};

export default registerClient;
