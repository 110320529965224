/* @flow */

import type { FormState } from "components/CheckoutView/Step1";

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { default as AddressComponent, ContactInfo } from "components/Address";
import { Foldable } from "@crossroads/ui-components";
import Button from "components/Button";
import { Box, BoxHeader, BoxBody } from "components/Box";

import styles from "./styles.scss";

type Props = {
  state: FormState,
  editing: boolean,
  setEditing: boolean => void,
  setCustomerCountry: string => void,
};

const Addresses = ({ state, editing, setEditing, setCustomerCountry }: Props) => {
  const t = useTranslate();

  const onClickToggle = (e: MouseEvent) => {
    e.preventDefault();
    setEditing(!editing);
  };

  const { billing } = state;

  return (
    <div>
      <Box>
        <BoxHeader>
          <h2>{t("CHECKOUT.ADDRESSES_TITLE")}</h2>
          <Button className="link" size="small" onClick={onClickToggle}>
            {editing ? t("CHECKOUT.ADDRESS_MINIMIZE") : t("CHECKOUT.EDIT_ADDRESS")}
          </Button>
        </BoxHeader>
        <Foldable open={!editing}>
          <BoxBody className={styles.preview}>
            <div>
              <h2 className={styles.minimizedName}>{billing.firstname} {billing.lastname}</h2>
              <p className={styles.minimizedInfo}>
                {Object.values(billing.street).join(", ")}
                <br />
                {billing.postcode} {billing.city}
                <br />
                {billing.telephone}
                <br />
                {state.email}
              </p>
            </div>
          </BoxBody>
        </Foldable>
        <Foldable open={editing}>
          <BoxBody className={styles.editing}>
            <div className={styles.row}>
              <ContactInfo type="billing" />
            </div>
            <div className={styles.row}>
              <h2>{t("CHECKOUT.ADDRESS.ADDRESS")}</h2>
              <AddressComponent
                setCustomerCountry={(country: string) => {
                  setCustomerCountry(country);
                }}
                type="billing"
              />
            </div>
          </BoxBody>
        </Foldable>
      </Box>
    </div>
  );
};

export default Addresses;
