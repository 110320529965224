/* @flow */

import type { CurrentInfoListItem, CurrentInfoListPageType } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";

import { updateData, updateNone } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Array<CurrentInfoListItem> }
  | { state: "ERROR", error: string };

export type CurrentInfoListResponse = Response<
  typeof CURRENT_INFO_LIST_RESPONSE,
  Array<CurrentInfoListItem>
>;

export type CurrentInfoListRequest = {
  tag: typeof CURRENT_INFO_LIST_REQUEST,
  page: CurrentInfoListPageType,
  tags?: Array<string>,
  prio?: string,
};

export const CURRENT_INFO_LIST_RESPONSE: "response/current_info_list" = "response/current_info_list";
export const CURRENT_INFO_LIST_REQUEST: "request/current_info_list" = "request/current_info_list";

export const getCurrentInfoList = (
  page: CurrentInfoListPageType,
  tags?: Array<string>,
  prio?: string
) => {
  return { tag: CURRENT_INFO_LIST_REQUEST, page, tags, prio };
};

export const CurrentInfoListModel: Model<Data, {
  page: CurrentInfoListPageType,
  tags?: Array<string | any>,
  prio?: string,
},
  CurrentInfoListRequest |
  CurrentInfoListResponse
> = {
  id: "current_info_list",
  init: ({ page, tags, prio }: {
    page: CurrentInfoListPageType,
    tags?: Array<string | any>,
    prio?: string,
  }) =>
    updateData(
      { state: "LOADING" },
      getCurrentInfoList(page, tags, prio)
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case CURRENT_INFO_LIST_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        return updateNone();
      default:
    }
  },
};

