/* @flow */

import { setMode, MODE } from "state/view-mode";
import { ViewModeData } from "data";
import { useData, useSendMessage } from "crustate/react";

type OpenFiltermenu = {
  isOpen: boolean,
  openFiltermenu: () => void,
  closeFiltermenu: () => void,
};

export const useOpenFiltermenu = (): OpenFiltermenu => {
  const viewMode = useData(ViewModeData);
  const sendMessage = useSendMessage();

  const openFiltermenu = () => {
    sendMessage(setMode(MODE.FILTER));
  };

  const closeFiltermenu = () => {
    sendMessage(setMode(MODE.NORMAL));
  };

  return {
    isOpen: viewMode === MODE.FILTER,
    openFiltermenu,
    closeFiltermenu,
  };
};
