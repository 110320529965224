/* @flow */

import type { PhotoServiceInfo } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: ?Array<PhotoServiceInfo> }
  | { state: "LOADED", data: Array<PhotoServiceInfo> }
  | { state: "ERROR", error: mixed };

export type PhotoServiceResponse =
  Response<typeof PHOTO_SERVICE_RESPONSE, Array<PhotoServiceInfo>>;

export type PhotoServiceRequest = {
    tag: typeof PHOTO_SERVICE_REQUEST,
};

export const PHOTO_SERVICE_REQUEST: "request/photo_service" = "request/photo_service";
export const PHOTO_SERVICE_RESPONSE: "response/photo_service" = "response/photo_service";

export const PhotoServiceModel: Model<
  Data, {}, PhotoServiceResponse | EffectErrorMessage
> = {
  id: "photo_service",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: PHOTO_SERVICE_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case PHOTO_SERVICE_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
