/* @flow */

import type { ProductCardProduct } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

export type Redeem = {
  featuredProducts: {
    category: {
      name: string,
      url: string,
      products: {
        items: Array<ProductCardProduct>,
      },
    },
  },
};

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: Redeem }
  | { state: "ERROR", error: string };

export type RedeemResponse = Response<typeof REDEEM_RESPONSE, ?Redeem>;

export type RedeemRequest = {
  tag: typeof REDEEM_REQUEST,
  type: "redeem",
};

export const REDEEM_RESPONSE: "response/redeem" = "response/redeem";
export const REDEEM_REQUEST: "request/redeem" = "request/redeem";

export const RedeemModel: Model<Data, { }, RedeemRequest | RedeemResponse> = {
  id: "redeem",
  init: () =>
    updateData(
      { state: "LOADING", type: "redeem" },
      { tag: REDEEM_REQUEST, type: "redeem" }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case REDEEM_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
