/* @flow */

import React, { useContext, useState, useEffect, useMemo } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useBrowser } from "@awardit/react-use-browser";
import { Helmet } from "react-helmet-async";
import { HomeData, AffiliateListSelectedData, CustomerData, PhotoServiceListData, CurrentPageInfoData, ViewModeData } from "data";
import ProductCard, { DummyCard } from "components/ProductCard";
import ProductCarousel from "components/ProductCarousel";
import PopularCategoryCarousel from "components/PopularCategoryCarousel";
import CurrentPageInfo from "components/CurrentPageInfo";
import { getCurrentPageInfo } from "state/current-page-info";
import Wrapper from "components/Wrapper";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import AffiliateList, { AffiliateDummyList } from "components/AffiliateList";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import HomeHero from "components/HomeHero";
import Button from "components/Button";
import HomeHeroLeaderboard from "components/HomeHeroLeaderboard";
import { Title, Description, Items, Item } from "components/UiComponents";
import { getCustomerData, getNumberBasedOnBrowserWidth, tryParseJSONObject } from "helpers/utils";
import Agreement from "components/Agreement";
import useNotifyMissingAccountDetails from "helpers/use-notify-missing-account-details";
import UploadImagesOverlay from "components/UploadedImages/UploadImagesOverlay";
import { useTranslate } from "@awardit/react-use-translate";
import { logout } from "@crossroads/shop-state/customer";
import { awarditAgreementAgree } from "queries";
import { MODE } from "state/view-mode";

import styles from "./styles.scss";

const HomeView = () => {
  const {
    content: {
      homeview: content,
      allproductsview: { popularCategories, popularCategoriesTitle },
      uploadedImagesView: imagesview,
      productCarousel: { useOnHomeView },
    },
    routes,
    routes: { photoServiceView },
    configuration,
  } = useContext(StoreInfoContext);
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const home = useData(HomeData);
  const viewMode = useData(ViewModeData);
  const affiliateList = useData(AffiliateListSelectedData);
  const { width: browserWidth } = useBrowserDimensions();
  const customer = getCustomerData(useData(CustomerData));
  const [showTerms, setShowTerms] = useState(false);
  const isBrowser = useBrowser();
  const client = useClient();
  const numAffiliates = getNumberBasedOnBrowserWidth(
    styles,
    { small: 4, medium: 6 },
    8,
    browserWidth
  );
  const [uploadImgOpen, setUploadImgOpen] = useState(false);
  const photoServiceListData = useData(PhotoServiceListData);
  const currentPageInfoData = useData(CurrentPageInfoData);
  const cookieConsentIsVisible = viewMode === MODE.COOKIE_CONSENT;

  const memberTargetList = customer &&
    customer.memberTargetList &&
    customer.memberTargetList.list.length > 0 ?
    customer.memberTargetList.list :
    [];

  const popularCategoriesJson = popularCategories !== undefined &&
    tryParseJSONObject(popularCategories) ? JSON.parse(popularCategories) : "";

  const onSuccess = () => {
    setUploadImgOpen(false);
  };

  useEffect(() => {
    sendMessage(getCurrentPageInfo("HOMEVIEW"));
    sendMessage(getCurrentPageInfo("CURRENTINFO6"));
  }, []);

  useEffect(() => {
    if (isBrowser &&
      configuration.showTermsOnFirstLogin === true &&
      customer &&
      customer.awardit.agreementAccepted === false) {
      setShowTerms(true);
    }
  }, [isBrowser, customer, configuration]);

  const postAgreement = async () => {
    await client(awarditAgreementAgree);
  };

  useNotifyMissingAccountDetails();

  const currentInfoOrder = content.currentInfoOrder ?
    Number.parseInt(content.currentInfoOrder, 10) :
    1;
  const earnOnlineOrder = content.earnOnlineOrder ?
    Number.parseInt(content.earnOnlineOrder, 10) :
    2;
  const howToEarnOrder = content.howToEarnOrder ?
    Number.parseInt(content.howToEarnOrder, 10) :
    3;
  const featuredProductsOrder = content.featuredProductsOrder ?
    Number.parseInt(content.featuredProductsOrder, 10) :
    4;
  const additionalHtmlOrder = content.additionalHtmlOrder ?
    Number.parseInt(content.additionalHtmlOrder, 10) :
    5;
  const additionalHtmlSecondaryOrder = content.additionalHtmlSecondaryOrder ?
    Number.parseInt(content.additionalHtmlSecondaryOrder, 10) :
    6;
  const popularProductsOrder = content.popularProductsOrder ?
    Number.parseInt(content.popularProductsOrder, 10) :
    -1;
  const popularCategoriesOrder = content.popularCategoriesOrder ?
    Number.parseInt(content.popularCategoriesOrder, 10) :
    -1;
  const currentInfo6Order = content.currentInfo6Order ?
    Number.parseInt(content.currentInfo6Order, 10) :
    -1;
  const getItemAmount = (config, defaultAmount) => {
    if (browserWidth > 0) {
      const currentBreakpoint = Object.keys(config).find(breakpoint => {
        return browserWidth <= parseInt(styles[breakpoint], 10);
      });

      return currentBreakpoint ? config[currentBreakpoint] : defaultAmount;
    }

    return 0;
  };

  const currentInfo6Count = content.currentInfo6Count ?
    Number.parseInt(content.currentInfo6Count, 10) : 0;
  const currentInfo6Array = currentPageInfoData.state === "LOADED" &&
    currentPageInfoData.data.currentinfo6 &&
    currentPageInfoData.data.currentinfo6.length > 0 ?
    (currentInfo6Count > 0 ?
      currentPageInfoData.data.currentinfo6.slice(0, currentInfo6Count) :
      currentPageInfoData.data.currentinfo6) : [];

  const featuredProductsAmount = getItemAmount({ small: 2, medium: 3 }, 4);

  const featuredProducts = useMemo(() => home.state !== "LOADED" ? [...new Array(4)] :
    home.data.featuredProducts && home.data.featuredProducts.filter(p => !memberTargetList ||
      memberTargetList.includes(p.attributes.awarditTargetId) ||
      !p.attributes.awarditTargetId)
      .sort(() => 0.5 - Math.random())
      .slice(0, featuredProductsAmount
      ), [home.state, featuredProductsAmount]);

  const affiliateItems = (affiliateList.state === "LOADED" ? affiliateList.data : []).slice(0, numAffiliates);

  const howToEarnSection = (
    (content.howToEarnImage || content.howToEarnDescription) && (
      <div key="howToEarn" className={cn("awardit-homeViewPromotionSection", styles.promotion)}>
        <div className={cn(styles.left, !content.howToEarnImage ? styles.empty : "")}>
          {content.howToEarnImage &&
          <img className={styles.howToEarnImage} src={content.howToEarnImage} />
          }
        </div>
        <div className={cn("awardit-homeViewPromotionTexts", styles.right)}>
          {content.howToEarnHeading &&
            <Title elem="h2">{content.howToEarnHeading}</Title>
          }
          {content.howToEarnDescription &&
            <Description content={content.howToEarnDescription} />
          }

          {content.howToEarnButtonLink && content.howToEarnButtonText &&
          <Link
            to={content.howToEarnButtonLink}
            className={cn("link", styles.ctaLarge)}
          >
            {content.howToEarnButtonText}
          </Link>
          }
        </div>
      </div>
    )
  );

  const earnOnlineSection = (
    affiliateItems.length > 0 && (
      <div key="earnOnline" className={cn("awardit-homeViewAffiliateSection", styles.section)}>
        {content.earnOnlineHeading && (
          <div className={styles.top}>
            <Title elem="h2">{content.earnOnlineHeading}</Title>
            {content.earnOnlineButtonLink && content.earnOnlineButtonText &&
              <div className={styles.earnOnline}>
                <Link
                  to={content.earnOnlineButtonLink}
                  className={cn("link", styles.cta)}
                >
                  {content.earnOnlineButtonText}
                </Link>
              </div>
            }
          </div>
        )}
        {affiliateList.state === "LOADED" &&
        <AffiliateList items={affiliateItems} />
        }

        {affiliateList.state === "LOADING" &&
        <AffiliateDummyList items={Array.from({
          length: numAffiliates,
        }, () => null)} />
        }
      </div>
    )
  );

  const featuredProductsSection = (
    <div key="featuredProducts" className={cn("awardit-homeViewProductSection", styles.section)}>
      <div className={cn("awardit-homeViewProductSectionTop", styles.top)}>
        {content.featuredProductsHeading &&
          <Title elem="h2">{content.featuredProductsHeading}</Title>
        }
        {content.featuredProductsButtonLink && content.featuredProductsButtonText &&
          <div className={cn("awardit-homeViewProductSectionTopCta", styles.featuredProducts)}>
            <Link
              to={content.featuredProductsButtonLink}
              className={cn("link", styles.cta)}
            >
              {content.featuredProductsButtonText}
            </Link>
          </div>
        }
      </div>
      <Items>
        {featuredProducts && featuredProducts.map((p, i) =>
          p ?
            <Item key={p.name + i} className="awardit-homeViewProductCard">
              <ProductCard product={p} list={content.featuredProductsHeading ?? ""} position={i} />
            </Item> :
            <Item key={i}><DummyCard /></Item>
        )}
      </Items>
    </div>
  );

  const popularCategoriesSection = (
    <div key="popularCategories" className={styles.popularCategories}>
      {popularCategoriesJson &&
        <PopularCategoryCarousel
          title={popularCategoriesTitle ?? ""}
          popularCategories={popularCategoriesJson}
        />
      }
    </div>
  );

  const popularProductsSection = (
    Boolean(useOnHomeView) && (
      <div key="popularProducts" className={styles.popularProducts}>
        <ProductCarousel />
      </div>
    )
  );

  const UploadImageSection = () => (
    <div key="uploadImageSection" className={cn("awardit-homeViewUploadedImages", styles.section)}>
      <UploadImagesOverlay
        open={uploadImgOpen}
        setOpen={setUploadImgOpen}
        onSuccess={onSuccess}
      />
      <div className={cn("awardit-homeViewUploadedImagesTop", styles.top)}>
        <Title className={styles.title} elem="h2">
          {photoServiceView &&
            photoServiceView.title ?
            photoServiceView.title :
            t("IMAGE_UPLOADER.LATEST")}
        </Title>
        <div className={cn("awardit-homeViewUploadedImagesTopCta", styles.uploadImage)}>
          <Link
            to={photoServiceView && photoServiceView.url ? photoServiceView.url : ""}
            className={cn("link", styles.cta)}
          >
            {t("IMAGE_UPLOADER.SHOW_ALL")}
          </Link>
          <Button
            type="button"
            className={styles.btn}
            variant="primary"
            onClick={() => setUploadImgOpen(true)}
          >
            {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
          </Button>
        </div>
      </div>
      {imagesview &&
        imagesview.frontpageInfo !== null &&
        imagesview.frontpageInfo !== undefined &&
        <>
          {/* eslint-disable react/no-danger */}
          <div
            className={styles.imageViewDesc}
            dangerouslySetInnerHTML={{ __html: imagesview.frontpageInfo }}
          />
          {/* eslint-enable react/no-danger */}
        </>
      }
      <Items>
        {photoServiceListData &&
        photoServiceListData.state === "LOADED" &&
        photoServiceListData.data.length > 0 ?
          photoServiceListData.data.slice(0, 4).map((elem, i) => (
            elem ?
              <Item key={`${elem.title}-${i}`} className={styles.uploadedImageContainer}>
                <div className={styles.innerImageContainer}>
                  <img src={elem.thumb ? elem.thumb : elem.url} />
                </div>
                <p className={styles.imageContainerTitle}>{elem.title}</p>
              </Item> :
              <Item key={i}><DummyCard /></Item>
          )) : <p className={styles.imageContainerTitle}>{t("IMAGE_UPLOADER.EMPTY")}</p>
        }
      </Items>
    </div>
  );

  const currentPageInfo = <CurrentPageInfo key="currentPageInfo" className={styles.section} />;

  const additionalHtmlSection = (
    content.additionalHtml && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtml }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const additionalHtmlSecondarySection = (
    content.additionalHtmlSecondary && (
      <div className={styles.additionalHtmlContainer}>
        {/* eslint-disable react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: content.additionalHtmlSecondary }} />
        {/* eslint-enable react/no-danger */}
      </div>
    )
  );

  const currentInfo6Section = (
    currentInfo6Array &&
    currentInfo6Array.length > 0 &&
    routes.currentInfoView6?.url && (
      <div className="awardit-currenInfoCardsWrapper">
        {content.currentInfo6Title &&
          <Title className={styles.title} elem="h2">{content.currentInfo6Title}</Title>
        }
        <div className={styles.currenInfoCardsSection}>
          {currentInfo6Array.map((info, i) => (
          /* eslint-disable react/no-danger */
            <div key={"currentinfo6_item_" + i} className={styles.currenInfoCardsSectionCardWrapper} idx={i}>
              <Link
                to={{
                  pathname: `${(routes.currentInfoView6 && routes.currentInfoView6.url) ?? ""}/${info.id}`,
                  state: { hint: { title: info.title } },
                }}
                className={cn(styles.currentInfoCard, "awardit-currenInfoCard")}
              >
                {info.title && <h3>{info.title}</h3>}
                {info.image &&
                  <div className={styles.currenInfoCardImage}>
                    <img src={info.image} />
                  </div>
                }
                {info.content &&
                  <div
                    className={cn(styles.currentInfoDescription, "awardit-currentInfoDescription")}
                    dangerouslySetInnerHTML={{ __html: info.description }}
                  />
                }
              </Link>
            </div>
          /* eslint-enable react/no-danger */
          ))}
        </div>
      </div>
    )
  );

  const main = [];

  main[howToEarnOrder] = howToEarnSection;
  main[currentInfoOrder] = currentPageInfo;
  main[earnOnlineOrder] = earnOnlineSection;
  main[featuredProductsOrder] = featuredProductsSection;

  if (additionalHtmlOrder >= 0) {
    main[additionalHtmlOrder] = additionalHtmlSection;
  }

  if (additionalHtmlSecondaryOrder >= 0) {
    main[additionalHtmlSecondaryOrder] = additionalHtmlSecondarySection;
  }

  if (popularProductsOrder >= 0) {
    main[popularProductsOrder] = popularProductsSection;
  }

  if (popularCategoriesOrder >= 0) {
    main[popularCategoriesOrder] = popularCategoriesSection;
  }

  if (currentInfo6Order >= 0) {
    main[currentInfo6Order] = currentInfo6Section;
  }

  return (
    <div className={cn("awardit-homeView", styles.block)}>
      <Helmet
        title={content.pageTitle ?? ""}
      />
      {content.motivationLeaderboardWidgetIsActive !== undefined &&
      content.motivationLeaderboardWidgetIsActive !== null &&
      content.motivationLeaderboardWidgetIsActive === true ?
        <HomeHeroLeaderboard /> : <HomeHero />}
      <Wrapper className={cn("awardit-homeViewWrapper", styles.wrapper)}>
        { photoServiceView && photoServiceView.toggle !== undefined &&
        photoServiceView.toggle ? <UploadImageSection /> : null }
        { main }
      </Wrapper>

      {showTerms && !cookieConsentIsVisible &&
        <Agreement
          agreementModalOpen={showTerms}
          setAgreementModalOpen={open => {
            if (open === true) {
              sendMessage(logout());
            }

            if (open === false) {
              postAgreement();
              setShowTerms(open);
            }
          }}
        />
      }
    </div>
  );
};

export default HomeView;
