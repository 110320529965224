/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { OffersResponse, OffersRequest } from "state/offers";

import { OFFERS_REQUEST, OFFERS_RESPONSE } from "state/offers";
import { offers, infoBlock } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: OffersRequest) => {
      /**
       * TODO: Split offers and currentInforForPage and handle them
       */
      const [{ offers: offersData }, { info }] = await Promise.all([
        client(offers, {}, { cache: true }),
        client(infoBlock, { page: "OFFERLISTVIEW", prio: msg.prio }, { cache: true }),
      ]);

      const data = [
        ...offersData.map(x => ({
          title: x.contentHeader,
          content: x.contentText,
          image: x.urlImage,
          link: x.url,
          linkText: x.urlText,
        })),
        ...(info.result === "SUCCESS" ? info.data.map(x => ({
          title: x.title,
          content: x.content,
          image: x.image,
          link: null,
          linkText: null,
        })) : []),
      ];

      return ({
        tag: OFFERS_RESPONSE,
        data,
      }: OffersResponse);
    },
    subscribe: { [OFFERS_REQUEST]: true },
  });
};

export default registerClient;
