/* @flow */

import type { MemberGroupIsOwner } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING" }
  | { state: "LOADED", data: MemberGroupIsOwner }
  | { state: "ERROR", error: string };

export type MemberGroupIsOwnerResponse =
  Response<typeof MEMBER_GROUP_IS_OWNER_RESPONSE, ?MemberGroupIsOwner>;
export type MemberGroupIsOwnerRequest = {
  tag: typeof MEMBER_GROUP_IS_OWNER_REQUEST,
  type: "member_group_is_owner",
};

export const MEMBER_GROUP_IS_OWNER_RESPONSE: "response/member_group_is_owner" = "response/member_group_is_owner";
export const MEMBER_GROUP_IS_OWNER_REQUEST: "request/member_group_is_owner" = "request/member_group_is_owner";

export const MemberGroupIsOwnerModel: Model<
    Data, { }, MemberGroupIsOwnerRequest | MemberGroupIsOwnerRequest
> = {
  id: "member_group_is_owner",
  init: () =>
    updateData(
      { state: "LOADING", type: "member_group_is_owner" },
      { tag: MEMBER_GROUP_IS_OWNER_REQUEST, type: "member_group_is_owner" }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case MEMBER_GROUP_IS_OWNER_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
