/* @flow */

import React from "react";

export const pixelSrc = "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==";

const Pixel = (props: any) => (
  <img alt="" src={pixelSrc} {...props} />
);

export default Pixel;
