/* @flow */

import type { AbstractAddressInputFormaggio } from "shop-state/types";

import React, { useState, useEffect, useRef, useContext } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router";
import { useSendMessage, useData } from "crustate/react";
import { Form, rules, nestedRule, isEmail, isPhone, isRequired } from "@awardit/formaggio";
import { setAddresses } from "@crossroads/shop-state/quote";
import { QuoteData, CustomerData } from "data";
import { getQuoteData } from "state/quote";
import Button from "components/Button";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CustomerServiceLink";
import CartSummary from "components/CartSummary/Points";
import {
  focusInvalidField,
  useSkipPayment,
  streetArrayToObject } from "helpers/utils";
import { StoreInfoContext } from "entrypoint/shared";
import Addresses from "components/CheckoutView/Addresses";

import styles from "./styles.scss";

export type FormState = {
  billing: AbstractAddressInputFormaggio,
  email: string,
};

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

const Step1 = ({ open, setOpen }: Props) => {
  const { routes, content: {
    checkoutview,
  } } = useContext(StoreInfoContext);
  const history = useHistory();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData) || {};
  const customerData = useData(CustomerData);
  const addresses = quote.addresses || [];
  const billingAddress = addresses.find(x => x.type === "billing");
  const prevQuoteData = usePrevious(quoteData);
  const [editingAddress, setEditingAddress] = useState(false);
  const checkoutViewUrl = (routes.checkoutView && routes.checkoutView.url) ?? "";

  const streets = streetArrayToObject(billingAddress ? billingAddress.street : []);
  const skipPayment = useSkipPayment();

  const [state, setState] = useState<FormState>({
    email: quote.email || "",
    billing: {
      firstname: billingAddress?.firstname || "",
      lastname: billingAddress?.lastname || "",
      street: streets,
      postcode: billingAddress?.postcode || "",
      city: billingAddress?.city || "",
      telephone: billingAddress?.telephone || "",
      countryCode: billingAddress?.country.code || "SE",
      company: billingAddress?.company || "",
      awarditOrgNr: billingAddress?.awarditOrgNr || "",
    },
  });

  const validationAddress = () => rules([
    isRequired("firstname"),
    isRequired("lastname"),
    isRequired("postcode"),
    isPhone("telephone"),
    isRequired("city"),
    nestedRule("street", rules([
      isRequired("0"),
    ])),
  ]);

  const validation = rules([
    isEmail("email"),
    isRequired("billing"),
    nestedRule("billing", validationAddress()),
  ]);

  // TODO: Maybe update formaggio types to be more accepting or become
  //       structured to accept a specific structure
  const errors = validation((state: any));

  // Proceed on submit address
  useEffect(() => {
    if ((prevQuoteData && prevQuoteData.state === "SETTING_ADDRESS") && quoteData.state === "LOADED" && !quoteData.errors) {
      history.push(skipPayment ? `${checkoutViewUrl}/overview` : `${checkoutViewUrl}/payment`);
    }

    if (!prevQuoteData?.errors && quoteData.errors) {
      setEditingAddress(true);
    }
  }, [prevQuoteData, quoteData, sendMessage, skipPayment]);

  if (quoteData.state === "LOADING" || customerData.state !== "LOGGED_IN") {
    return null;
  }

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const billing = {
      firstname: state.billing.firstname,
      lastname: state.billing.lastname,
      street: Object.values(state.billing.street).map(x => String(x)),
      postcode: state.billing.postcode,
      city: state.billing.city,
      company: state.billing.company,
      awarditOrgNr: state.billing.awarditOrgNr,
      countryCode: state.billing.countryCode,
      telephone: state.billing.telephone,
    };

    sendMessage(setAddresses(state.email, billing, billing, true));
  };

  if (!quoteData.data) {
    return null;
  }

  return (
    <>
      <Helmet title={checkoutview.pageTitleShipping ?? ""} />
      {/** TODO: Same as with validation, formaggio needs to be more accepting */}
      <Form
        value={(state: any)}
        errors={errors}
        onError={(e, errors) => {
          setEditingAddress(true);
          focusInvalidField(e, errors);
        }}
        onChange={x => {
          setState({ ...state, ...(x: any) });
        }}
        onSubmit={submit}
      >
        <Container
          right={
            <div>
              <CartSummary
                open={open}
                setOpen={setOpen}
                quoteData={quoteData}
              >
                {checkoutview.toPayment &&
                  <Button
                    type="submit"
                    variant="secondary"
                    loading={quoteData.state === "SETTING_ADDRESS"}
                    className="awardit-buttonToPayment"
                  >
                    {checkoutview.toPayment}
                  </Button>
                }
              </CartSummary>

              <CustomerServiceLink />
            </div>
          }
        >
          <div className={styles.row}>
            <Addresses
              state={state}
              errors={errors}
              editing={editingAddress}
              setEditing={setEditingAddress}
              setCustomerCountry={(country: string) => {
                setState({ ...state, billing: { ...state.billing, countryCode: country } });
              }}
            />
          </div>
        </Container>
      </Form>
    </>
  );
};

export default Step1;
