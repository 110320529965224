/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  CurrentInfoListResponse,
  CurrentInfoListRequest,
} from "state/current-info-list";
import type {
  CurrentInfoItemResponse,
  CurrentInfoItemRequest,
} from "state/current-info-item";

import { CURRENT_INFO_LIST_REQUEST, CURRENT_INFO_LIST_RESPONSE } from "state/current-info-list";
import { CURRENT_INFO_ITEM_REQUEST, CURRENT_INFO_ITEM_RESPONSE } from "state/current-info-item";
import { currentInfoPage, currentInfoItem } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: CurrentInfoListRequest) => {
      try {
        const { currentInfoPage: page } = await client(currentInfoPage, {
          page: msg.page,
          tags: msg.tags,
          prio: msg.prio,
        }, { cache: true });

        if (page.result !== "SUCCESS") {
          return ({
            tag: CURRENT_INFO_LIST_RESPONSE,
            error: "ERROR",
          }: CurrentInfoListResponse);
        }

        return ({
          tag: CURRENT_INFO_LIST_RESPONSE,
          data: page.data,
        }: CurrentInfoListResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: CURRENT_INFO_LIST_RESPONSE,
          error: "ERROR",
        }: CurrentInfoListResponse);
      }
    },
    subscribe: { [CURRENT_INFO_LIST_REQUEST]: true },
  });
  storage.addEffect({
    effect: async (msg: CurrentInfoItemRequest) => {
      try {
        const { currentInfoItem: item } = await client(currentInfoItem, {
          id: msg.id,
        }, { cache: true });

        if (item.result !== "SUCCESS") {
          return ({
            tag: CURRENT_INFO_ITEM_RESPONSE,
            error: "ERROR",
          }: CurrentInfoItemResponse);
        }

        return ({
          tag: CURRENT_INFO_ITEM_RESPONSE,
          data: item.data,
        }: CurrentInfoItemResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: CURRENT_INFO_ITEM_RESPONSE,
          error: "ERROR",
        }: CurrentInfoItemResponse);
      }
    },
    subscribe: { [CURRENT_INFO_ITEM_REQUEST]: true },
  });
};

export default registerClient;
