/* @flow */

import type { AffiliatesListItem } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: ?Array<AffiliatesListItem> }
  | { state: "LOADED", data: Array<AffiliatesListItem> }
  | { state: "ERROR", error: string };

export type AffiliateListParams = {
  category?: number,
  page?: number,
  pageSize?: number,
  sort?: {
    code?: string,
    order?: string,
  },
};

type AffiliateListRequest = {
  ...AffiliateListParams,
  tag: typeof AFFILIATE_LIST_REQUEST,
};

export type AffiliateListEffectResponse =
  Response<typeof AFFILIATE_LIST_EFFECT_RESPONSE, Array<AffiliatesListItem>>;

export type AffiliateListEffectRequest = {
  ...AffiliateListParams,
  tag: typeof AFFILIATE_LIST_EFFECT_REQUEST,
};

const AFFILIATE_LIST_REQUEST: "request/affiliate_list" = "request/affiliate_list";

export const AFFILIATE_LIST_EFFECT_RESPONSE: "response/affiliate_list_effect" = "response/affiliate_list_effect";
export const AFFILIATE_LIST_EFFECT_REQUEST: "request/affiliate_list_effect" = "request/affiliate_list_effect";

export const getAffiliateList = (params: AffiliateListParams) => ({
  ...params,
  tag: AFFILIATE_LIST_REQUEST,
});

export const AffiliateListModel: Model<
  Data,
  AffiliateListParams,
  AffiliateListRequest |
  AffiliateListEffectRequest | AffiliateListEffectResponse
> = {
  id: "affiliate_list",
  init: params => {
    return updateData(
      { state: "LOADING", data: null },
      { ...params, tag: AFFILIATE_LIST_EFFECT_REQUEST }
    );
  },
  update: (state, msg) => {
    switch (msg.tag) {
      case AFFILIATE_LIST_REQUEST:
        return updateData(
          { state: "LOADING", data: state.state !== "ERROR" ? state.data : null },
          { ...msg, tag: AFFILIATE_LIST_EFFECT_REQUEST }
        );
      case AFFILIATE_LIST_EFFECT_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
