/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import SearchIcon from "icons/search.svg";
import { Dropdown, DropdownItem, Input } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";

import styles from "./styles.scss";
import cn from "classnames";

type Value = string | number;

type handleProps = {
  datesel: number,
  getPrevMonthLeaderboard: () => void,
  getMonthLeaderboard: () => void,
  getYearLeaderboard: () => void,
  getCustomDateLeaderboard: (fromDate: string, toDate: string) => void,
  getSelectedYearLeaderboard: (selectedYear: Value) => void,
  activeLeaderboard: string,
  selectedFromDate: string,
  selectedToDate: string,
  selectedYear: Value | null,
  setSelectedFromDate: (x: string) => void,
  setSelectedToDate: (x: string) => void,
  setSelectedYear: (x: Value | null) => void,
};

const SortOptions = ({
  datesel,
  getSelectedYearLeaderboard,
  getCustomDateLeaderboard,
  getPrevMonthLeaderboard,
  getMonthLeaderboard,
  getYearLeaderboard,
  activeLeaderboard,
  selectedFromDate,
  selectedToDate,
  selectedYear,
  setSelectedFromDate,
  setSelectedToDate,
  setSelectedYear }: handleProps) => {
  const t = useTranslate();

  const { content: { leaderboardview } } = useContext(StoreInfoContext);
  const currentDate = new Date();
  const minDate = new Date("2020-01-01");
  const isoCurrentDate = currentDate.toISOString().slice(0, 10);
  const isoMinDate = minDate.toISOString().slice(0, 10);

  const rangeOfYears = (start, end) => new Array(end - start + 1)
    .fill(start)
    .map((year, index) => year + index);

  const yearArr: Array<Value | null> =
    rangeOfYears(minDate.getFullYear(), currentDate.getFullYear()).map(String);

  switch (datesel) {
    case 1:
      // Year select field
      return (
        <Dropdown
          name="showAmountOfMembers"
          className={cn(styles.dropdown, styles.yearDropdown, {
            [styles.dropdown__maxHeight]: yearArr.length > 2 }
          )}
          value={selectedYear}
          onChange={(val: any) => {
            setSelectedYear(val);
            getSelectedYearLeaderboard(val);
          }}
        >
          {yearArr.map((year, i) => (
            <DropdownItem key={i} value={year !== null ? year : ""}>
              {year}
            </DropdownItem>
          ))}
        </Dropdown>
      );
    case 2:
      // Year or month select field
      return (
        <div className={styles.ymSelectField}>
          <p>{t("LEADERBOARD.HERO.SHOW")}</p>
          {Boolean(leaderboardview.showPreviousMonth) &&
            <Button
              className={cn(styles.btn, {
                [styles.activeLeaderboard]: activeLeaderboard && activeLeaderboard === "prevMonth",
              })}
              onClick={() => getPrevMonthLeaderboard()}
            >
              {t(`MONTHS.${currentDate.getMonth() !== 0 ? currentDate.getMonth() : 12}`)}
            </Button>
          }
          <Button
            className={cn(styles.btn, { [styles.activeLeaderboard]: activeLeaderboard && activeLeaderboard === "month" })}
            onClick={() => getMonthLeaderboard()}
          >
            {t(`MONTHS.${currentDate.getMonth() + 1}`)}
          </Button>
          <Button
            className={cn(styles.btn, { [styles.activeLeaderboard]: activeLeaderboard && activeLeaderboard === "year" })}
            onClick={() => getYearLeaderboard()}
          >
            {currentDate.getFullYear()}
          </Button>
        </div>
      );
    case 3:
      // Custom date select field
      return (
        <>
          <Input
            type="date"
            wrapperClassName={styles.dateInput}
            min={isoMinDate}
            max={isoCurrentDate}
            value={selectedFromDate}
            pattern="\d{4}-\d{2}-\d{2}"
            placeholder={t("COMMON.FROM")}
            onChange={e => setSelectedFromDate(e.target.value)}
          />
          <Input
            type="date"
            wrapperClassName={styles.dateInput}
            min={isoMinDate}
            max={isoCurrentDate}
            value={selectedToDate}
            pattern="\d{4}-\d{2}-\d{2}"
            placeholder={t("COMMON.TO")}
            onChange={e => setSelectedToDate(e.target.value)}
          />
          <Button
            className={cn(styles.goBtn)}
            onClick={() => getCustomDateLeaderboard(selectedFromDate, selectedToDate)}
          >
            <SearchIcon />
          </Button>
        </>
      );
    default:
      return null;
  }
};

export default SortOptions;
