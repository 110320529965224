/* @flow */

import type { Gender } from "shop-state/types";

import React, { useMemo } from "react";
import { DropdownItem } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import Dropdown from "components/Dropdown";
import Field from "components/Field";
import styles from "./styles.scss";

type Props = {
  className?: string,
  dobLabel: string,
  genderLabel: string,
  showDob: boolean,
  showGender: boolean,
  gender?: ?string,
  showEmployees: boolean,
  employeesLabel?: ?string,
  setGender?: (Gender) => void,
};

export const GENDERS = [
  "Female",
  "Male",
  "Unspecified",
];

const ExtraCustomerInfo = ({
  className,
  employeesLabel,
  showEmployees,
  showDob,
  showGender,
  dobLabel,
  genderLabel,
  gender,
  setGender,
}: Props) => {
  const t = useTranslate();
  const genders = useMemo(() => GENDERS.map(x => ({
    code: x,
    name: t(`ACCOUNT.GENDER.${x.toUpperCase()}`),
  })), [t]);

  const _setGender = (value: ?(string | number)) => {
    if (setGender && (value === "Female" || value === "Male" || value === "Unspecified")) {
      setGender(value);
    }
  };

  return (
    <div className={className}>
      <div className={styles.row}>
        {showDob &&
          <div className={styles.s50}>
            <Field name="dob" label={dobLabel} />
          </div>
        }
        {showGender && setGender &&
          <div className={styles.s50}>
            <Dropdown
              placeholder={genderLabel}
              label={genderLabel}
              name="gender"
              value={gender}
              onChange={_setGender}
            >
              {genders.map(x => (
                <DropdownItem key={x.code} value={x.code}>
                  {x.name}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        }
      </div>
      <div className={styles.row}>
        {showEmployees &&
          <div className={styles.s50}>
            <Field disabled name="numberOfEmployees" label={employeesLabel} />
          </div>
        }
      </div>
    </div>
  );
};

export default ExtraCustomerInfo;
