/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { AffiliateCategoriesResponse } from "state/affiliate-categories";

import {
  AFFILIATE_CATEGORIES_REQUEST,
  AFFILIATE_CATEGORIES_RESPONSE,
} from "state/affiliate-categories";

import { affiliateCategories as affiliateCategoriesQuery } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const { affiliateCategories } = await client(affiliateCategoriesQuery);

      if (affiliateCategories.result === "SUCCESS") {
        return ({
          tag: AFFILIATE_CATEGORIES_RESPONSE,
          data: affiliateCategories.data.filter(f => f.name !== "tom/deletad/gömd"),
        }: AffiliateCategoriesResponse);
      }

      return ({
        tag: AFFILIATE_CATEGORIES_RESPONSE,
        error: "AFFILIATE_CATEGORIES_LOAD_ERROR",
      }: AffiliateCategoriesResponse);
    },
    subscribe: { [AFFILIATE_CATEGORIES_REQUEST]: true },
  });
};

export default registerClient;
