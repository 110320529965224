/* @flow */

import React, { useState, useContext } from "react";
import useDevice from "helpers/use-device";
import { useData, useSendMessage } from "crustate/react";
import { PhotoServiceListData } from "data";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";

import LoadingView from "components/LoadingView";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import Breadcrumbs from "components/Breadcrumbs";
import UploadImagesOverlay from "components/UploadedImages/UploadImagesOverlay";
import { Lightbox } from "@crossroads/ui-components";
import { getPhotoServiceList } from "state/photo-service-list";
import CloseIcon from "icons/close-large.svg";
import { Items, Item } from "components/UiComponents";

import styles from "./styles.scss";

const UploadedImages = () => {
  const { content: { uploadedImagesView } } = useContext(StoreInfoContext);
  const t = useTranslate();
  const photoServiceListData = useData(PhotoServiceListData);
  const isDesktop = useDevice("gt", 801);
  const [open, setOpen] = useState(false);
  const [boxOpen, setBoxOpen] = useState(false);
  const [currImgUrl, setCurrImgUrl] = useState<null | string>(null);
  const sendMessage = useSendMessage();

  const openImageBox = url => {
    setCurrImgUrl(url);
    setBoxOpen(true);
  };

  const onSuccess = () => {
    setOpen(false);
    reload();
  };

  const reload = () => {
    sendMessage(getPhotoServiceList());
  };

  if (photoServiceListData &&
    photoServiceListData.state === "LOADING") {
    return (
      <Wrapper className={styles.wrapper}>
        {isDesktop &&
          <Breadcrumbs current={uploadedImagesView.title ?
            uploadedImagesView.title :
            t("IMAGE_UPLOADER.TITLE")} />
        }

        <UploadImagesOverlay open={open} setOpen={setOpen} onSuccess={onSuccess} />

        <header className={styles.header}>
          <h2>{uploadedImagesView.title ? uploadedImagesView.title : t("IMAGE_UPLOADER.TITLE")}</h2>
          <Button
            type="button"
            className={styles.btn}
            variant="primary"
            onClick={() => setOpen(true)}
          >
            {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
          </Button>
        </header>
        <LoadingView />
      </Wrapper>
    );
  }

  if (photoServiceListData &&
    photoServiceListData.state === "LOADED" &&
    photoServiceListData.data.length <= 0
  ) {
    return (
      <Wrapper className={styles.wrapper}>
        {isDesktop &&
          <Breadcrumbs current={uploadedImagesView.title ?
            uploadedImagesView.title :
            t("IMAGE_UPLOADER.TITLE")} />
        }

        <UploadImagesOverlay open={open} setOpen={setOpen} onSuccess={onSuccess} />

        <header className={styles.header}>
          <h2>{uploadedImagesView.title ? uploadedImagesView.title : t("IMAGE_UPLOADER.TITLE")}</h2>
          <Button
            type="button"
            className={styles.btn}
            variant="primary"
            onClick={() => setOpen(true)}
          >
            {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
          </Button>
        </header>
        <p className={styles.imageContainerTitle}>{t("IMAGE_UPLOADER.EMPTY")}</p>
      </Wrapper>
    );
  }

  return (
    <Wrapper className={styles.wrapper}>
      {isDesktop &&
        <Breadcrumbs current={uploadedImagesView.title ?
          uploadedImagesView.title :
          t("IMAGE_UPLOADER.TITLE")} />
      }

      <UploadImagesOverlay open={open} setOpen={setOpen} onSuccess={onSuccess} />

      <header className={styles.header}>
        <h2>{uploadedImagesView.title ? uploadedImagesView.title : t("IMAGE_UPLOADER.TITLE")}</h2>
        <Button
          type="button"
          className={styles.btn}
          variant="primary"
          onClick={() => setOpen(true)}
        >
          {t("IMAGE_UPLOADER.UPLOAD_IMAGE")}
        </Button>
      </header>

      {uploadedImagesView &&
      uploadedImagesView.info && (
      /* eslint-disable react/no-danger */
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: uploadedImagesView.info }}
        />
      /* eslint-enable react/no-danger */
      )}
      {photoServiceListData &&
      photoServiceListData.state === "LOADED" ?
        <Items className={styles.uploadedImagesContainer}>
          {photoServiceListData.data.map((elem, i) => (
            <Item
              key={`${elem.title}-${i}`}
              className={styles.uploadedImageContainer}
              onClick={() => openImageBox(elem.url)}
            >
              <div className={styles.innerImageContainer}>
                <img src={elem.thumb ? elem.thumb : elem.url} />
              </div>
              <p className={styles.imageContainerTitle}>{elem.title}</p>
            </Item>
          ))}
        </Items> :
        <LoadingView /> }
      <Lightbox className={styles.lightbox} open={boxOpen} setOpen={setBoxOpen}>
        <CloseIcon onClick={() => setBoxOpen(false)} />
        <div className={styles.lightboxImage}>
          {currImgUrl && <img src={currImgUrl} />}
        </div>
      </Lightbox>
    </Wrapper>
  );
};

export default UploadedImages;
