/* @flow */

import React, { useState, useRef, useEffect, useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { debounce } from "diskho";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { setPointsPayment } from "state/quote";
import { CustomerData, QuoteData } from "data";
import { useSendMessage, useData } from "crustate/react";
import { getCustomerData } from "helpers/utils";
import { usePointsLabel, useSpendingLimit } from "helpers/points";
import useQuotePointsSetToMaximum from "helpers/use-quote-points-set-to-maximum";
import { Box } from "components/Box";
import Button from "components/Button";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CustomerServiceLink";
import CartSummary from "components/CartSummary/Full";
import { Input, Slider } from "@crossroads/ui-components";
import { StoreInfoContext } from "entrypoint/shared";

import styles from "./styles.scss";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const UPDATE_POINTS_TIMEOUT = 200;

const Step2 = ({ open, setOpen }: Props) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const sendMessage = useSendMessage();
  const customer = getCustomerData(useData(CustomerData));
  const { formatPoints, formatPrice } = useFormat();
  const {
    routes,
    content: { checkoutview },
  } = useContext(StoreInfoContext);
  const displayExVat = checkoutview.displayExVat !== undefined &&
    checkoutview.displayExVat !== null && checkoutview.displayExVat === true;
  const quote = quoteState.data || null;
  const { selectedPointPayment } = quote || {};
  const minPoints = selectedPointPayment?.points.min.exVat || 0;
  const availablePoints = selectedPointPayment?.points.available.exVat || 0;
  const selectedPoints = selectedPointPayment?.points.selected.exVat || 0;
  const selectedPointsExVat = selectedPointPayment?.points.selected.exVat || 0;
  const remainingCurrency = selectedPointPayment?.currency.remaining.incVat || 0;
  const remainingCurrencyExVat = selectedPointPayment?.currency.remaining.exVat || 0;
  const label = usePointsLabel();
  const spendingLimit = useSpendingLimit();

  const [sliderValue, setSliderValue] = useState(() => {
    return Math.min(Math.max(selectedPoints, minPoints), availablePoints);
  });
  const [inputValue, setInputValue] = useState<string>(sliderValue.toString());
  const customerBalance = customer ? parseInt(customer.awardit.activePoints, 10) : 0;

  const setQuotePointsDebounced = useRef(debounce(x => {
    sendMessage(setPointsPayment("awardit", Math.ceil(x)));
  }, UPDATE_POINTS_TIMEOUT)).current;

  const setQuotePoints = points => {
    if (quoteState.state === "LOADED") {
      setQuotePointsDebounced(points);
    }
  };

  useQuotePointsSetToMaximum();

  // Set selected point payment when landing on this page
  useEffect(() => {
    if (quoteState.state === "LOADED" && !quoteState.data.selectedPointPayment) {
      sendMessage(setPointsPayment("awardit"));
    }
  }, [quoteState]);

  useEffect(() => {
    setInputValue(sliderValue.toFixed(0));
  }, [sliderValue]);

  useEffect(() => {
    setInputValue(selectedPoints.toString());
    setSliderValue(selectedPoints);
  }, [selectedPoints]);

  const limitPoints = (p: number) => {
    if (isNaN(p)) {
      return selectedPoints;
    }

    if (p < minPoints) {
      return minPoints;
    }

    if (p > availablePoints) {
      return availablePoints;
    }

    return p;
  };

  const setInputValueToSliderValue = () => {
    const p = limitPoints(Number.parseInt(inputValue, 10));
    setInputValue(p.toString());
    setSliderValue(p);
    setQuotePoints(p);
  };

  return (
    <>
      <Helmet title={checkoutview.pageTitlePayment ?? ""} />
      <Container
        right={
          <div>
            {selectedPointPayment &&
              <CartSummary
                totalAmount
                open={open}
                setOpen={setOpen}
                selectedPointPayment={selectedPointPayment}
                priceDistribution={{
                  pointsExVat: selectedPointsExVat,
                  pointsIncVat: selectedPoints,
                  totalExVat: remainingCurrencyExVat,
                  totalIncVat: remainingCurrency,
                }}
              >
                {routes.checkoutView && routes.checkoutView.url && checkoutview.toOverview &&
                  <Button to={`${routes.checkoutView.url}/overview`} variant="secondary" className="awardit-buttonToOverview">
                    {checkoutview.toOverview}
                  </Button>
                }
              </CartSummary>
            }

            <CustomerServiceLink />
          </div>
        }
      >
        <Box className={styles.box}>
          {checkoutview.pointsTitle &&
            <h2 className={styles.pointsHeading}>{checkoutview.pointsTitle}</h2>
          }
          <p className={styles.balance}>
            {checkoutview.pointsBalanceText ?
              t("CHECKOUT.POINTS_BALANCE_JAVA", {
                points: formatPoints(customerBalance),
                javatext: checkoutview.pointsBalanceText,
              }) :
              t("CHECKOUT.POINTS_BALANCE", {
                points: formatPoints(customerBalance),
                pointsLabel: label(),
                currencyLimit: (100 - spendingLimit.minPercent),
              })}
          </p>
          <div className={styles.pointSelect}>
            <div className={styles.slider}>
              <Slider
                value={sliderValue}
                minValue={minPoints}
                maxValue={availablePoints}
                stepSize={1}
                onChange={v => {
                  const p = Math.floor(limitPoints(v));
                  setSliderValue(p);
                  setQuotePoints(p);
                }}
              />

              <div className={styles.sliderLabels}>
                <span>{formatPoints(minPoints)}</span>
                <span>{formatPoints(availablePoints)}</span>
              </div>
            </div>
            <Input
              value={inputValue}
              onChange={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
                setInputValue(e.currentTarget.value);
              }}
              onBlur={() => {
                setInputValueToSliderValue();
              }}
              onKeyUp={(e: SyntheticKeyboardEvent<HTMLInputElement>) => {
                if (e.key === "Enter") {
                  setInputValueToSliderValue();
                }
              }}
            />
          </div>

          <footer className={cn(
            styles.summary,
            { [styles.loading]: quoteState.state === "SETTING_AWARDIT_POINTS" }
          )}
          >
            {checkoutview.amountToPay &&
              <h2 className={styles.summary__label}>
                {checkoutview.amountToPay}
              </h2>
            }
            <div>
              <div className={styles.summaryIncVat}>
                <span><strong>{formatPoints(selectedPoints)}</strong></span>
                {(remainingCurrency >= 0.01) &&
                  <span><strong>{`${t("CHECKOUT.AND")} ${formatPrice(remainingCurrency)}`}</strong></span>
                }
              </div>
              {(remainingCurrencyExVat >= 0.01 && displayExVat) &&
                <div className={styles.summaryExVat}>
                  <span>{t("CHECKOUT.EX_TAX")}</span>
                  <span>{formatPrice(remainingCurrencyExVat)}</span>
                </div>
              }
            </div>

          </footer>
        </Box>
      </Container>
    </>
  );
};

export default Step2;
