/* @flow */

import type { Model } from "crustate";
import type { Response } from "./util";
import type { ConnectedAccounts } from "shop-state/types";

import { updateData, updateNone } from "crustate";

type Data =
  | {| state: "LOADING", data: ?ConnectedAccounts |}
  | {| state: "LOADED", data: ConnectedAccounts |}
  | {| state: "ERROR", data: ?ConnectedAccounts, error: string |}
  | {| state: "TRANSFERING", data: ?ConnectedAccounts |}
  | {| state: "TRANSFERED", data: ConnectedAccounts |};

export type ConnectedAccountsResponse =
  Response<typeof CONNECTED_ACCOUNTS_RESPONSE, ConnectedAccounts>;
export type TransferPointsResponse =
  Response<typeof TRANSFER_POINTS_RESPONSE, ConnectedAccounts>;

export type ConnectedAccountsRequest = {
  tag: typeof CONNECTED_ACCOUNTS_REQUEST,
};
export type TransferPointsRequest = {
  tag: typeof CONNECTED_ACCOUNTS_REQUEST,
  points: number,
  fromMember: string,
  toMember: string,
};

export const CONNECTED_ACCOUNTS_RESPONSE: "response/connected_accounts" = "response/connected_accounts";
export const CONNECTED_ACCOUNTS_REQUEST: "request/connected_accounts" = "request/connected_accounts";
export const TRANSFER_POINTS_RESPONSE: "response/transfer_points" = "response/transfer_points";
export const TRANSFER_POINTS_REQUEST: "request/transfer_points" = "request/transfer_points";

export const getConnectedAccounts = () => ({
  tag: CONNECTED_ACCOUNTS_REQUEST,
});

export const transferPoints = (points: number, fromMember: string, toMember: string) => ({
  tag: TRANSFER_POINTS_REQUEST,
  points,
  fromMember,
  toMember,
});

export const ConnectedAccountsModel: Model<
  Data,
  {},
  ConnectedAccountsRequest |
  ConnectedAccountsResponse |
  TransferPointsRequest |
  TransferPointsResponse
> = {
  id: "connected_accounts",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      getConnectedAccounts()
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case CONNECTED_ACCOUNTS_REQUEST:
        return updateData({ state: "LOADING", data: state.state !== "ERROR" ? state.data : null });
      case CONNECTED_ACCOUNTS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", data: state.data, error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        return updateNone();

      case TRANSFER_POINTS_REQUEST:
        if (state.state === "LOADED" || state.state === "TRANSFERED" || state.state === "ERROR") {
          return updateData({ state: "TRANSFERING", data: state.data }, msg);
        }

        return updateNone();

      case TRANSFER_POINTS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", data: state.data, error: msg.error });
        }

        if (msg.data && state.state === "TRANSFERING") {
          return updateData({ state: "TRANSFERED", data: msg.data });
        }

        return updateData({ state: "ERROR", data: state.data, error: "Could not transfer points" });

      default:
    }
  },
};
