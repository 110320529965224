/* eslint-disable react/no-danger */
/* @flow */

import type { CmsPage } from "shop-state/types";

import React, { useEffect, useRef, useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { Wrapper } from "@crossroads/ui-components";
import Breadcrumbs from "components/Breadcrumbs";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  cmsPage: CmsPage,
};

const toggleAccordionTitle = (e: MouseEvent) => {
  if (e.target instanceof HTMLElement) {
    const element = e.target.parentElement;

    if (element) {
      const isOpen = element.classList.contains("open");

      if (!isOpen) {
        element.classList.add("open");
      }
      else {
        element.classList.remove("open");
      }
    }
  }
};

const StripeAgreement = ({ cmsPage }: Props): React$Node => {
  const root = useRef<?HTMLDivElement>();
  const { content: { checkoutview } } = useContext(StoreInfoContext);

  useEffect(() => {
    const { current } = root;

    if (current) {
    // Bind accordions here
      const accordions = current.querySelectorAll(".cms__accordion");

      for (const e of accordions) {
        if (e) {
          const title = e.querySelector(".cms__accordionTitle");
          if (title) {
            title.addEventListener("click", toggleAccordionTitle);
          }
        }
      }
    }

    // Un-bind accordions
    return () => {
      if (current) {
        const accordions = current.querySelectorAll(".cms__accordion");
        for (const e of accordions) {
          e.removeEventListener("click", toggleAccordionTitle);
        }
      }
    };
  }, [root]);

  return (
    <Wrapper>
      <Breadcrumbs current={checkoutview.terms} className={styles.breadcrumbs} />
      <h2 className={styles.title}>{cmsPage.title}</h2>
      <div
        ref={root}
        key={cmsPage.url}
        className={cn("cms", "typography")}
        dangerouslySetInnerHTML={{ __html: cmsPage.content }}
      />
    </Wrapper>
  );
};

export default StripeAgreement;
