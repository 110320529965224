/* @flow */

import type { StatePath, Message } from "crustate";

/* eslint-disable import/no-unassigned-import */
import "core-js/features/object/assign";
import "unfetch";
import "what-input";
/* eslint-enable import/no-unassigned-import */

import "theme/_generic/_fonts.scss";
import "theme/_generic/_normalize.scss";
import "theme/_generic/_base.scss";
import "theme/_generic/_typography.scss";

// We need to initialize this early before we import any of the fetch wrappers
// or other resources to ensure RUM installs correctly:
import { datadogRum } from "@datadog/browser-rum";
import { datadog } from "config";

const datadogIsEnabled = false;

if (datadogIsEnabled && datadog) {
  datadogRum.init({
    ...datadog,
    trackInteractions: true,
  });
}

import { APP_KEY, StoreInfoContext, ClientProvider } from "entrypoint/shared";
import { registerClient } from "effects";

import { WithAnalytics } from "@crossroads/analytics";

import React from "react";
import { hydrate } from "react-dom";
import { Router } from "react-router";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import { watch } from "@crossroads/dev-server";
import { Storage } from "crustate";
import { StorageProvider } from "crustate/react";
import { TranslationProvider } from "@awardit/react-use-translate";
import translation from "translation";
import Routes from "components/Routes";
import {
  QuoteData, CustomerData, RouteData, HomeData, OrderData, CheckoutStepData, MessagesData,
  BrandData, SearchData, RedeemData, NewsPostsData, TransactionsData, LeaderboardData,
  ConnectedAccountsData, AffiliateListData, AffiliateListSelectedData, AffiliateCategoriesData,
  AffiliateData, ViewModeData, QuestionnaireListData, QuestionnaireData, DistrictInfoData,
  AllProductsData, OffersData, TinkTransactionsData, TinkProvidersData, CurrentPageInfoData,
  CurrentInfoItemData, CurrentInfoListData, ReportFormData, VerifyListData, ReportHistoryData,
  ReportListData, QuizListData, QuizData, PhotoServiceData, PhotoServiceListData, AllFormsData,
  FormRowsData, FormData, FormSubmitsData, TeampotMembersData, TeampotHistoryData,
  TeampotSettingsData, TeampotStatusData, TeampotActivitiesData, TeampotHistoryItemsData,
  QuizHistoryRowsData, QuizHistoryListData, QuizHistoryData, LeaderboardDistrictsData, CmsData,
  RegisterFormConfigData, AccountFormConfigData, DistrictListData, MemberGroupIsOwnerData,
} from "data";
import { markHydrated } from "@awardit/react-use-browser";
import { createClient, handleFetchResponse } from "@awardit/graphql-ast-client";

const prefix = window.shop.prefix ? window.shop.prefix : "";
const history = createBrowserHistory(prefix ? { basename: prefix } : {});

const storage = new Storage();

if (window.snapshot) {
  storage.addModel(QuoteData.model);
  storage.addModel(CustomerData.model);
  storage.addModel(OrderData.model);
  storage.addModel(RouteData.model);
  storage.addModel(HomeData.model);
  storage.addModel(RedeemData.model);
  storage.addModel(CheckoutStepData.model);
  storage.addModel(MessagesData.model);
  storage.addModel(BrandData.model);
  storage.addModel(SearchData.model);
  storage.addModel(NewsPostsData.model);
  storage.addModel(AffiliateCategoriesData.model);
  storage.addModel(AffiliateListData.model);
  storage.addModel(AffiliateListSelectedData.model);
  storage.addModel(AffiliateData.model);
  storage.addModel(TransactionsData.model);
  storage.addModel(LeaderboardData.model);
  storage.addModel(LeaderboardDistrictsData.model);
  storage.addModel(ReportFormData.model);
  storage.addModel(ReportListData.model);
  storage.addModel(ReportHistoryData.model);
  storage.addModel(VerifyListData.model);
  storage.addModel(ViewModeData.model);
  storage.addModel(ConnectedAccountsData.model);
  storage.addModel(QuestionnaireListData.model);
  storage.addModel(QuizListData.model);
  storage.addModel(QuizData.model);
  storage.addModel(QuizHistoryData.model);
  storage.addModel(QuizHistoryRowsData.model);
  storage.addModel(QuizHistoryListData.model);
  storage.addModel(TeampotMembersData.model);
  storage.addModel(TeampotHistoryData.model);
  storage.addModel(TeampotSettingsData.model);
  storage.addModel(TeampotStatusData.model);
  storage.addModel(TeampotActivitiesData.model);
  storage.addModel(TeampotHistoryItemsData.model);
  storage.addModel(QuestionnaireData.model);
  storage.addModel(DistrictInfoData.model);
  storage.addModel(DistrictListData.model);
  storage.addModel(AllProductsData.model);
  storage.addModel(OffersData.model);
  storage.addModel(TinkTransactionsData.model);
  storage.addModel(TinkProvidersData.model);
  storage.addModel(CurrentPageInfoData.model);
  storage.addModel(CurrentInfoListData.model);
  storage.addModel(CurrentInfoItemData.model);
  storage.addModel(PhotoServiceData.model);
  storage.addModel(PhotoServiceListData.model);
  storage.addModel(AllFormsData.model);
  storage.addModel(FormRowsData.model);
  storage.addModel(FormData.model);
  storage.addModel(FormSubmitsData.model);
  storage.addModel(CmsData.model);
  storage.addModel(RegisterFormConfigData.model);
  storage.addModel(AccountFormConfigData.model);
  storage.addModel(MemberGroupIsOwnerData.model);
  storage.restoreSnapshot(window.snapshot);

  storage.restoreSnapshot(window.snapshot);
  delete window.snapshot;
}

const redirectOnGraphQLUnAuthenticated = x => {
  if (x.errors && x.errors.find(x => x.extensions && x.extensions.code === "UNAUTHENTICATED")) {
    document.location.href = "/";
  }
};

// TODO: Cached client
const client = createClient({
  runQuery: async request => {
    try {
      const res = await fetch(prefix + "/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      });

      const body = await handleFetchResponse(res);

      redirectOnGraphQLUnAuthenticated(body);

      return body;
    }
    catch (e) {
      if (e.name === "RequestError") {
        try {
          const body = JSON.parse(e.bodyText);
          redirectOnGraphQLUnAuthenticated(body);
        }
        catch (_) {
        }
      }

      throw e;
    }
  },
  debounce: 5,
});

registerClient(storage, client, history);

window.storage = storage;
window.getState = storage.getSnapshot.bind(storage);

const events = {
  // unhandledMessage: "warn",
  // stateCreated: "info",
  // stateRemoved: "info",
  // stateNewData: "info",
  // snapshotRestore: "info",
  // messageQueued: "info",
  // messageMatched: "debug",
  // snapshotRestored: "debug",
};

Object.keys(events).forEach(eventName => {
  const level = events[eventName];
  storage.addListener((eventName: any), (...data) => console[level](eventName, ...data));
});

if (datadogIsEnabled && datadog) {
  storage.addListener("updateError", (
    e: mixed,
    stateData: mixed,
    statePath: StatePath,
    message: Message
  ) => datadogRum.addError(e, { statePath, message }));

  storage.addListener("unhandledMessage", (
    message: Message,
    statePath: StatePath
  ) => datadogRum.addError(new Error(`Unhandled message '${message.tag}' from ${statePath.join(", ")}`), { statePath, message }));
}

const root = document.getElementById(APP_KEY);

if (!root) {
  throw new Error("Missing app root");
}

// Boot up watch-process in dev mode to make sure we reload
if (process.env.NODE_ENV !== "production") {
  watch();
}

const currencyCode = window.shop.info.baseCurrencyCode;
const analyticsId = window.shop.info.analytics;
const isB2B = true;

hydrate(
  <StoreInfoContext.Provider value={window.shop}>
    <ClientProvider value={client}>
      <HelmetProvider>
        <TranslationProvider translations={translation || {}}>
          <StorageProvider storage={storage}>
            <QuoteData.Provider>
              <CustomerData.Provider>
                <CurrentPageInfoData.Provider>
                  <MessagesData.Provider>
                    <Router history={history}>
                      <WithAnalytics
                        accounts={analyticsId}
                        currencyCode={currencyCode}
                        isB2b={isB2B}
                      >
                        <Routes />
                      </WithAnalytics>
                    </Router>
                  </MessagesData.Provider>
                </CurrentPageInfoData.Provider>
              </CustomerData.Provider>
            </QuoteData.Provider>
          </StorageProvider>
        </TranslationProvider>
      </HelmetProvider>
    </ClientProvider>
  </StoreInfoContext.Provider>,
  root,
  () => {
    markHydrated();
  }
);
