/* @flow */

/*
  This component is used on step 2 and 3, with the selected points
*/

import type { QuotePointPayment } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useData } from "crustate/react";
import useFormat from "helpers/use-format";
import { getQuoteData } from "state/quote";
import { StoreInfoContext } from "entrypoint/shared";
import { QuoteData, CustomerData } from "data";
import { Box, BoxHeader, BoxBody } from "components/Box";
import ChevronIcon from "icons/chevron-small.svg";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  open: boolean,
  setOpen: boolean => void,
  className?: string,
  selectedPointPayment?: QuotePointPayment,
};

const CartSummary = ({
  children,
  open,
  setOpen,
  className,
  selectedPointPayment,
}: Props) => {
  const toggleOpen = () => setOpen(!open);
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData);
  const customerData = useData(CustomerData);
  const { content: { checkoutview: { displayExVat } } } = useContext(StoreInfoContext);

  if (!quote || !customerData.data || selectedPointPayment === undefined) {
    return null;
  }

  const shippingCost = selectedPointPayment.shipping?.points.value.exVat || 0;

  const showShippingInfo = shippingCost > 0.01;
  const currencyPaymentAvailable = selectedPointPayment.currency.max.incVat !== 0;
  const showDistribution = currencyPaymentAvailable &&
    selectedPointPayment.points.value.exVat > 0 &&
    selectedPointPayment.currency.remaining.incVat > 0;
  const isExpandable = showDistribution;

  if (open && !isExpandable) {
    setOpen(false);
  }

  return (
    <div className={cn(
      styles.block,
      {
        [styles.open]: open,
        [styles.processing]: quoteData.state === "UPDATING_ITEM",
      },
      className
    )}
    >
      <Box>
        <BoxHeader className={styles.header} onClick={isExpandable ? toggleOpen : null}>
          <h2>{t("CART.SUMMARY")}</h2>
          {isExpandable &&
            <ChevronIcon className={styles.closeIcon} />
          }
        </BoxHeader>
        <BoxBody className={styles.body}>
          <Foldable open={open}>
            <section className={styles.foldable}>
              <table>
                <tbody>
                  <tr>
                    <td className={styles.tableSection}><span>{t("CART.PRODUCTS")}</span></td>
                    <td className={styles.tableSection}>
                      <span>{formatPoints(selectedPointPayment.points.value.exVat)}</span>
                    </td>
                  </tr>
                  <tr>
                    {showShippingInfo &&
                    <>
                      <td className={styles.tableSection}>
                        <span>{t("CART.SHIPPING")}</span>
                      </td>
                      <td className={styles.tableSection}>
                        <span>
                          {formatPoints(shippingCost)}
                        </span>
                      </td>
                    </>
                    }
                  </tr>
                </tbody>
              </table>

              {showShippingInfo && showDistribution &&
                <div className={styles.separator} />
              }

              {showDistribution &&
                <table>
                  <tbody>
                    {showShippingInfo &&
                      <tr>
                        <td>{t("CART.SUBTOTAL")}</td>
                        <td>{formatPoints(selectedPointPayment.points.value.exVat)}</td>
                      </tr>
                    }
                    <tr>
                      <td className={styles.tableSection}>{t("CHECKOUT.PAYMENT_DISTRIBUTION")}</td>
                      <td className={styles.tableSection}>
                        <span>
                          {formatPoints(selectedPointPayment.points.selected.exVat)}
                        </span>
                        {currencyPaymentAvailable &&
                          selectedPointPayment.currency.remaining.incVat >= 0.01 &&
                          <>
                            <br />
                            <span>{`${t("CHECKOUT.AND")} ${formatPrice(selectedPointPayment.currency.remaining.incVat)}`}</span>
                          </>
                        }
                      </td>
                    </tr>
                  </tbody>
                </table>
              }
            </section>
          </Foldable>

          {open && <div className={styles.separatorFull} />}

          <section className={styles.footer}>
            <table>
              <tbody>
                {displayExVat !== undefined &&
                displayExVat !== null &&
                displayExVat === true &&
                  <tr>
                    <td className={styles.tableSection}>{t("CHECKOUT.SUBTOTAL")}</td>
                    <td className={styles.tableSection}>
                      {formatPoints(selectedPointPayment.points.selected.exVat)}
                    </td>
                  </tr>
                }

                <tr>
                  <td className={styles.tableSection}>{t("CART.GRANDTOTAL")}</td>
                  <td className={styles.tableSection}>
                    {selectedPointPayment.points.selected.exVat >= 0.01 &&
                      <span>
                        {formatPoints(selectedPointPayment.points.selected.exVat)}
                      </span>
                    }
                    {selectedPointPayment.currency.remaining.incVat >= 0.01 &&
                      selectedPointPayment.points.selected.exVat >= 0.01 &&
                      <>
                        <br />
                        <span>{t("CHECKOUT.AND")}&nbsp;</span>
                      </>
                    }
                    {currencyPaymentAvailable &&
                      selectedPointPayment.currency.remaining.incVat >= 0.01 &&
                      <span>{formatPrice(selectedPointPayment.currency.remaining.incVat)}</span>
                    }
                  </td>
                </tr>

                <tr>
                  <td>
                    <h3 className={styles.heading}>{t("CHECKOUT.AMOUNT_TO_PAY")}</h3>
                  </td>
                  <td className={styles.totalRow}>
                    {selectedPointPayment.points.selected.exVat > 0 &&
                      <strong>{formatPoints(selectedPointPayment.points.selected.exVat)}</strong>
                    }

                    {!currencyPaymentAvailable &&
                    selectedPointPayment.points.selected.exVat <= 0 && (
                      <strong>{formatPoints(selectedPointPayment.points.max.exVat)}</strong>
                    )}

                    {selectedPointPayment.currency.remaining.incVat > 0 &&
                      currencyPaymentAvailable &&
                      <strong>
                        {selectedPointPayment.points.selected.exVat > 0 &&
                          selectedPointPayment.currency.remaining.incVat > 0 &&
                          <>
                            <br />
                            <span>{t("CHECKOUT.AND")}&nbsp;</span>
                          </>
                        }

                        {formatPrice(selectedPointPayment.currency.remaining.incVat)}
                      </strong>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </section>

          {children}
        </BoxBody>
      </Box>
    </div>
  );
};

CartSummary.defaultProps = {
  className: "",
};

export default CartSummary;
