/* @flow */

import { useEffect } from "react";
import { useSendMessage } from "crustate/react";
import { useClient } from "entrypoint/shared";
import { quotePointsSetToMaximum } from "queries";
import { syncQuote } from "@crossroads/shop-state/quote";

const useQuotePointsSetToMaximum = () => {
  const client = useClient();
  const sendMessage = useSendMessage();

  useEffect(() => {
    (async () => {
      const { quotePointsSetToMaximum: { quote } } =
        await client(quotePointsSetToMaximum, { id: "awardit" });

      if (quote) {
        sendMessage(syncQuote(quote));
      }
    })();
  }, []);
};

export default useQuotePointsSetToMaximum;
