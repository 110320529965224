/* @flow */

import React from "react";
import useBrowserDimensions from "helpers/use-browser-dimensions";
import { Carousel, Combined } from "@crossroads/ui-components";
import ArrowIcon from "icons/carousel-arrow.svg";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  items: Array<React$Node>,
  loopAround?: boolean,
  autoplay?: boolean,
  adaptiveHeight?: boolean,
  adaptiveHeightLimit?: number,
  limitScrollIndex?: boolean,
  dragThreshold?: number,
  timer?: number,
  slidesToShow?: number,
  children?: React$Node,
};

const medium = Number.parseInt(styles.medium, 10);

const CustomCarousel = ({
  className,
  items,
  children,
  slidesToShow = 4,
  autoplay = true,
  ...rest
}: Props) => {
  const { width: browserWidth } = useBrowserDimensions();
  const desktop = browserWidth >= medium;

  if (browserWidth === 0) {
    return (
      <div className={cn(className, styles.containerServer)}>
        {items}
      </div>
    );
  }

  if (items.length === 1 || (desktop && items.length > 1 && items.length <= slidesToShow)) {
    return (
      <div className={cn(className, styles.container)}>
        {items}
      </div>
    );
  }

  return (
    <Carousel
      {...rest}
      autoplay={autoplay}
      className={className}
      items={items}
      slidesToScroll={slidesToShow}
      slidesToShow={slidesToShow}
    >
      <Combined icon={<ArrowIcon />} className={styles.carouselControls} />
    </Carousel>
  );
};

export default CustomCarousel;
