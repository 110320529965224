/* @flow */

import * as React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  content: string,
};

export const Description = ({ content, className = "", ...props }: Props) => {
  return (
    <span
      {...props}
      /* eslint-disable react/no-danger */
      dangerouslySetInnerHTML={{ __html: content }}
      /* eslint-enable react/no-danger */
      className={cn(styles.description, className)}
    />
  );
};
