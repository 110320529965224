/* @flow */

import type { TeamPotHistoryItem } from "shop-state/types";
import React from "react";
import cn from "classnames";
import Spinner from "components/Spinner";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import UsersModal from "../UsersModal";

import styles from "./styles.scss";

type historyItem = {
  commentar: string,
  countmem: string,
  datum: string,
  sumpoints: string,
  sumrank: string,
  description: string,
};

type TableLargeProps = {
  items: $ReadOnlyArray<historyItem>,
  users: $ReadOnlyArray<TeamPotHistoryItem>,
  onUsersClick: string => void,
};

const TableLargeHead = () => {
  const t = useTranslate();

  return (
    <thead>
      <tr>
        <th>{t("MOTIVATION_POT.ACTIVITY")}</th>
        <th>{t("MOTIVATION_POT.DATE")}</th>
        <th>{t("MOTIVATION_POT.SHARED_POINTS")}</th>
        <th>{t("MOTIVATION_POT.MEMBER_COUNT")}</th>
        <th>{t("MOTIVATION_POT.COMMENT")}</th>
      </tr>
    </thead>
  );
};

const TableLarge = ({ items, users, onUsersClick }: TableLargeProps) => {
  const { formatPoints } = useFormat(false);

  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead />
        <tbody>
          {items.map((x, i) => {
            const pointsOrRank = parseInt(x.sumrank, 10) > 0 ? x.sumrank : x.sumpoints;
            return (
              <tr key={`${i}-${x.datum}`}>
                <td>{x.description}</td>
                <td className={styles.noWrap}>{x.datum}</td>
                <td>{formatPoints(parseInt(pointsOrRank, 10))}</td>
                <td>
                  <UsersModal
                    users={users}
                    countmem={x.countmem}
                    date={x.datum}
                    onClickUsers={onUsersClick}
                  />
                </td>
                <td className={styles.maxWidthChar}>{x.commentar}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.spacer} />
    </div>
  );
};

export const TableLargeHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
