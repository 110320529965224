/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { OrderRequest, OrderResponse } from "state/order";

import { ORDER_REQUEST, ORDER_RESPONSE } from "state/order";
import { lastOrder } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: (msg: OrderRequest, path) => {
      switch (msg.type) {
        case "order":
          return client(lastOrder)
            .then(data => storage.replyMessage(({
              tag: ORDER_RESPONSE,
              data: data && data.lastOrder,
            }: OrderResponse), path));

        default:
      }
    },
    subscribe: { [ORDER_REQUEST]: true },
  });
};

export default registerClient;
