/* @flow */

import type { PhotoServiceListItem } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: ?Array<PhotoServiceListItem> }
  | { state: "LOADED", data: Array<PhotoServiceListItem> }
  | { state: "ERROR", error: string };

export type PhotoServiceListResponse =
  Response<typeof PHOTO_SERVICE_LIST_RESPONSE, Array<PhotoServiceListItem>>;

export type ImagePostRequest = {
    tag: typeof PHOTO_SERVICE_LIST_REQUEST,
};

export const PHOTO_SERVICE_LIST_REQUEST: "request/photo_service_list" = "request/photo_service_list";
export const PHOTO_SERVICE_LIST_RESPONSE: "response/photo_service_list" = "response/photo_service_list";

export const getPhotoServiceList = () => ({
  tag: PHOTO_SERVICE_LIST_REQUEST,
});

export const PhotoServiceListModel: Model<
  Data, {}, PhotoServiceListResponse | EffectErrorMessage
> = {
  id: "photo_service_list",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: PHOTO_SERVICE_LIST_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case PHOTO_SERVICE_LIST_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
