/* @flow */

import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import Wrapper from "components/Wrapper";
import Back from "components/AccountView/Back";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";
import Header from "components/AccountView/Header";
import cn from "classnames";
import styles from "./styles.scss";

const AccountingRecordsView = () => {
  const t = useTranslate();
  const {
    content: {
      transactionsview,
      accountingRecordsView,
    } } = useContext(StoreInfoContext);
  const [currentDate, setCurrentDate] = useState();
  const [link, setLink] = useState();
  const [dateItems, setDateItems] = useState(null);

  useEffect(() => {
    getDates();
  }, []);

  const getMonths = (fromDate, toDate) => {
    const fromYear = fromDate.getFullYear();
    const fromMonth = fromDate.getMonth() + 1;
    const toYear = toDate.getFullYear();
    const toMonth = toDate.getMonth();
    const months = [];

    for (let year = fromYear; year <= toYear; year++) {
      let month = year === fromYear ? fromMonth : 1;
      let monthLimit = 0;

      if (toMonth === 0) {
        monthLimit = year === toYear ? toMonth - 1 : 12;
      }
      else {
        monthLimit = year === toYear ? toMonth : 12;
      }

      for (; month <= monthLimit; month++) {
        months.push({ date: `${t(`MONTHS.${month}`)} ${year}`, value: `${year}-${String(month).padStart(2, "0")}` });
      }
    }

    return months;
  };

  const setInitialData = value => {
    const date = new Date(value);
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    setCurrentDate(value);
    setLink(`/serv/zeroinvoice?y=${year}&m=${month}`);
  };

  const validDateFormat = input => {
    return /^([12]\d{3}-(0[1-9]|1[0-2]))$/.test(input);
  };

  const getDates = () => {
    const date = new Date();
    const currentMonth = date.getMonth() + 1;
    const currentYear = date.getFullYear();

    const matchDateFormat = validDateFormat(accountingRecordsView.startDate ?? "");

    const availableDates = getMonths(
      new Date(matchDateFormat && accountingRecordsView.startDate ? accountingRecordsView.startDate : "2022-01"),
      new Date(`${currentYear}-${String(currentMonth).padStart(2, "0")}`));

    if (availableDates.length > 0) {
      setInitialData(availableDates[availableDates.length - 1].value);
    }

    setDateItems(availableDates);
  };

  const changeHandler = value => {
    const date = new Date(String(value));
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    setCurrentDate(value);
    setLink(`/serv/zeroinvoice?y=${year}&m=${month}`);
  };

  return (
    <Wrapper variant="pageWrapper" className={cn("awardit-pageWrapper", styles.wrapper)}>
      <Helmet
        title={transactionsview.heading}
      />
      <Back />
      <Header subHeader={t("RECORDS.ACCOUNTING_RECORDS")} />
      {/* eslint-disable react/no-danger */}
      {accountingRecordsView.text !== undefined && accountingRecordsView.text !== null ? (
        <p dangerouslySetInnerHTML={{ __html: accountingRecordsView.text }} />
      ) : null }
      {/* eslint-enable react/no-danger */}
      {dateItems && dateItems.length >= 1 ? (
        <div className={styles.selectField}>
          <Dropdown
            name="date"
            value={currentDate}
            className={styles.dropdown}
            onChange={value => {
              changeHandler(value);
            }}
          >
            {dateItems.map(item => (
              <DropdownItem key={item.date} value={item.value}>
                {item.date}
              </DropdownItem>
            ))}
          </Dropdown>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.recordsButton}
          >
            {t("RECORDS.BUTTON")}
          </a>
        </div>
      ) :
        <p>{t("RECORDS.EMPTY")}</p>
      }
    </Wrapper>
  );
};

export default AccountingRecordsView;

