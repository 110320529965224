/* @flow */

import type { ProductSort } from "shop-state/types";

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { Dropdown, DropdownItem } from "@crossroads/ui-components";

type Props = {
  items: ?Array<ProductSort>,
  value: ?string,
  onChange: (o: any) => void,
  className?: string,
  variant?: string,
};

const DropdownSort = ({ items, value, onChange, className, variant = "dropdownSort" }: Props) => {
  const t = useTranslate();

  return (
    <Dropdown
      className={className}
      placeholder={t("FILTER.FILTERBAR.SORT")}
      value={value}
      variant={variant}
      onChange={onChange}
    >
      {Array.isArray(items) && items.map(item => (
        <DropdownItem
          key={item.code}
          value={item.code}
        >
          {item.label}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};

export default DropdownSort;
