/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  DistrictListRequest,
  DistrictListResponse,
} from "state/district-list";

import {
  DISTRICT_LIST_REQUEST,
  DISTRICT_LIST_RESPONSE,
} from "state/district-list";

import { getDistrictList } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: DistrictListRequest) => {
      try {
        const data = await client(
          getDistrictList,
          { partner: msg.partner },
          { cache: true }
        );

        return ({
          tag: DISTRICT_LIST_RESPONSE,
          data,
        }: DistrictListResponse);
      }
      catch (e) {
        console.error(e);
      }
    },
    subscribe: { [DISTRICT_LIST_REQUEST]: true },
  });
};

export default registerClient;
