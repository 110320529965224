/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { AllProductsRequest, AllProductsResponse } from "@crossroads/shop-state/all-products";

import { ALL_PRODUCTS_REQUEST, ALL_PRODUCTS_RESPONSE } from "@crossroads/shop-state/all-products";
import { allProducts } from "queries";

import { PAGE_SIZE } from "./route";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: AllProductsRequest) => {
      const { products } = await client(allProducts, {
        filter: msg.filters,
        sort: msg.sort,
        page: msg.page !== null ? msg.page : 1,
        pageSize: PAGE_SIZE,
      }, { cache: true });

      return ({
        tag: ALL_PRODUCTS_RESPONSE,
        data: products,
      }: AllProductsResponse);
    },
    subscribe: { [ALL_PRODUCTS_REQUEST]: true },
  });
};

export default registerClient;
