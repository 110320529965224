/* @flow */

import type { Form, FormRow, FormSubmitsDetails } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type RowData =
| { state: "LOADING", data: ?Array<FormRow> }
| { state: "LOADED", data: Array<FormRow> }
| { state: "ERROR", error: string };
type AllFormsData =
| { state: "LOADING", data: ?Array<Form> }
| { state: "LOADED", data: Array<Form> }
| { state: "ERROR", error: string };
type FormData =
| { state: "LOADING", data: ?Form }
| { state: "LOADED", data: Form }
| { state: "ERROR", error: string };
type FormSubmitsData =
| { state: "LOADING", data: ?Array<FormSubmitsDetails> }
| { state: "LOADED", data: Array<FormSubmitsDetails> }
| { state: "ERROR", error: string };

/* FORM ROWS */
export type FormRowsEffectResponse =
Response<typeof FORM_ROWS_RESPONSE, Array<FormRow>>;

export type FormRowsEffectRequest = {
  tag: typeof FORM_ROWS_REQUEST,
  id: number,
};

type FormRowsRequest = {
  tag: typeof FORM_ROWS_REQUEST,
  id: number,
};

/* ALL FORMS */
export type AllFormsEffectResponse =
Response<typeof ALL_FORMS_RESPONSE, Array<Form>>;

export type AllFormsEffectRequest = {
  tag: typeof ALL_FORMS_REQUEST,
};

type AllFormsRequest = {
  tag: typeof ALL_FORMS_REQUEST,
};

/* ONE FORM */
export type FormEffectResponse =
Response<typeof FORM_RESPONSE, Form>;

export type FormEffectRequest = {
  tag: typeof FORM_REQUEST,
  id: number,
};

type FormRequest = {
  tag: typeof FORM_REQUEST,
  id: number,
};

/* ALL SUBMITS */
export type FormSubmitsEffectResponse =
Response<typeof FORM_SUBMITS_RESPONSE, Array<FormSubmitsDetails>>;

export type FormSubmitsEffectRequest = {
  tag: typeof FORM_SUBMITS_REQUEST,
  id: number,
};
type FormSubmitsRequest = {
  tag: typeof FORM_SUBMITS_REQUEST,
  id: number,
};

export const FORM_ROWS_RESPONSE: "response/form_rows" = "response/form_rows";
export const FORM_ROWS_REQUEST: "request/form_rows" = "request/form_rows";

export const ALL_FORMS_RESPONSE: "response/all_forms" = "response/all_forms";
export const ALL_FORMS_REQUEST: "request/all_forms" = "request/all_forms";

export const FORM_RESPONSE: "response/form" = "response/form";
export const FORM_REQUEST: "request/form" = "request/form";

export const FORM_SUBMITS_RESPONSE: "response/form_submits" = "response/form_submits";
export const FORM_SUBMITS_REQUEST: "request/form_submits" = "request/form_submits";

export const getFormRows = (id: number | null) => {
  return ({
    tag: FORM_ROWS_REQUEST,
    id,
  });
};

export const getForm = (id: number | null) => {
  return ({
    tag: FORM_REQUEST,
    id,
  });
};

export const getFormSubmits = (id: number | null) => {
  return ({
    tag: FORM_SUBMITS_REQUEST,
    id,
  });
};

export const AllFormsModel: Model<
  AllFormsData,
  {},
  AllFormsEffectRequest | AllFormsEffectResponse | AllFormsRequest
> = {
  id: "all_forms",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: ALL_FORMS_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: "Error in forms state" });

      case ALL_FORMS_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: ALL_FORMS_REQUEST });

      case ALL_FORMS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const FormModel: Model<
  FormData,
  { id: number | null },
  FormEffectRequest | FormEffectResponse | FormRequest
> = {
  id: "form",
  init: ({ id }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: FORM_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: "Error in forms state" });

      case FORM_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: FORM_REQUEST, id: msg.id });

      case FORM_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const FormRowsModel: Model<
  RowData,
  { id: number | null },
  FormRowsEffectRequest | FormRowsEffectResponse | FormRowsRequest
> = {
  id: "form_rows",
  init: ({ id }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: FORM_ROWS_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: "Error in formrows state" });

      case FORM_ROWS_REQUEST:
        return updateData({ state: "LOADING", data: state.state === "ERROR" ? null : state.data }, { tag: FORM_ROWS_REQUEST, id: msg.id });

      case FORM_ROWS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};

export const FormSubmitsModel: Model<
  FormSubmitsData,
  { id: number | null },
  FormSubmitsEffectRequest | FormSubmitsEffectResponse | FormSubmitsRequest
> = {
  id: "form_submits",
  init: ({ id }) =>
    updateData(
      { state: "LOADING", data: null },
      { tag: FORM_SUBMITS_REQUEST, id }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: "Error in forms state" });

      case FORM_SUBMITS_REQUEST:
        return updateData(
          { state: "LOADING", data: state.state === "ERROR" ? null : state.data },
          { tag: FORM_SUBMITS_REQUEST, id: msg.id }
        );

      case FORM_SUBMITS_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
