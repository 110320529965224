/* @flow */

import { useTranslate } from "@awardit/react-use-translate";
import { CustomerData } from "data";
import { useData } from "crustate/react";
import { getCustomerData } from "helpers/utils";
import { pointsPriceByID } from "helpers/points";

// Finds groups of three digits in a number.
const MATCH_NUMBER_SET = /\B(?=(\d{3})+(?!\d))/g;

// Formats a number-as-string into 12 345.67 format.
export const formatNumber = (n: string): string =>
  n.replace(".", ",").replace(MATCH_NUMBER_SET, " ");

const useFormat = (usePostfix: boolean = true) => {
  const t = useTranslate();
  const customer = getCustomerData(useData(CustomerData));
  const pointsObj = customer ? pointsPriceByID(customer.points, "awardit") : null;

  return {
    formatPrice: (price: number) => {
      // Max 2 decimals, and skip any trailing zeroes
      const fixedPrice = Number.isInteger(price) ? Number(price) : price.toFixed(2);
      return formatNumber(fixedPrice.toString()) + (usePostfix ? ` ${t("LOCALE.CURRENCY_SHORT")}` : "");
    },
    // default label should be points or poäng
    formatPoints: (points: number) =>
      formatNumber(points != null ? points.toFixed(0) : "") +
        (usePostfix ? ` ${pointsObj ? pointsObj.label.toLowerCase() : t("LOCALE.POINTS")}` : ""),
  };
};

export default useFormat;
