/* @flow */

import type { CurrentInfoRoute } from "shop-state/types";

import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useClient } from "entrypoint/shared";
import { currentInfoTagList } from "queries";
import { parseParams } from "@crossroads/location-search-string";
import { useParseTags } from "helpers/use-parse-tags";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  route: CurrentInfoRoute,
  selectedTags: Array<string>,
  setSelectedTags: (Array<string>) => void,
};

const TagsFilter = ({ route, selectedTags, setSelectedTags }: Props) => {
  const client = useClient();
  const location = useLocation();
  const history = useHistory();
  const t = useTranslate();
  const queryParams = parseParams(location.search);
  const parsedTags = useParseTags(queryParams);
  const [tagsArray, setTagsArray] = useState([]);

  useEffect(() => {
    if (parsedTags && Array.isArray(parsedTags) && parsedTags.length > 0) {
      setSelectedTags(parsedTags);
    }
  }, []);

  useEffect(() => {
    if (selectedTags.length > 0) {
      history.push(`${route.url}?tags=${selectedTags.join(";")}`);
    }
    else {
      history.push(`${route.url}`);
    }
  }, [selectedTags]);

  useEffect(() => {
    client(currentInfoTagList, { page: route.key })
      .then(({ currentInfoTagList }) => {
        if (currentInfoTagList.result === "SUCCESS") {
          setTagsArray(currentInfoTagList.data);
        }
      });
  }, [client]);

  const toggleTags = (tagname: string) => {
    if (selectedTags.includes(tagname)) {
      setSelectedTags(selectedTags.filter(tag => tag !== tagname));
    }
    else {
      setSelectedTags([...selectedTags, tagname]);
    }
  };

  const isActive = (tagname: string) => {
    if (selectedTags.includes(tagname) || parsedTags?.includes(tagname)) {
      return true;
    }

    return false;
  };

  if (!tagsArray || tagsArray.length === 0) {
    return null;
  }

  return (
    <div className={styles.tagsFilter}>
      <Button
        className={cn(styles.tag, {
          [styles.active]: selectedTags.length === 0 && (!parsedTags),
        })}
        to={`${route.url}`}
        onClick={() => setSelectedTags([])}
      >
        {t("COMMON.SEE_ALL")}
      </Button>
      {tagsArray.map((tag, i) => (
        <Button
          key={`${route.key}-${i}`}
          className={cn(styles.tag, {
            [styles.active]: isActive(tag.tagname),
          })}
          onClick={() => toggleTags(tag.tagname)}
        >
          {tag.tagname}
        </Button>
      ))}
    </div>
  );
};

export default TagsFilter;
