/* @flow */

/*
  This component is only used on cart and shows the possible payments
*/

import type { Quote } from "shop-state/types";

import React from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import { Box, BoxHeader, BoxBody } from "components/Box";
import PriceSplit, { PriceSplitSummary } from "components/PriceSplit";
import { pointsPriceByID } from "helpers/points";
import ChevronIcon from "icons/chevron-small.svg";

import styles from "./styles.scss";

type Props = {
  children?: React$Node,
  open: boolean,
  setOpen: boolean => void,
  className?: string,
  canAfford?: boolean,
  missingPoints?: number,
  quoteData: { +state: string, +data: Quote },
};

const CartSummary = ({
  children,
  open,
  setOpen,
  className,
  canAfford = true,
  missingPoints = 0,
  quoteData,
}: Props) => {
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const quote = quoteData.data;
  const { selectedPointPayment, availablePointPayments } = quote;

  const pointPayment = selectedPointPayment || pointsPriceByID(availablePointPayments, "awardit");

  const toggleOpen = () => setOpen(!open);

  if (!pointPayment) {
    return null;
  }

  const shipping = pointPayment.shipping?.points.value.exVat || 0;
  const isExpandable = Boolean(shipping > 0);

  if (open && !isExpandable) {
    setOpen(false);
  }

  return (
    <div className={cn(
      styles.block,
      {
        [styles.open]: open,
        [styles.processing]: quoteData.state === "UPDATING_ITEM",
      },
      className
    )}
    >
      <Box>
        <BoxHeader className={styles.header} onClick={isExpandable ? toggleOpen : null}>
          <h2>{t("CART.SUMMARY")}</h2>

          {isExpandable &&
            <ChevronIcon className={styles.closeIcon} />
          }
        </BoxHeader>
        <BoxBody className={styles.body}>
          <Foldable open={open}>
            <section className={styles.foldable}>
              <table>
                <tbody>
                  <tr>
                    <td className={styles.tableSection}><span>{t("CART.PRODUCTS")}</span></td>
                    <td className={styles.tableSection}>
                      <PriceSplit pointsPrice={pointPayment} />
                    </td>
                  </tr>
                  {shipping > 0 &&
                    <tr>
                      <td className={styles.tableSection}>
                        <span>{t("CART.SHIPPING")}</span>
                      </td>
                      <td className={styles.tableSection}>
                        <span>
                          {formatPoints(shipping)}
                        </span>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </section>
          </Foldable>

          {open && <div className={styles.separatorFull} />}

          <section className={styles.footer}>
            <table>
              <tbody>

                {canAfford &&
                  <tr>
                    <td className={styles.tableSection}>{t("CART.GRANDTOTAL")}</td>
                    <td className={styles.tableSection}>
                      <PriceSplitSummary
                        pointsPrice={pointPayment}
                        className={styles.split}
                      />
                    </td>
                  </tr>
                }

                {!canAfford &&
                  <tr>
                    <td className={styles.tableSection}>{t("CART.INSUFFICIENT_FUNDS_SUMMARY")}</td>
                    <td className={styles.tableSection}>{formatPoints(missingPoints)}</td>
                  </tr>
                }

              </tbody>
            </table>
          </section>

          {children}
        </BoxBody>
      </Box>
    </div>
  );
};

CartSummary.defaultProps = {
  className: "",
};

export default CartSummary;
