/* @flow */

import type { Customer } from "shop-state/types";
import type { Mode } from "state/view-mode";

import React, { useState, useEffect, useContext } from "react";
import { Route, Switch } from "react-router";
import { Link, NavLink, useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { useData } from "crustate/react";
import { QuoteData } from "data";
import { CartMini } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import Questionnaires from "components/AppHeader/Large/SubMenu/questionnaires";
import cn from "classnames";
import HamburgerIcon from "icons/hamburger.svg";
import CloseIcon from "icons/close-large.svg";
import CartIcon from "icons/cart.svg";
import ChevronIcon from "icons/chevron-small.svg";
import SystemMessages from "components/SystemMessages";
import CustomerBadge from "components/CustomerBadge";
import useFormat from "helpers/use-format";
import { getCustomerActiveBalance } from "helpers/points";
import { navRoutes } from "helpers/utils";
import { ROUTES } from "components/AppHeader/routes";
import { MODE } from "state/view-mode";
import Redeem from "components/AppHeader/Small/Redeem";
import Earn from "components/AppHeader/Small/Earn";
import Logo, { SecondaryLogo } from "components/AppHeader/Logo";
import AdminButton from "components/AccountView/AdminButton";

import styles from "./styles.scss";

type Props = {
  className: string,
  customer: ?Customer,
  mode: Mode,
  setMode: Mode => void,
  subNavOpen: boolean,
  setSubNavOpen: boolean => void,
  hamburgerOpen: boolean,
  setHamburgerOpen: boolean => void,
  lastVisited: ?string,
  isScrolled?: boolean,
  onCheckout: boolean,
  onSuccess: boolean,
  cartCount: number,
  logoWidth: string,
};

const AppHeaderSmall = ({
  className = "",
  mode,
  setMode,
  subNavOpen,
  setSubNavOpen,
  hamburgerOpen,
  setHamburgerOpen,
  lastVisited,
  customer,
  isScrolled,
  onCheckout,
  onSuccess,
  cartCount,
  logoWidth,
}: Props) => {
  const t = useTranslate();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const {
    routes,
    configuration: { helpdeskTitle, helpdeskLink },
    content: { accountview, tinkview },
  } = storeInfo;
  const { formatPoints } = useFormat();
  const [isOpening, setIsOpening] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const toggleHamburger = () => hamburgerOpen ? closeHamburger() : openHamburger();
  const closeMenu = () => setMode(MODE.NORMAL);
  const history = useHistory();
  const showAdminButton = Boolean(accountview.showAdministration) &&
    customer &&
    customer.awardit.userType !== null &&
    customer.awardit.userType !== undefined ?
    customer.awardit.userType > 0 :
    false;

  let UPDATEDROUTES = null;
  if (tinkview.hideInNavbar !== undefined &&
    tinkview.hideInNavbar !== null &&
    tinkview.hideInNavbar === true) {
    UPDATEDROUTES = ROUTES.filter(item => item !== "accountTinkView");
  }

  const nav = navRoutes(UPDATEDROUTES ? UPDATEDROUTES : ROUTES, storeInfo);
  const questionnaireViewUrl = routes.questionnaireView && routes.questionnaireView.url ? routes.questionnaireView.url : "";

  const QUESTIONNAIRE_ROUTES = [
    questionnaireViewUrl,
    questionnaireViewUrl + "/:slug",
  ];

  const openHamburger = () => {
    setMode(MODE.NORMAL);
    setIsOpening(true);
    setHamburgerOpen(true);

    setTimeout(() => {
    }, parseInt(styles.animationDuration, 10));
  };

  const closeHamburger = () => {
    setIsClosing(true);
    setSubNavOpen(false);

    setTimeout(() => {
      setHamburgerOpen(false);
      setIsClosing(false);
      setMode(MODE.NORMAL);
    }, parseInt(styles.animationDuration, 10));
  };

  useEffect(() => {
    if (mode === MODE.NORMAL || mode === MODE.CART) {
      setHamburgerOpen(false);
    }
  }, [mode, setHamburgerOpen]);

  const setModeAndOpenMenu = (mode: Mode) => {
    setMode(mode);
    setSubNavOpen(true);
  };

  if (!customer) {
    return null;
  }

  const customerBalance = getCustomerActiveBalance(customer);
  const formattedBalance = formatPoints(customerBalance);

  return (
    <>
      <div className={styles.outerContainer}>
        <div className={cn(
          styles.container,
          styles["mode__" + mode],
          { [styles.hamburgerOpen]: hamburgerOpen },
          { [styles.isOpening]: isOpening },
          { [styles.isClosing]: isClosing },
          { [styles.subNavOpen]: subNavOpen }
        )}
        >
          <div className={cn(styles.block, className)}>
            <Wrapper className={cn(styles.wrapper)}>
              <div className={styles.left}>
                {routes.homeView && routes.homeView.url &&
                  <Link className={cn(styles.item, styles.logo)} to={routes.homeView?.url}>
                    <Logo width={logoWidth} isInverted={isScrolled} />
                  </Link>
                }
              </div>

              <div className={styles.right}>
                {onCheckout ? (
                  <div className={styles.close}>
                    <Button
                      className={styles.close}
                      onClick={() =>
                        lastVisited ?
                          history.push(lastVisited) :
                          history.push(routes.redeemView?.url)
                      }
                    >
                      {t(onSuccess ? "CART.CLOSE" : "CART.CLOSE_CART")}
                    </Button>
                  </div>
                ) : (
                  <nav className={styles.nav}>
                    {routes.accountView && routes.accountView.url &&
                      <div className={cn(styles.item, styles.customer_badge)}>
                        <NavLink
                          to={routes.accountView?.url}
                          className={styles.customer_badge}
                        >
                          <CustomerBadge
                            customer={customer}
                          />
                        </NavLink>
                      </div>
                    }

                    <div
                      className={styles.item} onClick={() => {
                        if (cartCount === 0) {
                          return;
                        }

                        setMode(mode === MODE.CART ? MODE.NORMAL : MODE.CART);
                      }}
                    >
                      <CartIcon className={styles.icon} />
                      <span className={styles.cartCount}>{cartCount}</span>
                    </div>

                    <span
                      className={styles.item}
                      onClick={toggleHamburger}
                    >
                      <HamburgerIcon />
                    </span>
                  </nav>
                )}
              </div>
            </Wrapper>
          </div>
          <aside className={cn("awardit-appHeaderSmallMenuContainer", styles.outofcanvas)}>
            <div className={styles.outofcanvasMain}>
              <Wrapper className={styles.top}>
                <SecondaryLogo width={logoWidth} />
                <Button className={styles.close} onClick={closeHamburger}>
                  <CloseIcon className={styles.closeIcon} />
                </Button>
              </Wrapper>

              {nav.map(x => (
                <React.Fragment key={x.url}>
                  {x.title === routes.redeemView?.title ||
                  (x.title === routes.earnView?.title &&
                  ((routes.earnViewSubpage && routes.earnViewSubpage.toggle) ||
                  (routes.earnViewSubpage2 && routes.earnViewSubpage2.toggle))) ?
                    <span
                      className={cn("awardit-appHeaderSmallMenuItem", styles.item)}
                      onClick={() => setModeAndOpenMenu(x.title === routes.redeemView?.title ? "redeem" : "earn")}
                    >
                      {x.title === routes.redeemView?.title ?
                        routes.redeemView?.title : routes.earnView?.title}
                      <ChevronIcon />
                    </span> :
                    <NavLink
                      className={cn("awardit-appHeaderSmallMenuItem", styles.item)}
                      title={x.title}
                      to={x.url}
                    >
                      <span>{x.title}</span>
                    </NavLink>
                  }
                </React.Fragment>
              ))}

              {helpdeskLink && helpdeskTitle &&
              <a
                href={helpdeskLink}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.item}
                title={helpdeskTitle}
              >
                <span>{helpdeskTitle}</span>
              </a>
              }

              {customer !== null && customer !== undefined &&
            routes.accountView && routes.accountView.toggle !== undefined &&
            routes.accountView.toggle &&
              <NavLink
                className={styles.item}
                title={routes.accountView.title}
                to={routes.accountView.url}
              >
                <span>{routes.accountView.title}</span>
              </NavLink>
              }

              {cartCount > 0 && routes.checkoutView &&
              routes.checkoutView.url && routes.checkoutView.title &&
              <Link
                className={styles.item}
                title={routes.checkoutView.title}
                to={`${routes.checkoutView.url}/shipping`}
              >
                <span>{routes.checkoutView.title}</span>
              </Link>
              }
            </div>

            {customer !== null && customer !== undefined &&
            <Wrapper className={styles.bottom}>
              {showAdminButton &&
                <div className={cn(styles.adminBtnContainer, "awardit-adminButtonSmall")}>
                  {accountview.administration &&
                    <AdminButton text={accountview.administration} />
                  }
                </div>
              }
              <div className={styles.user}>
                <h3 className={styles.userName}>{customer.firstname} {customer.lastname}</h3>
                <p className={styles.userPoints}>{formattedBalance}</p>
              </div>
            </Wrapper>
            }
          </aside>

          {mode === MODE.REDEEM &&
          <nav className={styles.redeem}>
            <Redeem
              closeHamburger={closeHamburger}
              setHamburgerOpen={setHamburgerOpen}
              setSubNavOpen={setSubNavOpen}
              closeMenu={closeMenu}
            />
          </nav>}
          {mode === MODE.EARN &&
          <nav className={styles.earn}>
            <Earn
              closeHamburger={closeHamburger}
              setHamburgerOpen={setHamburgerOpen}
              setSubNavOpen={setSubNavOpen}
              closeMenu={closeMenu}
            />
          </nav>}
          {mode === MODE.CART && routes.checkoutView && routes.checkoutView.url && (
            <>
              <CartMini
                quoteData={quoteData}
                variant="b2b"
                altUrl={`${routes.checkoutView.url}/cart`}
                ctaUrl={`${routes.checkoutView.url}/shipping`}
                className={cn(styles.mobile, styles.miniCart)}
                onClose={() => setMode(MODE.NORMAL)}
              />
              <div className={styles.dim} onClick={() => setMode(MODE.NORMAL)} />
            </>
          )}
        </div>

        <Wrapper>
          <SystemMessages />
        </Wrapper>
      </div>
      <Switch>
        <Route
          path={QUESTIONNAIRE_ROUTES}
          render={() => {
            return (
              <div className={styles.subMenu}>
                <Questionnaires />
              </div>
            );
          }}
        />
      </Switch>
    </>
  );
};

export default AppHeaderSmall;
