/* @flow */

import React, { useEffect, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { addMessage } from "state/messages";
import { useSendMessage } from "crustate/react";
import { parseParams } from "helpers/location-search-string";

export const useErrorParams = (errors: { [key: string]: string }) => {
  const { location } = useHistory();
  const sendMessage = useSendMessage();
  const params = useMemo(() => (parseParams(location.search)));

  useEffect(() => {
    if (params && params.error && !Array.isArray(params.error)) {
      if (errors[params.error]) {
        sendMessage(addMessage(errors[params.error], "error"));
      }
      else {
        sendMessage(addMessage("FALLBACK", "error"));
      }
    }
  }, []);
};
