/* @flow */

import React from "react";
import cn from "classnames";

import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";

import UnsuccessfulIcon from "icons/unsuccessful.svg";

import styles from "./styles.scss";

type PartnerSpecificPointDeficit = {
  partnerName: string,
  partnerPointDeficit: number,
};

type Props = {
    className?: string,
    partnerSpecificPointDeficit: PartnerSpecificPointDeficit,
};

const PartnerPointDeficitMessage = ({ className, partnerSpecificPointDeficit }: Props) => {
  const t = useTranslate();
  const { formatPoints } = useFormat();

  return (
    <div className={cn(className, styles.block, styles.warning)}>
      <div className={styles.top}>
        <UnsuccessfulIcon className={styles.icon} />
        <h2>{t("CART.INSUFFICIENT_FUNDS_HEADER_NEGATIVE")}</h2>
      </div>

      <div className={styles.middle}>
        <p>
          {t("CART.INSUFFICIENT_FUNDS_SPECIFIC_PARTNER", { partnerName: partnerSpecificPointDeficit.partnerName })}
        </p>
      </div>

      <div className={styles.bottom}>
        <p className={styles.bottomLabel}>{t("CART.INSUFFICIENT_FUNDS_BOTTOM_LABEL", { points: formatPoints(partnerSpecificPointDeficit.partnerPointDeficit) })}</p>
      </div>

    </div>
  );
};

export default PartnerPointDeficitMessage;
