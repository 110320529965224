/* @flow */

import React from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import Spinner from "components/Spinner";
import ChevronIcon from "icons/chevron-mini.svg";

import styles from "./styles.scss";

type TableSmallProps = {
  headers: Array<string>,
  table: Array<Array<string>>,
  onClick: string => void,
  current?: string | null,
};

const TableSmallHead = () => {
  return (
    <div className={styles.thead}>
      <div className={styles.th}>Ordernumnner</div>
      <div className={styles.th_spacer} />
    </div>
  );
};

const TableSmall = ({ table, headers, onClick, current }: TableSmallProps) => {
  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <TableSmallHead />
      <div className={styles.tbody}>
        {table.map((x, i) => (
          <React.Fragment key={`${x[i]}-${i}`}>
            <div
              className={cn(styles.tr, { [styles.open]: current === x[1] })}
              onClick={() => {
                onClick(x[1]);
              }}
            >
              <div>{x[1]}</div>
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === x[1]} className={styles.expandable}>
              <table>
                <tbody>
                  {headers.map((header, i) => (
                    <tr key={`${header}-${i}`}>
                      <td>{header}</td>
                      <td>{x[i]}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>
      <div className={styles.spacer} />
    </div>
  );
};

export const TableSmallHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
