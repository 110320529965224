/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import Spinner from "components/Spinner";

type TableLargeProps = {
  headers: Array<string>,
  table: Array<Array<string>>,
};

const TableLargeHead = ({ headers }: any) => {
  return (
    <thead>
      <tr>
        {headers.map(header => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

const TableLarge = ({ headers, table }: TableLargeProps) => {
  return (
    <div className={styles.tableLarge}>
      <table className={styles.table}>
        <TableLargeHead headers={headers} />
        <tbody>
          {table.map((row, i) => (
            <tr key={`${row[i]}-${i}`}>
              {row.map((item, i) => (
                <td key={`${item}-${i}`}>{item}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.spacer} />
    </div>
  );
};

export const HistoryTableLargeHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintLarge)}>
    <Spinner />
  </div>
);

export default TableLarge;
