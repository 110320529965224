/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";

type ReceiptProps = {
  currentReceipt: string | null,
  setCurrentReceipt: (string | null) => void,
};

const Receipt = ({ currentReceipt, setCurrentReceipt }: ReceiptProps) => {
  const { info: { code } } = useContext(StoreInfoContext);
  // Different url for Release Points & Michelin
  const partnerIdArray = ["releasefinans_sek", "michelin_nok", "michelin_sek", "michelin_dkk", "michelin_fin"];
  const NEW_WINDOW_HEIGHT = partnerIdArray.includes(code) ? 350 : 550;
  const NEW_WINDOW_WIDTH = partnerIdArray.includes(code) ? 350 : 400;

  if (currentReceipt && process.browser) {
    const receipt = currentReceipt;
    setCurrentReceipt(null);

    const left = Math.floor((screen.width - NEW_WINDOW_WIDTH) / 2);
    const top = Math.floor((screen.height - NEW_WINDOW_HEIGHT) / 2);

    const receiptUrl = partnerIdArray.includes(code) ? `/member/pages/PurchaseDetails.jsp?txnseqnum=${receipt}` : `/TransKvitto?redseqnum=${receipt}`;

    window.open(
      receiptUrl,
      "Print",
      `width=${NEW_WINDOW_WIDTH},height=${NEW_WINDOW_HEIGHT},menubar=no,top=scrollbars=yes,top=${top > 0 ? top : 0},left=${left > 0 ? left : 0}`
    );
  }

  return null;
};

export default Receipt;
