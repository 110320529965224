/* @flow */

import type { ProductSort, FilterRange, ProductFilterInput, ProductSortInput } from "shop-state/types";

import React, { useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { RangeSliderNonLinear } from "@crossroads/ui-components";
import Button from "components/Button";
import DropdownSort from "components/DropdownSort";
import FilterIcon from "icons/filter.svg";
import { usePointsLabel } from "helpers/points";
import { StoreInfoContext } from "entrypoint/shared";
import { getDefaultSort } from "helpers/use-update-product-list";

import styles from "./styles.scss";

type Props = {
  openFilters: () => void,
  className?: string,
  hasDefaultSort?: boolean,
  filterState: {
    price: {
      range: FilterRange,
      min: number,
      max: number,
      setRange: (value: FilterRange) => void,
    },
    sort: {
      setValue: (value: string) => void,
      values: ?Array<ProductSort>,
      value: ?string,
    },
    usePoints: boolean,
    totalCount: number,
    visible: boolean,
    active: {
      filters: Array<ProductFilterInput>,
      page: ?number,
      sort: ?ProductSortInput,
    },
  },
};

const Filterbar = ({
  openFilters,
  className,
  hasDefaultSort = true,
  filterState }: Props) => {
  const t = useTranslate();
  const label = usePointsLabel();
  const { info, configuration } = useContext(StoreInfoContext);

  const history = useHistory();
  const location = useLocation();
  const defaultSort = getDefaultSort(configuration);

  const activeSortFilter = filterState.active.sort;
  const defaultSortValue = (defaultSort && hasDefaultSort) ? `${defaultSort.code}_${defaultSort.order.toLowerCase()}` : null;
  const activeSortFilterValue = activeSortFilter ? `${activeSortFilter.code}_${activeSortFilter.order.toLowerCase()}` : null;

  useEffect(() => {
    if (defaultSortValue && location && location.search === "" && history.action !== "POP") {
      filterState.sort.setValue(defaultSortValue);
    }
  }, [location.search]);

  if (!filterState.visible) {
    return null;
  }

  return (
    <div className={cn("awardit-filterbar", styles.block, className)}>
      <div className={styles.block__left}>

        <div className={cn("awardit-FilterbarButton", styles.item, styles.filter)}>
          <Button
            slotLeft={<FilterIcon className={styles.filterIcon} />}
            className={styles.filterButton}
            onClick={openFilters}
          >
            {t("FILTER.FILTERBAR.ALL_FILTERS")}
          </Button>
        </div>

        <div className={cn(styles.item, styles.sort)}>
          {filterState.totalCount > 1 &&
          filterState.sort.values &&
          filterState.sort.values.length > 0 &&
            <DropdownSort
              value={activeSortFilter ? activeSortFilterValue : filterState.sort.value}
              items={filterState.sort.values}
              className="awardit-filterbarDropdownButton"
              onChange={v => filterState.sort.setValue(v)}
            />
          }
        </div>

        <div className={cn("awardit-FilterbarSlider", styles.item, styles.price)}>
          {filterState.price &&
          filterState.price.max > 0 &&
          filterState.price.min !== filterState.price.max &&
            <>
              <span className={styles.filterOnPoints}>
                {t("FILTER.FILTERBAR.FILTER_BY_POINTS", { pointsLabel: info.locale !== "fi_FI" ? label() : "pisteiden" })}
              </span>
              <div className={cn("awardit-rangeSlider", styles.slider)}>
                {filterState.price &&
                  <RangeSliderNonLinear
                    exponentiation={4}
                    variant="small"
                    value={filterState.price.range}
                    minValue={filterState.price.min}
                    maxValue={filterState.price.max}
                    onChange={x => filterState.price && filterState.price.setRange(x)}
                  />
                }
              </div>
              <span>
                {filterState.price ? t("FILTER.FILTERBAR.PRICE_RANGE",
                  { min: filterState.price.range.min,
                    max: filterState.price.range.max,
                    pointsLabel: label(true),
                  }) : ""}
              </span>
            </>
          }
        </div>
      </div>
    </div>
  );
};

export default Filterbar;
