/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { NewsPostsRequest, NewsPostsResponse } from "state/news-posts";

import { NEWS_POSTS_REQUEST, NEWS_POSTS_RESPONSE } from "state/news-posts";
import { newsPosts } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: (msg: NewsPostsRequest, path) => {
      return client(newsPosts, { page: msg.page, size: msg.size }, { cache: true })
        .then(data => storage.replyMessage(({
          tag: NEWS_POSTS_RESPONSE,
          data: data.newsPosts,
        }: NewsPostsResponse), path));
    },
    subscribe: { [NEWS_POSTS_REQUEST]: true },
  });
};

export default registerClient;
