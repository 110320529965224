/* @flow */

import type { QuizListItemRes } from "shop-state/types";

import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: ?Array<QuizListItemRes> }
  | { state: "LOADED", data: Array<QuizListItemRes> }
  | { state: "ERROR", error: string };

export type QuizListEffectResponse =
  Response<typeof QUIZ_LIST_RESPONSE, Array<QuizListItemRes>>;

export type QuizListEffectRequest = {
  tag: typeof QUIZ_LIST_REQUEST,
};

type QuizListRequest = {
  tag: typeof QUIZ_LIST_REQUEST,
};

export const QUIZ_LIST_RESPONSE: "response/quiz_list" = "response/quiz_list";
export const QUIZ_LIST_REQUEST: "request/quiz_list" = "request/quiz_list";

export const getQuizList = (): QuizListRequest => ({
  tag: QUIZ_LIST_REQUEST,
});

export const QuizListModel: Model<
  Data,
  {},
  QuizListEffectRequest | QuizListEffectResponse | QuizListRequest
> = {
  id: "quiz_list",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      { tag: QUIZ_LIST_REQUEST }
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case QUIZ_LIST_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
