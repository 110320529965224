/* @flow */

import { useEffect } from "react";

// Key code for esc is 27
const useDetectKey = (callback: () => void, key: number = 27) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => e.keyCode === key && callback();

    document.addEventListener("keydown", onKeyDown, false);

    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, [callback, key]);
};

export default useDetectKey;
