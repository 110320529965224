/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  TeampotMembersResponse,
  TeampotHistoryResponse,
  TeampotSettingsResponse,
  TeampotStatusResponse,
  TeampotActivitiesResponse,
  TeampotHistoryItemsResponse,
  TeampotHistoryItemsRequest,
} from "state/teampot";

import {
  TEAMPOT_MEMBERS_REQUEST,
  TEAMPOT_MEMBERS_RESPONSE,
  TEAMPOT_HISTORY_REQUEST,
  TEAMPOT_HISTORY_RESPONSE,
  TEAMPOT_SETTINGS_REQUEST,
  TEAMPOT_SETTINGS_RESPONSE,
  TEAMPOT_STATUS_REQUEST,
  TEAMPOT_STATUS_RESPONSE,
  TEAMPOT_ACTIVITIES_RESPONSE,
  TEAMPOT_ACTIVITIES_REQUEST,
  TEAMPOT_HISTORY_ITEMS_RESPONSE,
  TEAMPOT_HISTORY_ITEMS_REQUEST,
} from "state/teampot";

import { teampostMembers, historygrouped, teampotStatus, teampotSettings, teampotActivities, teampothistory } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          teampostMembers
        );

        return ({
          tag: TEAMPOT_MEMBERS_RESPONSE,
          data: data.members,
        }: TeampotMembersResponse);
      }
      catch (e) {
        console.error(e);

        return ({
          tag: TEAMPOT_MEMBERS_RESPONSE,
          error: "Error in teampot members effect",
        }: TeampotMembersResponse);
      }
    },
    subscribe: { [TEAMPOT_MEMBERS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          historygrouped
        );

        return ({
          tag: TEAMPOT_HISTORY_RESPONSE,
          data: data.historygrouped,
        }: TeampotHistoryResponse);
      }
      catch (e) {
        return ({
          tag: TEAMPOT_HISTORY_RESPONSE,
          error: "Error in teampot history effect",
        }: TeampotHistoryResponse);
      }
    },
    subscribe: { [TEAMPOT_HISTORY_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: TeampotHistoryItemsRequest) => {
      try {
        const data = await client(
          teampothistory, { date: msg.date ?? "" }
        );
        return ({
          tag: TEAMPOT_HISTORY_ITEMS_RESPONSE,
          data: data.teampothistory,
        }: TeampotHistoryItemsResponse);
      }
      catch (e) {
        return ({
          tag: TEAMPOT_HISTORY_ITEMS_RESPONSE,
          error: "Error in teampot history effect",
        }: TeampotHistoryItemsResponse);
      }
    },
    subscribe: { [TEAMPOT_HISTORY_ITEMS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          teampotSettings
        );

        return ({
          tag: TEAMPOT_SETTINGS_RESPONSE,
          data: data.teamPotSettings,
        }: TeampotSettingsResponse);
      }
      catch (e) {
        return ({
          tag: TEAMPOT_SETTINGS_RESPONSE,
          error: "Error in teampot settings effect",
        }: TeampotSettingsResponse);
      }
    },
    subscribe: { [TEAMPOT_SETTINGS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          teampotStatus
        );

        return ({
          tag: TEAMPOT_STATUS_RESPONSE,
          data: data.teamPotStatus,
        }: TeampotStatusResponse);
      }
      catch (e) {
        return ({
          tag: TEAMPOT_STATUS_RESPONSE,
          error: "Error in teampot status effect",
        }: TeampotStatusResponse);
      }
    },
    subscribe: { [TEAMPOT_STATUS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(
          teampotActivities
        );

        return ({
          tag: TEAMPOT_ACTIVITIES_RESPONSE,
          data: data.teamPotActivities,
        }: TeampotActivitiesResponse);
      }
      catch (e) {
        return ({
          tag: TEAMPOT_ACTIVITIES_RESPONSE,
          error: "Error in teampot activities effect",
        }: TeampotActivitiesResponse);
      }
    },
    subscribe: { [TEAMPOT_ACTIVITIES_REQUEST]: true },
  });
};

export default registerClient;
