/* @flow */

import React from "react";

import { Link } from "react-router-dom";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  className?: string,
  img: string,
  title: string,
  url: string,
  variant: "lightText" | null,
};

const PopularCategory = ({ img, title, url, variant, className }: Props) => {
  return (
    <Link className={cn(className, (variant && styles[variant]))} to={url}>
      <img className={styles.popularCategoryImage} src={img} />
      <h2 className={styles.popularCategoryTitle}>{title}</h2>
    </Link>
  );
};

export default PopularCategory;
