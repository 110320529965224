/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import type {
  MemberGroupIsOwnerResponse,
} from "state/member-group";

import { MEMBER_GROUP_IS_OWNER_REQUEST, MEMBER_GROUP_IS_OWNER_RESPONSE } from "state/member-group";

import { membergroupIsOwner } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(membergroupIsOwner, undefined, { cache: true });

      return ({
        tag: MEMBER_GROUP_IS_OWNER_RESPONSE,
        data: {
          owner: data.membergroupIsOwner.owner,
        },
      }: MemberGroupIsOwnerResponse);
    },
    subscribe: { [MEMBER_GROUP_IS_OWNER_REQUEST]: true },
  });
};

export default registerClient;
