/* @flow */

import React, { useState } from "react";
import { useFormField } from "@awardit/formaggio";
import { Input, Checkbox, Foldable, InputErrors, Radio } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import cn from "classnames";
import TextArea from "components/TextArea";
import EyeOff from "icons/eye-off.svg";
import Eye from "icons/eye.svg";

import styles from "./styles.scss";

type CheckboxProps = {
  ...React$ElementProps<"input">,
  children: React$Node,
};

type RadioProps = {
  ...React$ElementProps<"input">,
  children: React$Node,
};

type PasswordProps = {
  ...React$ElementProps<"input">,
  name: any,
  noColorBtn?: boolean,
};

const CheckedComponent = () => (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fillRule="evenodd">
      <path d="m12.8033009 3v10h-5.00000004" stroke="currentColor" strokeWidth="2" transform="matrix(.70710678 .70710678 -.70710678 .70710678 8.674621 -4.942388)" />
    </g>
  </svg>
);

const Field = (props: React$ElementProps<"input">) => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");
  const inputErrors = submitted || dirty ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <Input {...props} {...inputProps} errors={inputErrors.slice(0, 1)} />
  );
};

export default Field;

export const CheckboxField = ({ children, name, className, ...props }: CheckboxProps) => {
  const t = useTranslate();
  const fieldProps = useFormField(name, "");
  const inputErrors = fieldProps.submitted || fieldProps.dirty ?
    fieldProps.errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <>
      <div className={className} style={{ display: "flex", alignItems: "center" }}>
        <Checkbox
          {...props}
          {...fieldProps.inputProps}
          CheckedComponent={CheckedComponent}
        />
        <label htmlFor={fieldProps.inputProps.id} style={{ paddingLeft: "0.7em" }}>
          {children}
        </label>

      </div>
      {inputErrors.length > 0 && (
        <Foldable className={styles.errors} open={inputErrors.length > 0}>
          {inputErrors.map(x => <span key={x}>{x}</span>)}
        </Foldable>
      )}
    </>
  );
};

export const RadioField = ({ children, name, className, ...props }: RadioProps) => {
  const t = useTranslate();
  const fieldProps = useFormField(name, "");

  const inputErrors = fieldProps.submitted || fieldProps.dirty ?
    fieldProps.errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <>
      <div className={className} style={{ display: "flex", alignItems: "center" }}>
        <Radio {...props} name={name} className={styles.radio}>
          {children}
        </Radio>
      </div>
      {inputErrors.length > 0 && (
        <Foldable className={styles.errors} open={inputErrors.length > 0}>
          {inputErrors.map(x => <span key={x}>{x}</span>)}
        </Foldable>
      )}
    </>
  );
};

export const TextAreaField = (props: React$ElementProps<"textarea">) => {
  const t = useTranslate();
  const fieldProps = useFormField(props.name, "");

  const inputErrors = (fieldProps.submitted || fieldProps.dirty) ?
    fieldProps.errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <div className={styles.textAreaField}>
      <TextArea
        {...props}
        labelClassName={inputErrors.length > 0 ? styles.error : ""}
        className={inputErrors.length > 0 ? styles.error : ""}
        onChange={fieldProps.inputProps.onChange}
      />
      {inputErrors.length > 0 &&
        <InputErrors
          className={styles.inputErrors}
          errors={inputErrors}
        />
      }
    </div>
  );
};

export const TogglePasswordField = ({ noColorBtn = false, ...props }: PasswordProps) => {
  const t = useTranslate();
  const { submitted, dirty, errors, inputProps } = useFormField(props.name, "");
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = e => {
    e.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
    }
    else {
      setPasswordType("password");
    }
  };

  const inputErrors = submitted || dirty ?
    errors.map(x => t(`VALIDATION.${x.error}`)) :
    [];

  return (
    <Input
      {...props}
      {...inputProps}
      className={cn(styles.passwordField)}
      type={passwordType}
      errors={inputErrors}
      slotRight={
        <button
          type="button"
          title={passwordType === "password" ? "Visa lösenord" : "Dölj lösenord"}
          className={cn(styles.passwordFieldButton,
            { [styles.noColorBtn]: noColorBtn === true },
            { [styles.passwordInActive]: passwordType === "text" }
          )}
          onClick={togglePassword}
        >
          {passwordType === "password" ? <EyeOff /> : <Eye />}
        </button>
      }
    />
  );
};
