/* @flow */

import type { Routes } from "shop-state/types";

import React from "react";
import { Route } from "react-router";
import { TinkTransactionsData } from "data";
import TinkView from "components/AccountView/TinkView";
import getCurrentInfoRoutes from "components/CurrentInfo/routes";

// Theme specific components

type Props = {
  routes: Routes,
  partnerId?: number,
};

const additionalRoutes = ({ routes }: Props) => {
  return [
    ...(routes.accountTinkView &&
    routes.accountTinkView.toggle !== undefined &&
    routes.accountTinkView.toggle &&
    typeof routes.accountTinkView.url === "string" &&
    routes.accountTinkView.url.length > 0 ?
      [
        <Route
          key={routes.accountTinkView.url}
          path={routes.accountTinkView.url}
          render={() => (
            <TinkTransactionsData.Provider>
              <TinkView />
            </TinkTransactionsData.Provider>
          )}
        />,
      ] : []),
    getCurrentInfoRoutes(routes),
  ];
};

export default additionalRoutes;
