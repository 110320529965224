/* @flow */

import type { FilterState } from "@crossroads/ui-components";

import type {
  AffiliatesListItem,
  ProductFilterBucket,
  FilterValue,
  ProductFilterInput,
  ProductSort } from "shop-state/types";

import { useState, useEffect } from "react";
import { useSendMessage, useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { debounce } from "diskho";
import { getAffiliateList } from "state/affiliate-list";
import { AffiliateListData, AffiliateCategoriesData, AffiliateListSelectedData } from "data";
import usePrevious from "helpers/use-previous";
import { constructSortableValues } from "helpers/filters";
import { useLocation } from "react-router";

type UseAffiliateListFilterReturn = {
  filterState: FilterState,
  items: Array<AffiliatesListItem>,
  itemsSelected: Array<AffiliatesListItem>,
  searchValue: string,
  setSearchValue: string => void,
  affiliateCategoriesLoaded: boolean,
  affiliateListLoaded: boolean,
  affiliateListSelectedLoaded: boolean,
  affiliateListError: boolean,
  affiliateCategoryError: boolean,
};

const SORT_VALUES: Array<ProductSort> = [
  { code: "name", label: "Namn" },
  { code: "popular", label: "Populära" },
  { code: "latest", label: "Senaste" },
  { code: "value", label: "Värde" },
];

const ONLY_DESC_SORT = ["popular", "latest"];

const useAffiliateListFilter = (): UseAffiliateListFilterReturn => {
  const t = useTranslate();
  const [searchValue, setSearchValue] = useState("");
  const affiliateList = useData(AffiliateListData);
  const affiliateCategories = useData(AffiliateCategoriesData);
  const affiliateListSelected = useData(AffiliateListSelectedData);
  const sendMessage = useSendMessage();
  const getAffiliateListDebounced = debounce(v => sendMessage(getAffiliateList(v)));
  const [activeFilter, setActiveFilter] = useState<?ProductFilterInput>();
  const prevActiveFilter: ?ProductFilterInput = usePrevious(activeFilter);
  const [sortValue, setSortValue] = useState<?string>();
  const prevSortValue = usePrevious(sortValue);
  const sortValues = constructSortableValues(
    SORT_VALUES.filter(x => !activeFilter || (activeFilter && x.code !== "popular")),
    t,
    SORT_VALUES.map(x => x.code),
    ONLY_DESC_SORT
  );
  const location = useLocation();

  useEffect(() => {
    if (affiliateCategories.state === "LOADED" && location) {
      const query = location ? location.pathname.split("/").slice(-1).pop() : "";
      const categoryName = query.charAt(0).toUpperCase() + query.slice(1);
      const category = affiliateCategories.data.find(c => c.name === categoryName);

      if (category !== undefined) {
        setActiveFilter({ code: "category", value: category.name });
      }
      else {
        setActiveFilter();
        getAffiliateListDebounced();
      }
    }
  }, [affiliateCategories, location]);

  useEffect(() => {
    if ((prevActiveFilter?.value !== activeFilter?.value ||
      sortValue !== prevSortValue) && affiliateCategories.state === "LOADED") {
      const category = affiliateCategories.data.find(c => c.name === activeFilter?.value);
      const params = {};

      if (category) {
        params.category = category.id;
      }

      if (sortValue) {
        const [code, order] = sortValue.split("_");
        params.sort = {
          code,
          order,
        };
      }

      getAffiliateListDebounced(params);
    }
  }, [activeFilter, sortValue]);

  const categories: Array<FilterValue> = affiliateCategories.state === "LOADED" ?
    affiliateCategories.data
      .filter(a => activeFilter ? a.name === activeFilter.value : true)
      .map(a => ({ value: a.name })) :
    [];

  const clearFilter = () => {
    if (affiliateList.state === "LOADING") {
      return;
    }

    setActiveFilter();
  };

  const toggleFilter = (filterInput: ProductFilterInput) => {
    if (affiliateList.state === "LOADING") {
      return;
    }

    if (sortValue) {
      setSortValue();
    }

    if (activeFilter) {
      setActiveFilter();
    }
    else {
      setActiveFilter(filterInput);
    }
  };

  const filterBucket: ProductFilterBucket = {
    __typename: "ProductFilterBucket",
    code: "category",
    label: t("SEARCH.TYPE.CATEGORY"),
    values: categories,
  };

  const filteredItems: Array<AffiliatesListItem> = affiliateList.state === "LOADED" ?
    affiliateList.data
      .filter(i => (!searchValue || i.name.toLowerCase().includes(searchValue.toLowerCase()))) :
    [];

  const filteredSelectedItems: Array<AffiliatesListItem> = affiliateListSelected.state === "LOADED" ?
    affiliateListSelected.data :
    [];

  const filterState = {
    active: {
      filters: activeFilter ? [activeFilter] : [],
      page: null,
      sort: null,
    },
    filters: {
      buckets: [filterBucket],
      booleans: [],
      prices: [],
      ranges: [],
    },
    clearAllFilters: clearFilter,
    sort: {
      setValue: v => {
        if (affiliateList.state === "LOADED") {
          setSortValue(v);
        }
      },
      value: sortValue,
      values: sortValues,
    },
    loading: false,
    totalCount: filteredItems.length,
    toggleFilter,
    visible: true,
    usePoints: true,
    price: {
      max: 0,
      min: 0,
      range: { min: 0, max: 0 },
      setRange: value => {
        value.max.toString();
      },
    },
  };

  return {
    filterState,
    items: filteredItems,
    itemsSelected: filteredSelectedItems,
    searchValue,
    setSearchValue,
    affiliateListLoaded: affiliateList.state === "LOADED",
    affiliateListSelectedLoaded: affiliateList.state === "LOADED",
    affiliateCategoriesLoaded: affiliateCategories.state === "LOADED",
    affiliateListError: affiliateList.state === "ERROR",
    affiliateCategoryError: affiliateCategories.state === "ERROR",
  };
};

export default useAffiliateListFilter;
