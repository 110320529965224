/* @flow */

import type { CmsPage } from "shop-state/types";
import type { Mode } from "state/view-mode";

import React, { useEffect, useContext, useCallback } from "react";
import { useData, useSendMessage } from "crustate/react";
import { CookieDialogue } from "@crossroads/ui-components";
import { AnalyticsContext } from "@crossroads/analytics";
import { StoreInfoContext } from "entrypoint/shared";
import { CmsData, ViewModeData } from "data";
import { setMode, MODE } from "state/view-mode";
import useCookie from "helpers/use-cookie";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  className?: string,
};

const CookieConsent = ({ className = "" }: Props): React$Node => {
  const privacyData = useData(CmsData);
  const viewMode = useData(ViewModeData);
  const sendMessage = useSendMessage();
  const privacyContent: CmsPage | any = privacyData.data !== null ? privacyData.data : null;
  const gaContext = useContext(AnalyticsContext);
  const { routes, configuration, info: { analytics } } = useContext(StoreInfoContext);
  const cookie = useCookie("cookie_notice_accepted", "initial", { expires: 365 });
  const consentActivated = (configuration.showCookieConsent !== null ||
    configuration.showCookieConsent !== undefined) &&
    configuration.showCookieConsent === true;
  const isVisible = cookie.isRead && (cookie.value === "initial" || viewMode === MODE.COOKIE_CONSENT);
  const config = {
    googleAnalytics: analytics ? analytics.filter(x => x !== "") : [],
  };

  const setViewMode = useCallback((mode: Mode) => {
    sendMessage(setMode(mode));
  }, [sendMessage]);

  const setToggleCookieDialogue = (value: boolean) => {
    setViewMode(value === true ? MODE.COOKIE_CONSENT : MODE.NORMAL);
  };

  useEffect(() => {
    if (cookie.isRead && cookie.value !== "initial") {
      if (cookie.value === "accepted" || cookie.value === "declined") {
        cookie.setValue("initial");
        return;
      }

      const consentJson = JSON.parse(cookie.value);

      if (consentJson.necessary) {
        gaContext.grantConsentNecessary();
      }

      if (consentJson.statistical) {
        gaContext.grantConsentStatistical();
      }
      else {
        gaContext.denyConsentStatistical();
      }
    }

    if (cookie.isRead && cookie.value === "initial" && consentActivated) {
      setToggleCookieDialogue(true);
    }
  }, [cookie.isRead, cookie.value]);

  return (
    viewMode === MODE.COOKIE_CONSENT &&
    privacyData.state === "LOADED" &&
    <div
      className={cn(
        className,
        styles.block,
        { [styles.visible]: isVisible === true }
      )}
    >
      <CookieDialogue
        content={privacyContent}
        isVisible={isVisible}
        config={config}
        consent={cookie.value}
        setConsent={cookie.setValue}
        setToggleCookieDialogue={setToggleCookieDialogue}
        termLink={routes.termsView}
      />
    </div>
  );
};

export default CookieConsent;
