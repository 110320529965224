/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { BrandRequest, BrandResponse } from "@crossroads/shop-state/brand";

import { BRAND_REQUEST, BRAND_RESPONSE } from "@crossroads/shop-state/brand";
import { brand } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: BrandRequest) => {
      const { products } = await client(brand, {
        filter: msg.filters,
        sort: msg.sort,
        page: msg.page == null ? 1 : msg.page,
      }, { cache: true });

      return ({
        tag: BRAND_RESPONSE,
        data: products,
      }: BrandResponse);
    },
    subscribe: { [BRAND_REQUEST]: true },
  });
};

export default registerClient;
