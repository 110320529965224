/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { DistrictInfoResponse } from "state/district-info";

import {
  DISTRICT_INFO_REQUEST,
  DISTRICT_INFO_RESPONSE,
} from "state/district-info";

import { districtInfo } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(districtInfo);

      if (data.districtInfo.result === "ERROR") {
        return {
          tag: DISTRICT_INFO_RESPONSE,
          error: "Error when fetching districtinfo.",
        };
      }

      return ({
        tag: DISTRICT_INFO_RESPONSE,
        data: data.districtInfo,
      }: DistrictInfoResponse);
    },
    subscribe: { [DISTRICT_INFO_REQUEST]: true },
  });
};

export default registerClient;
