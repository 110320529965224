/* @flow */

import type { DistrictInfo } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";

import { updateData } from "crustate";

type Data =
  | {| state: "LOADING" |}
  | {| state: "LOADED", data: DistrictInfo |}
  | {| state: "ERROR", error: string |};

export type DistrictInfoResponse = Response<typeof DISTRICT_INFO_RESPONSE, DistrictInfo>;

export type DistrictInfoRequest = {
  tag: typeof DISTRICT_INFO_REQUEST,
};

export const DISTRICT_INFO_RESPONSE: "response/district_info" = "response/district_info";
export const DISTRICT_INFO_REQUEST: "request/district_info" = "request/district_info";

export const DistrictInfoModel: Model<
  Data,
  {},
  DistrictInfoResponse | DistrictInfoRequest
> = {
  id: "district_info",
  init: () =>
    updateData({ state: "LOADING" }, { tag: DISTRICT_INFO_REQUEST }),
  update: (state, msg) => {
    switch (msg.tag) {
      case DISTRICT_INFO_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        return updateData({ state: "ERROR", error: "Disctrict info could not be fetched." });
      default:
    }
  },
};
