/* @flow */

/*
 * Extending customer
 */

import type { Model } from "crustate";
import type { Data as CustomerData, CustomerRequest as Request, CustomerResponse as Response } from "@crossroads/shop-state/customer";

import { updateData } from "crustate";
import { CustomerModel as BaseModel, CUSTOMER_LOGOUT_REQUEST } from "@crossroads/shop-state/customer";

export type CustomerRequest = Request | {
  +tag: typeof CUSTOMER_LOGOUT_REDIRECT_REQUEST,
  +redirectURL: string,
} | {
  +tag: typeof CUSTOMER_EMAIL_VERIFY_REQUEST,
  +memberuserid: string,
};
export type Data = CustomerData | {|
  +state: "REDIRECTING",
|} | {|
  +state: "NEED_VERIFICATION",
  +memberuserid: string,
  +email: string,
|} | {|
  +state: "SENDING_VERIFICATION",
|} | {|
  +state: "VERIFICATION_SENT",
  +memberuserid: string,
|};

export type CustomerResponse = Response |
{
  +tag: typeof CUSTOMER_LOGOUT_REDIRECT_RESPONSE,
} |
{
  +tag: typeof CUSTOMER_NEED_VERIFY_RESPONSE,
  +memberuserid: string,
  +email: string,
} |
{
  +tag: typeof CUSTOMER_EMAIL_VERIFY_RESPONSE,
  +memberuserid: string,
};

export const logout = (redirectURL?: string) => {
  if (redirectURL) {
    return {
      tag: CUSTOMER_LOGOUT_REDIRECT_REQUEST,
      redirectURL,
    };
  }

  return {
    tag: CUSTOMER_LOGOUT_REQUEST,
  };
};

export const sendEmailVerification = (memberuserid: string) => {
  return {
    tag: CUSTOMER_EMAIL_VERIFY_REQUEST,
    memberuserid,
  };
};

export const login = (email: string, password: string) => ({
  tag: CUSTOMER_LOGIN_REQUEST,
  email,
  password,
});

export const CUSTOMER_LOGIN_REQUEST: "customer/login/request" = "customer/login/request";
export const CUSTOMER_LOGIN_RESPONSE: "customer/login/response" = "customer/login/response";

export const CUSTOMER_LOGOUT_REDIRECT_REQUEST: "customer/logout_redirect/request" = "customer/logout_redirect/request";
export const CUSTOMER_LOGOUT_REDIRECT_RESPONSE: "customer/logout_redirect/response" = "customer/logout_redirect/response";

export const CUSTOMER_EMAIL_VERIFY_REQUEST: "customer/email_verify/request" = "customer/email_verify/request";
export const CUSTOMER_EMAIL_VERIFY_RESPONSE: "customer/email_verify/response" = "customer/email_verify/response";

export const CUSTOMER_NEED_VERIFY_RESPONSE: "customer/need_verify/response" = "customer/need_verify/response";

export const CustomerModel: Model<Data, {}, CustomerRequest | CustomerResponse> = {
  id: "customer",
  init: BaseModel.init,
  update: (state, msg) => {
    switch (msg.tag) {
      case CUSTOMER_LOGOUT_REDIRECT_REQUEST:
        if (state.state === "LOGGED_IN") {
          return updateData({
            state: "LOGGING_OUT",
            data: state.data,
          }, msg);
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_LOGOUT_REDIRECT_RESPONSE:
        return updateData({ state: "REDIRECTING" });

      case CUSTOMER_LOGIN_REQUEST:
        if (state.state === "NOT_LOGGED_IN" || state.state === "VERIFICATION_SENT") {
          return updateData({
            state: "LOGGING_IN",
          }, msg);
        }

        throw new Error("Invalid state transition");
      case CUSTOMER_LOGIN_RESPONSE:
        if (state.state === "LOGGING_IN") {
          return msg.data ?
            updateData({ state: "LOGGED_IN", data: msg.data }) :
            updateData({ state: "NOT_LOGGED_IN" });
        }

        throw new Error("Invalid state transition");

      case CUSTOMER_NEED_VERIFY_RESPONSE:
        return updateData({
          state: "NEED_VERIFICATION",
          memberuserid: msg.memberuserid,
          email: msg.email,
        });

      case CUSTOMER_EMAIL_VERIFY_REQUEST:
        return updateData({
          state: "SENDING_VERIFICATION",
        }, msg);

      case CUSTOMER_EMAIL_VERIFY_RESPONSE:
        return updateData({
          state: "VERIFICATION_SENT",
          memberuserid: msg.memberuserid,
        });

      default:
        if (state.state !== "REDIRECTING" &&
        state.state !== "NEED_VERIFICATION" &&
        msg.tag !== "customer/need_verify/response" &&
        state.state !== "SENDING_VERIFICATION" &&
        state.state !== "VERIFICATION_SENT" &&
        msg.tag !== "customer/email_verify/request" &&
        msg.tag !== "customer/email_verify/response") {
          return BaseModel.update(state, msg);
        }
    }
  },
};
