/* @flow */

import type { QuestionnaireListItem as TQuestionnaireListItem } from "shop-state/types";

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";

import styles from "./styles.scss";

const QuestionnaireListItem = ({ item }: { item: TQuestionnaireListItem }) => {
  const { title, id, status, points, questions } = item;
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const { routes } = useContext(StoreInfoContext);
  const questionnaireViewUrl = routes.questionnaireView && routes.questionnaireView.url ? routes.questionnaireView.url : "";

  if (status !== "UNANSWERED") {
    return (
      <div className={styles.block}>
        <div className={styles.shadow} />
        <div className={styles.top}>
          <p
            className={cn(
              styles.status,
              styles.status__closed
            )}
          >
            {t(`QUIZ_ITEM.STATUS.${status}`)}
          </p>
          <header>
            <h2 className={styles.title}>{title}</h2>
          </header>
        </div>
        <div className={styles.bottom}>
          <p>{t("QUIZ_ITEM.NUM_QUESTIONS", { questions })}</p>
          <p>{formatPoints(points)}</p>
        </div>
      </div>
    );
  }

  return (
    <Link
      className={cn(styles.block, styles.open)}
      to={`${questionnaireViewUrl}/${id}`}
    >
      <div className={styles.shadow} />
      <div className={styles.top}>
        <p
          className={cn(
            styles.status,
            styles.status__open
          )}
        >
          {t(`QUIZ_ITEM.STATUS.${status}`)}
        </p>
        <header>
          <h2 className={styles.title}>{title}</h2>
        </header>
      </div>
      <div className={styles.bottom}>
        <p>{t("QUIZ_ITEM.NUM_QUESTIONS", { questions })}</p>
        <p>{formatPoints(points)}</p>
      </div>
    </Link>
  );
};

export const DummyQuestionnaireListItem = () => (
  <div className={styles.dummy_block}>
    <div className={styles.dummy_top}>
      <div className={styles.dummy_date} />
    </div>
    <div className={styles.dummy_title} />
  </div>
);

export default QuestionnaireListItem;
