/* @flow */

export type QueryValue = string | Array<?string> | null;
export type QueryObject = { [string]: QueryValue };

import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useData, useSendMessage } from "crustate/react";
import { useClient, StoreInfoContext } from "entrypoint/shared";
import { RegisterFormConfigData, DistrictListData } from "data";
import RegisterForm from "./form";
import { createArrayFromObj, formatRegisterFormRequest } from "helpers/forms";
import { addMessage, addMessageTranslated } from "state/messages";
import { registerMember } from "queries";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  queryParams: QueryObject,
  partnerId: number,
  routeParams?: { [string]: string },
};

const RegisterView = ({ queryParams, partnerId }: Props) => {
  const client = useClient();
  const history = useHistory();
  const sendMessage = useSendMessage();
  const { routes: { registerView } } = useContext(StoreInfoContext);
  const [formValue, setFormValue] = useState({});
  const [error, setError] = useState(null);
  const [genderValue, setGenderValue] = useState<string>("");
  const formConfigData = useData(RegisterFormConfigData);
  const districtListData = useData(DistrictListData);
  const formConfigContent = formConfigData.state === "LOADED" ? formConfigData.data : null;
  const districtListArr = districtListData.state === "LOADED" ? districtListData.data.getDistrictList : null;

  useEffect(() => {
    if (error && error.length > 0) {
      sendMessage(addMessageTranslated(error, "error"));
      window.scrollTo(0, 0);
    }
  }, [error]);

  if ((registerView && registerView.toggle === false) || !formConfigContent) {
    return null;
  }

  const formArr = createArrayFromObj(formConfigContent);

  const postForm = async () => {
    const data = formatRegisterFormRequest(formValue);

    if (genderValue !== "") {
      data.gender = genderValue;
    }

    const response = await client(registerMember, { partner: partnerId, member: {
      ...data,
    } });

    if (response.registerMember.result.includes("ERROR")) {
      if (response.registerMember.message) {
        setError(response.registerMember.message);
      }

      return;
    }

    sendMessage(addMessage("REGISTER_MEMBER.SUCCESS_REG", "success"));
    history.push("/login");
  };

  return (
    <div className={cn(styles.registerView, "awardit-registerView")}>
      <Helmet title={(registerView && registerView.title) ?? ""} />
      <div>
        <RegisterForm
          formArr={formArr}
          value={formValue}
          setValue={x => setFormValue(x)}
          postForm={postForm}
          partnerId={partnerId}
          queryParams={queryParams}
          genderValue={genderValue}
          setGenderValue={setGenderValue}
          districtList={districtListArr}
        />
      </div>
    </div>
  );
};

export default RegisterView;
