/* @flow */

import type { MemberTransactionData, MemberTransaction } from "shop-state/types";

import React, { useContext } from "react";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import { timestampToDateString } from "helpers/locale-utils";
import { Foldable } from "@crossroads/ui-components";
import Points from "components/AccountView/TransactionsView/Points";
import Spinner from "components/Spinner";
import ChevronIcon from "icons/chevron-mini.svg";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

type TableSmallProps = {
  items: MemberTransactionData,
  onClick: MemberTransaction => void,
  current: string | null,
};

const TableSmallHead = () => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);

  return (
    <div className={styles.thead}>
      <div className={styles.th}>{transactionsview.tableTransaction}</div>
      <div className={styles.th}>{transactionsview.tablePoints}</div>
      <div className={styles.th_spacer} />
    </div>
  );
};

const TableSmall = ({ items, onClick, current }: TableSmallProps) => {
  const { content: { transactionsview } } = useContext(StoreInfoContext);
  const t = useTranslate();

  return (
    <div className={cn(styles.table, styles.tableSmall)}>
      <TableSmallHead />
      <div className={styles.tbody}>
        {items.transactions.map(x => (
          <React.Fragment key={x.id}>
            <div
              className={cn(styles.tr, { [styles.open]: current === x.id })}
              onClick={() => {
                onClick(x);
              }}
            >
              <div>{x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description}</div>
              <div className={cn({ [styles.negative]: x.flag === "DOWN" })}>{x.points}</div>
              <div className={styles.chevron}>
                <ChevronIcon />
              </div>
            </div>
            <Foldable open={current === x.id} className={styles.expandable}>
              <table>
                <tbody>
                  <tr>
                    <td>{transactionsview.tableId}</td>
                    <td>{x.id}</td>
                  </tr>
                  <tr>
                    <td>{transactionsview.tableTransaction}</td>
                    <td>{x.type === 471 ? `${t("TRANSACTIONS.REFUND")} ${x.description}` : x.description}</td>
                  </tr>
                  {Boolean(!transactionsview.hideTransactionSource) &&
                    <tr>
                      <td>{transactionsview.tableClub}</td>
                      <td>{x.club}</td>
                    </tr>
                  }
                  <tr>
                    <td>{transactionsview.tablePoints}</td>
                    <td className={cn({ [styles.negative]: x.flag === "DOWN" })}>
                      <Points points={x.points} />
                    </td>
                  </tr>
                  <tr>
                    <td>{transactionsview.tableDate}</td>
                    <td>{timestampToDateString(x.date)}</td>
                  </tr>
                  <tr>
                    <td>{transactionsview.tablePointsExpires}</td>
                    <td>{x.expiresAt !== null && timestampToDateString(x.expiresAt)}</td>
                  </tr>
                </tbody>
              </table>
            </Foldable>
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export const TableSmallHint = () => (
  <div className={cn(styles.tableHint, styles.tableHintSmall)}>
    <Spinner />
  </div>
);

export default TableSmall;
