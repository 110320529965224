/* @flow */

import type { QuestionnaireListItem } from "shop-state/types";

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

type Data =
  | { state: "LOADING", data: ?Array<QuestionnaireListItem> }
  | { state: "LOADED", data: Array<QuestionnaireListItem> }
  | { state: "ERROR", error: mixed };

export type QuestionnaireListResponse =
  Response<typeof QUESTIONNAIRE_LIST_RESPONSE, Array<QuestionnaireListItem>>;

export type QuestionnaireListRequest = {
  tag: typeof QUESTIONNAIRE_LIST_REQUEST,
};

export type Filter = "open" | "closed" | "answered";

export const QUESTIONNAIRE_LIST_RESPONSE: "response/questionnaire_list" = "response/questionnaire_list";
export const QUESTIONNAIRE_LIST_REQUEST: "request/questionnaire_list" = "request/questionnaire_list";

export const getQuestionnaireList = () => ({
  tag: QUESTIONNAIRE_LIST_REQUEST,
});

export const QuestionnaireListModel: Model<
  Data,
  {},
  QuestionnaireListRequest | QuestionnaireListResponse | EffectErrorMessage
> = {
  id: "questionnaire_list",
  init: () =>
    updateData(
      { state: "LOADING", data: null },
      getQuestionnaireList()
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        return updateData({ state: "ERROR", error: msg.error });

      case QUESTIONNAIRE_LIST_REQUEST:
        return updateData({ state: "LOADING", data: state.state !== "ERROR" ? state.data : null });

      case QUESTIONNAIRE_LIST_RESPONSE:
        if (msg.error) {
          return updateData({ state: "ERROR", error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;

      default:
    }
  },
};
