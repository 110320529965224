/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  ConnectedAccountsResponse,
  TransferPointsRequest,
  TransferPointsResponse,
} from "state/connected-accounts";

import { syncCustomer } from "@crossroads/shop-state/customer";
import { addMessage } from "state/messages";
import {
  CONNECTED_ACCOUNTS_REQUEST,
  CONNECTED_ACCOUNTS_RESPONSE,
  TRANSFER_POINTS_REQUEST,
  TRANSFER_POINTS_RESPONSE,
} from "state/connected-accounts";
import { transferPoints, listConnectedAccounts, customer } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const data = await client(listConnectedAccounts);

      return ({
        tag: CONNECTED_ACCOUNTS_RESPONSE,
        data: data.listConnectedAccounts,
      }: ConnectedAccountsResponse);
    },
    subscribe: { [CONNECTED_ACCOUNTS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: TransferPointsRequest) => {
      const data = await client(transferPoints, {
        points: msg.points,
        fromMember: msg.fromMember,
        toMember: msg.toMember,
      }, { cache: true });

      if (data.transferPoints.result === "SUCCESS") {
        const { customer: customerData } = await client(customer);
        const connectedAccounts = await client(listConnectedAccounts);

        if (customerData) {
          storage.broadcastMessage(syncCustomer(customerData));
        }

        storage.broadcastMessage(addMessage({
          translationKey: "TRANSFER_POINTS_SUCCESS",
          variable: {
            pointsLabel: customerData.points[0].label,
          },
        },
        "success"));
        return ({
          tag: TRANSFER_POINTS_RESPONSE,
          data: connectedAccounts.listConnectedAccounts,
        }: TransferPointsResponse);
      }

      storage.broadcastMessage(addMessage("TRANSFER_POINTS_ERROR", "error"));

      return ({
        tag: TRANSFER_POINTS_RESPONSE,
        error: "ERROR",
      }: TransferPointsResponse);
    },
    subscribe: { [TRANSFER_POINTS_REQUEST]: true },
  });
};

export default registerClient;
