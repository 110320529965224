/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { AffiliateListEffectResponse, AffiliateListEffectRequest } from "state/affiliate-list";

import {
  AFFILIATE_LIST_EFFECT_REQUEST,
  AFFILIATE_LIST_EFFECT_RESPONSE,
} from "state/affiliate-list";

import { affiliates as affiliatesQuery } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: AffiliateListEffectRequest, path) => {
      const { affiliates } = await client(affiliatesQuery, {
        pageSize: msg.pageSize,
        page: msg.page,
        category: msg.category,
        sort: msg.sort ? {
          code: msg.sort.code,
          order: msg.sort.order,
        } : null,
      });

      if (affiliates.result === "SUCCESS") {
        return storage.replyMessage(({
          tag: AFFILIATE_LIST_EFFECT_RESPONSE,
          data: affiliates.data.filter(f => f.name !== "tom/deletad/gömd"),
        }: AffiliateListEffectResponse), path);
      }

      return storage.replyMessage(({
        tag: AFFILIATE_LIST_EFFECT_RESPONSE,
        error: "AFFILIATE_LIST_LOAD_ERROR",
      }: AffiliateListEffectResponse), path);
    },
    subscribe: { [AFFILIATE_LIST_EFFECT_REQUEST]: true },
  });
};

export default registerClient;
