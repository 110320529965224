/* @flow */

import React from "react";
import type { Node as ReactNode } from "react";

import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  right: ReactNode,
};

const Container = ({ children, right }: Props) => {
  return (
    <div className={styles.block}>
      <div className={styles.left}>
        {children}
      </div>
      <div className={styles.right}>
        {right}
      </div>
    </div>
  );
};

export default Container;
