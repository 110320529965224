/* @flow */

import React from "react";
import cn from "classnames";

import styles from "./styles.scss";

type Props = {
  variant?: string,
  className?: string,
  children: React$Node,
};

const Wrapper = ({ variant = "", className = "", children }: Props) => {
  return (
    <div className={cn(
      styles.block,
      className,
      { [styles[variant && `block__${variant}`]]: Boolean(variant) }
    )}
    >
      {children}
    </div>
  );
};

export default Wrapper;
