/* @flow */

import type { TeamPotHistoryItem } from "shop-state/types";
import React, { useState, useEffect } from "react";
import { Lightbox } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import CloseIcon from "icons/close-small.svg";

import styles from "./styles.scss";
type countsItemProp = {
  users: $ReadOnlyArray<TeamPotHistoryItem>,
  onClickUsers: string => void,
  date: string,
  countmem: string,
};

const UsersModal = ({ users, onClickUsers, date, countmem }: countsItemProp) => {
  const t = useTranslate();
  const [open, setOpen] = useState(false);
  const [usersList, setUserList] = useState([]);
  const onClickHandler = () => {
    setOpen(true);
    onClickUsers(date);
  };

  useEffect(() => {
    if (users && users.length > 0) {
      // Combine names and filter unique users
      setUserList(users.map(user => user.firstname + " " + user.lastname)
        .filter((user, index, self) => self.indexOf(user) === index));
    }
  }, [users]);

  return (
    <>
      <span className={styles.usercell} onClick={() => onClickHandler()}>
        {countmem}
      </span>
      <Lightbox direction="top" open={open} setOpen={setOpen} className={styles.lightbox}>
        <header className={styles.lightboxHeader}>
          <h2>{t("STATUS_CODE.TEAMPOT_USERS")}</h2>
          <CloseIcon onClick={() => setOpen(false)} />
        </header>

        <main className={styles.lightboxBody}>
          {usersList.length > 0 ? <p>{usersList.join(", ")}</p> : null }
        </main>
      </Lightbox>
    </>
  );
};

export default UsersModal;
