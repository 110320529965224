/* @flow */

import type { Customer } from "shop-state/types";

import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { pointsPriceByID, getCustomerActiveBalance } from "helpers/points";
import useFormat from "helpers/use-format";
import Button from "components/Button";
import ChevronIcon from "icons/chevron-mini.svg";
import cn from "classnames";

import styles from "components/AccountView/styles.scss";

type Props = {
  customer: Customer,
};

const PartnerPoints = ({ customer }: Props) => {
  const pointsObj = pointsPriceByID(customer.points, "awardit");
  const t = useTranslate();
  const { formatPoints } = useFormat(false);

  if (customer.pointsPerPartner && customer.pointsPerPartner.length >= 2) {
    return (
      customer.pointsPerPartner.map(partner => {
        return (
          <tr key={partner.partnerUserId}>
            <td>{partner.partnerName}</td>
            <td>{`${formatPoints(partner.sumBalance)} ${pointsObj ? pointsObj.label.toLowerCase() : t("LOCALE.POINTS")}`}</td>
          </tr>
        );
      })
    );
  }

  return null;
};

const YourPoints = ({ customer }: { customer: Customer }) => {
  const t = useTranslate();
  const { content: { accountview }, routes } = useContext(StoreInfoContext);
  const { formatPoints } = useFormat(false);
  const { location: { pathname } } = useHistory();
  const { pointsPerPartner, awardit, points } = customer;
  const accountViewUrl = routes.accountView && routes.accountView.url ? routes.accountView.url : "";
  const isCurrentPage = pathname.includes(`${accountViewUrl}/points`);

  const expiringPoints = awardit.expiringPoints > 0 ? formatPoints(awardit.expiringPoints) : null;
  const expireDate = awardit.expiringPointsDate;
  const pointsObj = pointsPriceByID(points, "awardit");
  const hasPartnerPoints = pointsPerPartner && pointsPerPartner.length >= 2;
  const hasGroupPoints = awardit.groupPoints > 0;
  const showOwnPoints = () => {
    if (awardit.ownPoints > 0 && (awardit.ownPoints !== awardit.groupPoints &&
        awardit.ownPoints !== getCustomerActiveBalance(customer))) {
      return true;
    }

    return false;
  };

  const customerBalance = getCustomerActiveBalance(customer);
  const formattedBalance = formatPoints(customerBalance);
  const formattedGroupPoints = formatPoints(awardit.groupPoints);
  const formattedOwnPoints = formatPoints(awardit.ownPoints);

  return (
    <div className={cn("awardit-PointsTable")}>
      <header className={styles.boxHeader}>
        {accountview.yourPointsHeading &&
          <h2 className={styles.boxHeading}>{accountview.yourPointsHeading}</h2>
        }
        {!isCurrentPage &&
          <Button to={`${accountViewUrl}/points`} type="button">
            {accountview.open &&
              <span>{accountview.open}</span>
            }
            <ChevronIcon />
          </Button>
        }
      </header>
      <section className={styles.boxBody}>
        <table className={styles.pointsTable}>
          <tbody>
            <PartnerPoints customer={customer} />
            <tr className={cn({ [styles.boldBalance]: hasPartnerPoints })}>
              {accountview.balance &&
                <td>{accountview.balance}</td>
              }
              <td>{`${formattedBalance} ${pointsObj ? pointsObj.label.toLowerCase() : t("LOCALE.POINTS")}`}</td>
            </tr>
            {expireDate && expiringPoints && (
              <tr>
                {accountview.expires &&
                  <td>{`${accountview.expires} ${expireDate}`}</td>
                }
                <td>{`${expiringPoints} ${pointsObj ? pointsObj.label.toLowerCase() : t("LOCALE.POINTS")}`}</td>
              </tr>
            )}
            {hasGroupPoints && (
              <tr>
                {accountview.groupBalance &&
                  <td>{accountview.groupBalance}</td>
                }
                <td>{`${formattedGroupPoints} ${pointsObj ? pointsObj.label.toLowerCase() : t("LOCALE.POINTS")}`}</td>
              </tr>
            )}
            {showOwnPoints() && (
              <tr>
                {accountview.ownBalance &&
                  <td>{accountview.ownBalance}</td>
                }
                <td>{`${formattedOwnPoints} ${pointsObj ? pointsObj.label.toLowerCase() : t("LOCALE.POINTS")}`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default YourPoints;
