/* @flow */

import React, { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import cn from "classnames";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";

type Props = {
  title?: ?string,
  description?: ?string,
};

const HeroIntro = ({ title, description }: Props) => {
  const {
    routes,
    content: { heroIntroView },
  } = useContext(StoreInfoContext);

  return (
    <div
      className={cn(
        styles.heroSection,
        "awardit-heroIntro",
        { [cn("awardit-heroIntroEarnView")]: routes.earnViewSubpage?.url || routes.earnViewSubpage2?.url }
      )}
      style={{ backgroundColor: heroIntroView.backgroundColor && `#${heroIntroView.backgroundColor}` }}
    >
      {heroIntroView.backgroundImage &&
        <img src={heroIntroView.backgroundImage} alt={title} className={styles.heroBGImage} />
      }
      <Wrapper className={styles.heroWrapper}>
        <h1
          className={styles.heroTitle}
          style={{ color: heroIntroView.titleColor && `#${heroIntroView.titleColor}` }}
        >
          {title}
        </h1>
        {description &&
          /* eslint-disable react/no-danger */
          <div
            className={cn("typography", styles.heroDescription)}
            style={{ color: heroIntroView.textColor && `#${heroIntroView.textColor}` }}
            dangerouslySetInnerHTML={{ __html: description }} />
          /* eslint-enable react/no-danger */
        }
      </Wrapper>
    </div>
  );
};

export default HeroIntro;
